import React, { Component } from 'react';
import AuthContext from '../../../../AuthContext';
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';
import { Row, Col, Button, ButtonGroup } from 'reactstrap';
import LeadAceptado from './lead/LeadAceptado';
import LeadAceptadoChico from './lead/LeadAceptadoChico';
import FiltrosClientes from '../FiltrosClientes';
import { Link } from 'react-router-dom';

class Clientes extends Component {
  state = {
    leads: [],
    show: 0,
    busqueda: '',
    tamano: 1,
  };

  chico() {
    this.setState({ tamano: 1 });
  }

  mediano() {
    this.setState({ tamano: 2 });
  }
  grande() {
    this.setState({ tamano: 3 });
  }

  user = {
    id: '23489',
    balance: '5950',
    currency: { name: 'MXN', factor: 1 },
    budgets: [
      {
        origin: { country: 'México', state: 'Puebla', city: 'Puebla' },
        amount: '1950',
      },
      {
        origin: { country: 'México', state: 'Puebla', city: 'Cholula' },
        amount: '4000',
      },
    ],
  };
  leadQuery = gql`
    query GetUser($filter: leadWhereInput) {
      getUser {
        user {
          id
          leads(filter: $filter) {
            id
            accepted
            contact {
              name
              cellphone
              email
            }
            origin {
              state
              city
            }
            zones
            price
            propertyType
            operationType
            furnished
            bedrooms
            bathrooms
            description
            budget
            rentBudget
            followUp
          }
          preferences
        }
      }
    }
  `;

  render() {
    return (
      <Query
        query={this.leadQuery}
        variables={{
          filter: {
            accepted: true,
          },
        }}
        context={{ token: this.context.token }}>
        {({ data, error, loading }) => {
          if (loading)
            return (
              <div className="centro parmediano">
                <h2 className="verde wbold">
                  Cargando información de tus clientes...
                </h2>
              </div>
            );
          if (error) return <h2>{error.message}</h2>;
          const user = data.getUser.user;
          console.log(user.preferences);
          return (
            <div className="centro parmuygrande">
              <div className="pabmuychico">
                {user.leads.length === 0 && (
                  <div className="pargrande">
                    <div className="sombraTodo tamanoAun centradoLead">
                      <div className="centro tmuygrande pchico wbold rojo">
                        Aún no tienes clientes
                      </div>{' '}
                      <div className="tgrande wbold">
                        ¿Quieres clientes y empezar a generar ventas?{' '}
                      </div>
                      <div className="pmediano">
                        <Button
                          tag={Link}
                          to="/clientes"
                          className="wbold parchico parmuychico pizmediano pdemediano tmediana botonAzul">
                          DA CLICK AQUÍ
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
                {user.leads.length !== 0 && (
                  <React.Fragment>
                    <Button tag={Link} to="/clientes" className="botonComprar">
                      <div className="pizmediano pdemediano parmuychico pabmuychico tgrande">
                        COMPRAR CLIENTES
                      </div>
                    </Button>
                    {/*   Filtros clientes */}
                    <FiltrosClientes
                      tamano={this.state.tamano}
                      chico={this.chico.bind(this)}
                      mediano={this.mediano.bind(this)}
                      grande={this.grande.bind(this)}
                    />
                    <Row className="parmediano">
                      <Col
                        xs={12}
                        sm={{ size: 10, offset: 1 }}
                        md={{ size: 12, offset: 0 }}>
                        <div className="paddingLeadsAceptados">
                          <Row>
                            {user.leads.map(lead => (
                              <React.Fragment>
                                {this.state.tamano === 1 && (
                                  <React.Fragment>
                                    <Col xs={12} sm={10} md={6} lg={4}>
                                      <LeadAceptadoChico
                                        lead={lead}
                                        currency={this.user.currency}
                                      />
                                    </Col>
                                  </React.Fragment>
                                )}
                                {this.state.tamano === 3 && (
                                  <React.Fragment>
                                    <Col
                                      xs={12}
                                      sm={10}
                                      md={6}
                                      lg={4}
                                      className="alturaLeadAceptados">
                                      <LeadAceptado
                                        lead={lead}
                                        currency={this.user.currency}
                                      />
                                    </Col>
                                  </React.Fragment>
                                )}
                              </React.Fragment>
                            ))}
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}
Clientes.contextType = AuthContext;
export default Clientes;
