import React, { Component } from 'react';
import AuthContext from '../../../../../AuthContext';
import { Row, Col } from 'reactstrap';
import ComponenteExplicacion from '../../../../objetos/ComponenteExplicacion';

function numberWithCommas(x) {
  x = Math.round(x).toString();
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x)) x = x.replace(pattern, '$1,$2');
  return x;
}

class Presupuesto extends Component {
  render() {
    return (
      <React.Fragment>
        <Row className={`${!this.props.clientes && 'parchico'}`}>
          <Col
            xs={this.props.clientes ? 12 : 8}
            className={`wbold letraLeadPresupuesto ${
              this.props.clientes
                ? `${this.props.venta ? 'izquierda azul' : 'centro azul'}`
                : 'izquierda verde'
            } sinpymde`}>
            <div
              className={
                this.props.chico &&
                'fondoAzul blanco areaPresupuestoLeadAceptadosChico'
              }>
              {this.props.lead.budget !== null &&
              this.props.lead.rentBudget !== null ? (
                <React.Fragment>
                  <div
                    className={this.props.venta && 'pizchico tchica d-inline'}>
                    COMPRAR: $
                    {numberWithCommas(
                      this.props.lead.budget * this.props.currency.factor,
                    )}
                    <span className="tmuychica">
                      {this.props.currency.name}
                    </span>
                  </div>
                  <div
                    className={this.props.venta && 'pizchico tchica d-inline'}>
                    RENTAR: $
                    {numberWithCommas(
                      this.props.lead.rentBudget * this.props.currency.factor,
                    )}
                    <span className="tmuychica">
                      {this.props.currency.name}
                    </span>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div
                    className={`${
                      this.props.chico ? this.props.chico : 'pabprecio'
                    } ${this.props.venta && 'pizmuychico tchica'}`}>
                    PRESUPUESTO: $
                    {numberWithCommas(
                      (this.props.lead.budget !== null
                        ? this.props.lead.budget
                        : this.props.lead.rentBudget) *
                        this.props.currency.factor,
                    )}
                    <span className="tmuychica">
                      {this.props.currency.name}
                    </span>
                  </div>
                </React.Fragment>
              )}
            </div>
            {this.props.venta && (
              <div className="parmicro pabmicro">
                <div className="lmuychica fondoGrisClaro2 lineaPresupuesto" />
              </div>
            )}
          </Col>
          {!this.props.clientes ? (
            <React.Fragment>
              <Col className="derecha sinpymlados">
                <div>
                  <ComponenteExplicacion className="rojo tmuygrande parmuychico">
                    <React.Fragment>
                      <div className="pabchico">
                        <div className="justificado tchica pizmediano pdemediano">
                          *Este cliente puede ser solo tuyo, sólo tienes que
                          comprarlo! Una vez comprado obtendras acceso a sus
                          datos personales.
                        </div>
                      </div>
                    </React.Fragment>
                  </ComponenteExplicacion>
                </div>
              </Col>
            </React.Fragment>
          ) : (
            ''
          )}
        </Row>
      </React.Fragment>
    );
  }
}
Presupuesto.contextType = AuthContext;
export default Presupuesto;
