import React from 'react';
import {
  Modal,
  ModalHeader,
  Row,
  Col,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap';
import { FaQuestionCircle } from 'react-icons/fa';
import './ayuda.css';

class ComponenteExplicacion extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      toggle: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle = () => {
    this.setState({ toggle: !this.state.toggle });
  };

  render() {
    return (
      <div className={this.props.clase}>
        <Row>
          <Col xs={{ size: 6, offset: 3 }} onClick={this.toggle}>
            <FaQuestionCircle
              id="Popover1"
              onClick={this.toggle}
              className={this.props.className || 'tmuygrande amarillo'}
            />
          </Col>
        </Row>

        <Modal
          isOpen={this.state.toggle}
          trigger="focus"
          placement="bottom"
          target="Popover1"
          toggle={this.toggle}
          className="pdemediano">
          <ModalHeader className="wbold tgrande">
            <div className="wbold">
              <FaQuestionCircle
                id="Popover1"
                onClick={this.toggle}
                className={this.props.className && 'tmuygrande rojo'}
              />{' '}
              {this.props.titulo ? this.props.titulo : '¿Cómo funciona?'}
            </div>
          </ModalHeader>
          <ModalBody className="pizchico fondoPantalla">
            {this.props.children}
          </ModalBody>
          <ModalFooter className="pizchico ">
            <Button onClick={this.toggle} className="botonComprar">
              CERRAR
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ComponenteExplicacion;
