import React from 'react';
import { Col, Row } from 'reactstrap';
import SeleccionarEditarDatos from './SeleccionarEditarDatos';
import { FaBriefcase } from 'react-icons/fa';

class DatosNegocio extends React.PureComponent {
  render() {
    return (
      <section>
        <Row className="izquierda wbold tgrande">
          {/*          <Col xs={4} sm={6}>
            <Row>
              <Col xs={12}>
                <div className="w100 h100 textoImagen">
                  <img
                    className="w100 h100 tamanoProductoImg2"
                    src={this.props.imagen}
                    alt="error"
                  />
                  <div className="centrado textoImagen2 mayus compralmp wbolder">
                    <Button className="fondoGris tchica sinpymar sinpymab pizmuychico pdemuychico">
                      Editar <FaPen className="tmuychica" />
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>*/}
          {/*</Col>*/}
          {/*TODO <abbr className="tmuychica">
              Quiero que el editar aparezca sobre la imagen en la esquina
              inferior derecha
            </abbr>*/}
          <Col className="centro">
            <Row>
              <Col xs={4} md={5} className="derecha sinpymde">
                <FaBriefcase />
              </Col>{' '}
              <Col xs={8} md={7} className="izquierda pizchico parmicro2">
                Datos Negocio
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="parchico">
          <SeleccionarEditarDatos
            fondo="fondoGrisClaro"
            color="azul"
            concepto="Nombre"
            valor={this.props.nombre}
            falso
          />
        </div>
        <SeleccionarEditarDatos
          fondo="fondoGrisClaro"
          color="azul"
          concepto="Giro"
          valor={this.props.negocio}
          falso
        />
      </section>
    );
  }
}

export default DatosNegocio;
