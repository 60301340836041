import React, { Component } from 'react';
import {
  Input,
  FormGroup,
  FormFeedback,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';

class Zonas extends Component {
  render() {
    return (
      <FormGroup className="sinpym">
        <InputGroup>
          <InputGroupAddon className={this.props.fondo} addonType="prepend">
            <div className={this.props.icono}>
              <div className={`${this.props.color || 'rojo'} icon-pin`} />
            </div>
          </InputGroupAddon>
          <Input
            name="zone"
            onBlur={this.props.handleZones}
            type="text"
            data-index={this.props.i}
            defaultValue={this.props.zone}
            onChange={this.props.changeZone}
            maxlength={40}
            placeholder={
              this.props.placeholder ||
              'Zona en la que se encuentra la propiedad'
            }
          />
        </InputGroup>
        <FormFeedback>
          Indique la zona en dónde se encuentra la propiedad
        </FormFeedback>
      </FormGroup>
    );
  }
}

export default Zonas;
