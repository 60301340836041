import React, { Component } from 'react';
import AuthContext from '../../../../../AuthContext';

class ContenidoLead extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.chico && (
          <div className="tContenidoLead">
            <div className="negro justificado pabmuychico pizchico pdechico">
              <span className="wbold mayus verde">
                {this.props.lead.operationType === 'BUY' && 'COMPRAR'}
                {this.props.lead.operationType === 'BUYRENT' &&
                  'COMPRAR o RENTAR'}
                {this.props.lead.operationType === 'RENT' && 'RENTAR'}
              </span>{' '}
              <span className="minus wbold">
                {this.props.lead.propertyType === 'APPARTMENT' &&
                  'departamento'}
                {this.props.lead.propertyType === 'HOUSE' && 'Casa'}
                {this.props.lead.propertyType === 'OFFICE' && 'Oficina'}
                {this.props.lead.propertyType === 'LAND' && 'Terreno'}
                {this.props.lead.propertyType === 'SHOP' && 'Local'}
                {this.props.lead.propertyType === 'STORAGE' && 'Bodega'}{' '}
              </span>
              <React.Fragment>
                {this.props.lead.furnished === true ? (
                  <span className="wbold minus negro">
                    {this.props.lead.propertyType === 'APPARTMENT' &&
                      'amueblado'}
                    {this.props.lead.propertyType === 'HOUSE' && 'amueblada'}
                    {this.props.lead.propertyType === 'OFFICE' && 'amueblada'}
                    {this.props.lead.propertyType === 'LAND' && 'amueblado'}
                    {this.props.lead.propertyType === 'SHOP' && 'amueblado'}
                    {this.props.lead.propertyType === 'STORAGE' &&
                      'amueblada'}{' '}
                  </span>
                ) : (
                  ''
                )}
              </React.Fragment>
              <React.Fragment>
                <span className="negro">
                  {this.props.lead.bedrooms !== -1 && (
                    <React.Fragment>
                      de{' '}
                      <span className="wbold minus negro">
                        {this.props.lead.bedrooms}{' '}
                        {this.props.lead.bedrooms === 1
                          ? 'recámara'
                          : 'recámaras'}{' '}
                      </span>
                    </React.Fragment>
                  )}
                </span>{' '}
                <React.Fragment>
                  en{' '}
                  <span className="wbold negro">
                    {this.props.lead.origin.state}
                  </span>
                  ,{' '}
                  <span className="wbold negro">
                    {this.props.lead.origin.city}
                  </span>{' '}
                  {this.props.lead.zones.length !== 1 && ': '}
                  <span className="wbold negro">
                    {this.props.lead.zones.join(', ')}
                  </span>
                  .
                </React.Fragment>
              </React.Fragment>
            </div>
          </div>
        )}
        {/*normal*/}
        {!this.props.chico && (
          <div className="tContenidoLead">
            <div className="negro izquierda pabchico">
              <div className="gris">
                Busca{' '}
                <span className="wbold minus negro">
                  {this.props.lead.operationType === 'BUY' && 'COMPRAR'}
                  {this.props.lead.operationType === 'BUYRENT' &&
                    'COMPRAR o RENTAR'}
                  {this.props.lead.operationType === 'RENT' && 'RENTAR'}
                </span>{' '}
                <span className="wbold minus negro">
                  {this.props.lead.propertyType === 'APPARTMENT' && 'un '}
                  {this.props.lead.propertyType === 'HOUSE' && 'una '}
                  {this.props.lead.propertyType === 'OFFICE' && 'una '}
                  {this.props.lead.propertyType === 'LAND' && 'un '}
                  {this.props.lead.propertyType === 'SHOP' && 'un '}
                  {this.props.lead.propertyType === 'STORAGE' && 'una '}
                  {this.props.lead.propertyType === 'APPARTMENT' &&
                    'departamento'}
                  {this.props.lead.propertyType === 'HOUSE' && 'Casa'}
                  {this.props.lead.propertyType === 'OFFICE' && 'Oficina'}
                  {this.props.lead.propertyType === 'LAND' && 'Terreno'}
                  {this.props.lead.propertyType === 'SHOP' && 'Local'}
                  {this.props.lead.propertyType === 'STORAGE' && 'Bodega'}{' '}
                </span>
                {this.props.lead.furnished === true ? (
                  <span className="wbold minus negro">
                    {this.props.lead.propertyType === 'APPARTMENT' &&
                      'amueblado'}
                    {this.props.lead.propertyType === 'HOUSE' && 'amueblada'}
                    {this.props.lead.propertyType === 'OFFICE' && 'amueblada'}
                    {this.props.lead.propertyType === 'LAND' && 'amueblado'}
                    {this.props.lead.propertyType === 'SHOP' && 'amueblado'}
                    {this.props.lead.propertyType === 'STORAGE' &&
                      'amueblada'}{' '}
                  </span>
                ) : (
                  ''
                )}
                <span className="wbold negro">
                  {this.props.lead.bedrooms !== -1 && (
                    <React.Fragment>
                      de{' '}
                      <span className="wbold minus negro">
                        {this.props.lead.bedrooms}{' '}
                        {this.props.lead.bedrooms === 1
                          ? 'recámara'
                          : 'recámaras'}{' '}
                      </span>
                    </React.Fragment>
                  )}
                </span>{' '}
                {this.props.lead.bathrooms !== -1 && (
                  <React.Fragment>
                    con{' '}
                    <span className="wbold minus negro">
                      {this.props.lead.bathrooms}{' '}
                      {this.props.lead.bathrooms === 1 ? 'baño' : 'baños'}{' '}
                    </span>
                  </React.Fragment>
                )}
                en{' '}
                <span className="wbold negro">
                  {this.props.lead.origin.state}
                </span>
                ,{' '}
                <span className="wbold negro">
                  {this.props.lead.origin.city}
                </span>{' '}
                {this.props.lead.zones.length !== 1
                  ? 'cualquiera de las siguientes zonas'
                  : 'la zona '}
                {this.props.lead.zones.length !== 1 && ': '}
                <span className="wbold negro">
                  {this.props.lead.zones.join(', ')}
                </span>
                .
                {this.props.lead.description !== '' && (
                  <div className="parchico">
                    {this.props.lead.description !== null && (
                      <React.Fragment>
                        <span className="wbold azul">Otros requisitos: </span>"
                        {this.props.lead.description}."
                      </React.Fragment>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
ContenidoLead.contextType = AuthContext;
export default ContenidoLead;
