import React from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import AuthContext from './AuthContext';
import gql from 'graphql-tag';
import Menu from './components/Menu';
import { rutas } from './components/Logeado/Logeado';
import LogoVerde from './components/imgs/logoVerde.png'

var styles = {
  bmMenu: {
    background: '#008742',
  },

  bmMorphShape: {
    fill: '#008742',
  },
  bmBurgerButton: {
    zIndex: 1040,
  },
};

const userQuery = gql`
  {
    getUser {
      user {
        id
        email
        firstName
        lastName
        verified
        membership {
          id
        }
      }
    }
  }
`;

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const { loading, data, error: errors } = useQuery(userQuery);

  if (loading)
    return (
      <React.Fragment>
        <Menu
          logotipo={
            LogoVerde
          }
          rutas={rutas}
          color="fondoBlanco"
          styles={styles}
        />
      </React.Fragment>
    );
  if (errors) {
    if (errors.graphQLErrors) {
      const isAuthenticationError = errors.graphQLErrors.some(
        error => error.message === 'UserNotAuthenticatedException',
      );
      if (isAuthenticationError) {
        return <Redirect to="/iniciar-sesion" />;
      }
    }
  }
  if (!data) {
    return <Redirect to="/iniciar-sesion" />;
  }

  const { getUser } = data;

  const render = props =>
    getUser ? <Component {...props} /> : <Redirect to="/iniciar-sesion" />;

  return (
    <AuthContext.Provider
      value={{ isAuthenticated: !!getUser, user: getUser.user }}>
      <Route path={path} render={render} {...rest} />
    </AuthContext.Provider>
  );
};

export default PrivateRoute;
