import React, { Component } from 'react';
import AuthContext from '../../../AuthContext';
import { Button, ButtonGroup } from 'reactstrap';

class FiltrosClientes extends Component {
  state = {
    areaFiltrar: false,
  };
  areaFiltrar() {
    this.setState({ areaFiltrar: !this.state.areaFiltrar });
  }

  render() {
    return (
      <React.Fragment>
        {/*   Filtros clientes */}
        <div className="parmediano">
          <ButtonGroup>
            {/*   el boton rojo tiene que estar cuando esta seleccionado */}
            <Button
              onClick={this.props.chico}
              className={`${
                this.props.tamano === 1 ? 'botonRojo' : 'botonBlanco3'
              } sombraTodo`}>
              Chico
            </Button>
            {/*<Button
              onClick={this.props.mediano}
              className={`${
                this.props.tamano === 2 ? 'botonRojo' : 'botonBlanco3'
              } sombraTodo`}>
              Mediano
            </Button>*/}
            <Button
              onClick={this.props.grande}
              className={`${
                this.props.tamano === 3 ? 'botonRojo' : 'botonBlanco3'
              } sombraTodo`}>
              Grande
            </Button>
          </ButtonGroup>
        </div>
      </React.Fragment>
    );
  }
}
FiltrosClientes.contextType = AuthContext;
export default FiltrosClientes;
