import React, { Component } from 'react';
import AuthContext from '../../../../../AuthContext';
import { Button } from 'reactstrap';
import ContenidoLead from './ContenidoLead';
import Presupuesto from './Presupuesto';
import Lead from './Lead';

function numberWithCommas(x) {
  x = Math.round(x).toString();
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x)) x = x.replace(pattern, '$1,$2');
  return x;
}

class LeadChico extends Component {
  state = { aceptado: false, normal: false };

  aceptado() {
    this.setState({ aceptado: !this.state.aceptado });
  }
  normal() {
    this.setState({ normal: !this.state.normal });
  }
  render() {
    return (
      <React.Fragment>
        {/*chico*/}
        {!this.state.normal && (
          <React.Fragment>
            <div className="fondoBlanco sombraTodo heightLeadAceptadosChico">
              <div className="pmuychico">
                <div className="fondoAzul blanco wbold tprecio parmuychico pabmicro borderPrecio">
                  Precio: ${numberWithCommas(this.props.lead.price)}{' '}
                  <span className="tchica">{this.props.currency.name}</span>
                </div>
                <Presupuesto
                  clientes
                  venta
                  lead={this.props.lead}
                  currency={this.props.currency}
                />
                <ContenidoLead chico lead={this.props.lead} />
                <div className="centro">
                  <Button
                    onClick={this.normal.bind(this)}
                    className="botonTransparente">
                    <div className="gris wbold claseBoton tContenidoLead">
                      Ver más
                    </div>
                  </Button>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
        {/*nomal*/}
        {this.state.normal && (
          <Lead
            carrito={this.props.carrito}
            lead={this.props.lead}
            currency={this.props.currency}
          />
        )}
      </React.Fragment>
    );
  }
}
LeadChico.contextType = AuthContext;
export default LeadChico;
