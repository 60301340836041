// @format
import React, { Component } from 'react';
import {
  Button,
  Row,
  Col,
  Input,
  Label,
  FormGroup,
  FormFeedback,
  InputGroup,
  InputGroupAddon,
  Container,
} from 'reactstrap';
import { Query, Mutation } from '@apollo/react-components';
import AuthContext from '../../../AuthContext';
import gql from 'graphql-tag';
import Switch from 'react-switch';
import Drop from '../../objetos/Drop';
import Zonas from '../../objetos/Zonas';
import { EliminarImagenes } from './EliminarImagenes';
import { Link, withRouter } from 'react-router-dom';
import Swal from 'sweetalert';
import MapsGoogleMaps from './Location/MapsGoogleMaps';
import CommaInput from './InputWithCommas';
class ContenidoPropiedad extends Component {
  constructor(props) {
    super(props);
    this.zoneRef = React.createRef();
    if (props.property) {
      this.editar = true;
      this.state = {
        ...props.property,
        invalid: false,
        address: props.property.address,
        placeId: props.property.placeId,
        country: props.property.origin.country,
        state: props.property.origin.state,
        city: props.property.origin.city,
        operationType: props.property.operationType === 'RENT',
        pictures: [],
        updatedPictures: null,
        location: {
          lat: props.property.location.latitude,
          lng: props.property.location.longitude,
        },
        price: this.formatPriceValue(props.property.price.toString())
      };
    } else {
      this.state = {
        invalid: false,
        operationType: true,
        propertyType: '',
        country: 'México',
        state: 'Puebla',
        address: '',
        placeId: '',
        city: '',
        description: '',
        price: '',
        commission: '',
        zone: '',
        squareMeters: 0,
        constructionMeters: 0,
        bedrooms: 0,
        bathrooms: 0,
        pictures: [],
        location: {
          lat: 19.0379295,
          lng: -98.2034607,
        },
      };
    }
    console.log('!!!!!!!!!!!!!', this.state.location);
  }

  editar = false;

  sp = {
    HOUSE: 'Casa',
    APPARTMENT: 'Departamento',
    LAND: 'Terreno',
    OFFICE: 'Oficina',
    SHOP: 'Local',
    MEDICAL: 'Consultorio',
    STORAGE: 'Bodega',
    FACILITY: 'Nave industrial',
    BUILDING: 'Edificio',
    RANCH: 'Finca',
  };

  formatPriceValue (price) {
    debugger
    let val = price.replace(/,/g, '');
    const exp = /(\d)(?=(\d{3})+(?!\d))/g;
    const rep = '$1,';
    let arr = val.toString().split('.');
    arr[0] = arr[0].replace(exp,rep);
    let newPrice= arr[1] ? arr.join('.'): arr[0];
    return newPrice
  }

  setMapInfo(address, placeId) {
    this.setState({ address, placeId });
  }

  handleOrigin(address_component) {
    console.log('@@@@@@@@@@');
    const { country, state, city, zone } = this.state;
    let zoneSet = false;
    const origin = {
      country,
      state,
      city,
      zone,
    };
    address_component.forEach(entry => {
      if (
        (entry.types.includes('sublocality') ||
          entry.types.includes('neighborhood')) &&
        !zoneSet
      ) {
        zoneSet = true;
        origin.zone = entry.short_name;
      } else if (entry.types.includes('administrative_area_level_1')) {
        origin.state = entry.long_name;
      } else if (
        entry.types.includes('locality') ||
        entry.types.includes('administrative_area_level_2') ||
        (zoneSet && entry.types.includes('sublocality'))
      ) {
        origin.city = entry.short_name;
      } else if (entry.types.includes('country')) {
        origin.country = entry.long_name;
      }
    });
    this.setState(origin);
  }

  handleChange({ target }) {
    const { name, value, type } = target;
    let val = value;
    switch (type) {
      case 'number':
        val = parseFloat(value, 10);
        if (name === 'constructionMeters' && isNaN(val)) {
          val = 0;
        }
        break;
      case 'select-one':
        val = target.selectedOptions[0].value;
        break;
      default:
        break;
    }
    this.setState((state, _) => ({
      [name]: val,
    }));
  }

  propertyTypes = [
    'HOUSE',
    'APPARTMENT',
    'LAND',
    'OFFICE',
    'STORAGE',
    'SHOP',
    'MEDICAL',
    'RANCH',
    'BUILDING',
    'FACILITY',
  ];

  countriesQuery = gql`
    {
      origins
      getUser {
        user {
          id
          firstName
          lastName
          phone
        }
      }
    }
  `;

  render() {
    const hasPropertyType = this.state.propertyType !== '';
    const hasBedrooms = this.state.bedrooms !== 0;
    const hasBathrooms = this.state.bathrooms !== 0;
    const hasLandMeters = this.state.squareMeters !== 0;
    const hasConstructionMeters = this.state.constructionMeters !== 0;
    const hasCountry = this.state.country !== '';
    const hasState = this.state.state !== '';
    const hasCity = this.state.city !== '';
    const hasZone = this.state.zone !== '';
    const hasCommission = this.state.commission !== '';

    const isLand = this.state.propertyType === 'LAND';
    const isOffice =
      this.state.propertyType === 'OFFICE' ||
      this.state.propertyType === 'RANCH' ||
      this.state.propertyType === 'BUILDING' ||
      this.state.propertyType === 'FACILITY' ||
      this.state.propertyType === 'MEDICAL';
    const isStorage = this.state.propertyType === 'STORAGE';
    const isShop = this.state.propertyType === 'SHOP';
    const isAppartment = this.state.propertyType === 'APPARTMENT';
    const isHouse = this.state.propertyType === 'HOUSE';

    const isRent = this.state.operationType === true;
    const isSale = this.state.operationType === false;
    return (
      <Container>
        <Row>
          <Col
            xs={12}
            md={{ size: 10, offset: 1 }}
            lg={{ size: 8, offset: 2 }}
            xl={{ size: 6, offset: 3 }}>
            <Query
              query={this.countriesQuery}
              onCompleted={data => {
                this.countries = data.origins.countries;
                if (!this.props.property) {
                  this.setState({
                    name: `${data.getUser.user.firstName} ${
                      data.getUser.user.lastName
                    }`,
                    cellphone: `${data.getUser.user.phone}`,
                  });
                }
              }}>
              {({ data, loading, error }) => {
                if (loading)
                  return (
                    <div className="parenorme centro tenorme verde bold">
                      Cargando...
                    </div>
                  );
                if (error) return <p>{error.message}</p>;

                const { countries } = data.origins;
                return (
                  <React.Fragment>
                    <div className="parmuygrande">
                      <div className="centro pabenorme wbold tgrande blanco">
                        <div className="fondoVerde pladosmediano parchico pabmuychico">
                          {this.props.tipo}
                        </div>
                      </div>
                      <div className="pabchico">
                        <div className="wbold centro pabmuychico">
                          Tipo de operación
                        </div>
                        <Row>
                          <Col
                            xs={4}
                            onClick={() =>
                              this.setState({
                                operationType: false,
                              })
                            }
                            className="verde wbold derecha manita">
                            Venta
                          </Col>
                          <Col xs={4} className="centro">
                            <Switch
                              name="operationType"
                              onChange={value =>
                                this.setState((state, _) => ({
                                  operationType: !state.operationType,
                                }))
                              }
                              checked={this.state.operationType}
                              uncheckedIcon={<div> </div>}
                              checkedIcon={<div> </div>}
                              width={40}
                              height={20}
                              offColor="#008742"
                              onColor="#255474"
                              id="normal-switch"
                            />
                          </Col>
                          <Col
                            xs={4}
                            onClick={() =>
                              this.setState({
                                operationType: true,
                              })
                            }
                            className="azul wbold izquierda manita">
                            Renta
                          </Col>
                        </Row>
                      </div>
                      {/*propertyType*/}
                      <FormGroup className="sinpym">
                        <InputGroup>
                          <InputGroupAddon
                            className="fondoVerde"
                            addonType="prepend">
                            <div className="paddingAddon tmediana centro">
                              <div className="icon-casa blanco" />
                            </div>
                          </InputGroupAddon>
                          <Input
                            name="propertyType"
                            onChange={this.handleChange.bind(this)}
                            invalid={this.state.propertyType === ''}
                            defaultValue={
                              this.props.property
                                ? this.props.property.propertyType
                                : 'ffhhffkk'
                            }
                            type="select">
                            <option value="ffhhffkk" hidden>
                              Seleccione tipo de propiedad
                            </option>
                            {this.propertyTypes.map(property => (
                              <option value={property}>
                                {this.sp[property]}
                              </option>
                            ))}
                          </Input>
                        </InputGroup>
                        <FormFeedback>
                          Seleccione el tipo de propiedad
                        </FormFeedback>
                      </FormGroup>
                      {/*if es terreno oficina bodega o local entonces no
                                                                  seleccionar recamaras*/}
                      {/*rooms*/}
                      {isAppartment || isHouse ? (
                        <React.Fragment>
                          <FormGroup className="sinpym">
                            <InputGroup>
                              <InputGroupAddon
                                className="fondoVerde"
                                addonType="prepend">
                                <div className="paddingAddon tmediana centro">
                                  <div className="icon-cama blanco" />
                                </div>
                              </InputGroupAddon>
                              <Input
                                name="bedrooms"
                                onChange={this.handleChange.bind(this)}
                                invalid={this.state.rooms === ''}
                                defaultValue={
                                  this.props.property
                                    ? `${this.state.bedrooms}`
                                    : 'tipo'
                                }
                                type="select">
                                <option value="tipo" hidden>
                                  ¿Cuántas recámaras tiene?
                                </option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                              </Input>
                            </InputGroup>
                            <FormFeedback>
                              Indique cuántas recámaras tiene su propiedad
                            </FormFeedback>
                          </FormGroup>
                        </React.Fragment>
                      ) : (
                        false
                      )}
                      {/*bathrooms*/}
                      {/*en todos menos en terreno*/}
                      {hasBedrooms &&
                        !(isLand || isOffice || isStorage || isShop) && (
                          <FormGroup className="sinpym">
                            <InputGroup>
                              <InputGroupAddon
                                className="fondoVerde"
                                addonType="prepend">
                                <div className="paddingAddon tmediana centro">
                                  <div className="icon-escusado blanco" />
                                </div>
                              </InputGroupAddon>
                              <Input
                                name="bathrooms"
                                onChange={this.handleChange.bind(this)}
                                invalid={this.state.bathrooms === ''}
                                type="select"
                                defaultValue={
                                  this.props.property
                                    ? `${this.props.property.bathrooms}`
                                    : 'tipo'
                                }>
                                <option value="tipo" hidden>
                                  ¿Cuántos baños tiene?
                                </option>
                                <option value="0">No tiene baño</option>
                                <option value=".5">Medio</option>
                                <option value="1">Uno</option>
                                <option value="1.5">Uno y medio</option>
                                <option value="2">Dos</option>
                                <option value="2.5">Dos y medio</option>
                                <option value="3">Tres</option>
                                <option value="3.5">Tres y medio</option>
                                <option value="4">Cuatro</option>
                                <option value="4.5">Cuatro y medio</option>
                                <option value="5">Cinco</option>
                                <option value="5.5">Cinco y medio</option>
                                <option value="6">Seis</option>
                                <option value="6.5">Seis y medio</option>
                                <option value="7">Siete</option>
                                <option value="7.5">Siete y medio</option>
                                <option value="8">Ocho</option>
                                <option value="8.5">Ocho y medio</option>
                              </Input>
                            </InputGroup>
                            <FormFeedback>
                              Indique cuántos baños tiene su propiedad
                            </FormFeedback>
                          </FormGroup>
                        )}
                      {/*squareMeters*/}
                      {(hasBathrooms ||
                        (isLand || isOffice || isShop || isStorage)) && (
                        <FormGroup className="sinpym">
                          <InputGroup>
                            <InputGroupAddon
                              className="fondoVerde"
                              addonType="prepend">
                              <div className="paddingAddon tmediana centro">
                                <div className="icon-regla blanco" />
                              </div>
                            </InputGroupAddon>
                            <Input
                              name="squareMeters"
                              onChange={this.handleChange.bind(this)}
                              invalid={this.state.squareMeters === 0}
                              type="number"
                              defaultValue={
                                this.props.property
                                  ? `${this.state.squareMeters}`
                                  : undefined
                              }
                              min="0"
                              step="1"
                              placeholder={
                                isLand
                                  ? 'Metros de propiedad'
                                  : 'Metros de terreno'
                              }
                            />
                          </InputGroup>
                          <FormFeedback>
                            Indique cuántos metros de terreno tiene su propiedad
                          </FormFeedback>
                        </FormGroup>
                      )}
                      {/*el siguiente solo si es casa departamento bodega oficina
                                                                  y local (todo menos terreno)*/}
                      {/*constructionMeters*/}
                      {hasLandMeters && !isLand && !isRent && (
                        <FormGroup className="sinpym">
                          <InputGroup>
                            <InputGroupAddon
                              className="fondoVerde"
                              addonType="prepend">
                              <div className="paddingAddon tmediana centro">
                                <div className="icon-construccion blanco" />
                              </div>
                            </InputGroupAddon>
                            <Input
                              name="constructionMeters"
                              onChange={this.handleChange.bind(this)}
                              invalid={this.state.constructionMeters === 0}
                              defaultValue={
                                this.props.property
                                  ? `${this.state.constructionMeters}`
                                  : undefined
                              }
                              type="number"
                              min="0"
                              step="1"
                              placeholder="Metros de construcción"
                            />
                          </InputGroup>
                          <FormFeedback>
                            Indique cuántos metros de construcción tiene su
                            propiedad
                          </FormFeedback>
                        </FormGroup>
                      )}
                      {/*description*/}
                      {(hasConstructionMeters ||
                        (hasLandMeters && isRent
                          ? isOffice ||
                            isStorage ||
                            isShop ||
                            isLand ||
                            isHouse ||
                            isAppartment
                          : hasLandMeters && isSale && isLand)) && (
                        <FormGroup className="sinpym">
                          <InputGroup>
                            <InputGroupAddon
                              className="fondoVerde"
                              addonType="prepend">
                              <div className="paddingAddon tmediana centro">
                                <div className="icon-chat-3 blanco" />
                              </div>
                            </InputGroupAddon>
                            <Input
                              name="description"
                              onChange={this.handleChange.bind(this)}
                              invalid={this.state.description === ''}
                              type="textarea"
                              rows={5}
                              maxLength="1200"
                              defaultValue={
                                this.props.property
                                  ? this.state.description
                                  : undefined
                              }
                              placeholder="Descripción del inmueble"
                            />
                          </InputGroup>
                          <FormFeedback>
                            Indique el precio en el que desea comercializar está
                            propiedad
                          </FormFeedback>
                        </FormGroup>
                      )}
                      {/*price*/}
                      <FormGroup className="sinpym">
                        <InputGroup>
                          <InputGroupAddon
                            className="fondoAzul"
                            addonType="prepend">
                            <div className="paddingAddon tmediana centro">
                              <div className="icon-dolar blanco" />
                            </div>
                          </InputGroupAddon>
                          <Input
                            name="price"
                            onChange={({ target }) => {
                              let newPrice = this.formatPriceValue(target.value);
                              this.setState({ price:  newPrice});
                              }
                            }
                            invalid={this.state.price === ''}
                            value={this.state.price}
                            lang="en-150"
                            defaultValue={
                              this.props.property ? this.state.price : undefined
                            }
                            min="0"
                            step="1000"
                            placeholder={`Precio ${
                              this.state.operationType ? 'de renta' : 'de venta'
                            }`}
                          />
                        </InputGroup>
                        <FormFeedback>
                          Indique el precio en el que desea comercializar está
                          propiedad
                        </FormFeedback>
                      </FormGroup>
                      {/*commission*/}
                      <FormGroup className="sinpym">
                        {this.state.operationType === false && (
                          <InputGroup>
                            <InputGroupAddon
                              className="fondoRojo"
                              addonType="prepend">
                              <div className="paddingAddon tmediana centro">
                                <div className="icon-porcentaje blanco" />
                              </div>
                            </InputGroupAddon>
                            <Input
                              name="commission"
                              onChange={this.handleChange.bind(this)}
                              invalid={this.state.commission === ''}
                              defaultValue={this.state.commission}
                              type="select">
                              <option value="tipo" hidden>
                                Comisión que desea otorgar
                              </option>
                              <option value=".5">% 1</option>
                              <option value="1.5">% 1.5</option>
                              <option value="2">% 2</option>
                              <option value="2.5">% 2.5</option>
                              <option value="3">% 3</option>
                              <option value="3.5">% 3.5</option>
                              <option value="4">% 4</option>
                              <option value="4.5">% 4.5</option>
                              <option value="5">% 5</option>
                              <option value="5.5">% 5.5</option>
                              <option value="6">% 6</option>
                              <option value="6.5">% 6.5</option>
                              <option value="7">% 7</option>
                              <option value="7.5">% 7.5</option>
                              <option value="8">% 8</option>
                              <option value="8.5">% 8.5</option>
                              <option value="9">% 9</option>
                              <option value="9.5">% 9.5</option>
                              <option value="10">% 10</option>
                            </Input>
                          </InputGroup>
                        )}

                        {this.state.operationType === true && (
                          <InputGroup>
                            <InputGroupAddon
                              className="fondoRojo"
                              addonType="prepend">
                              <div className="paddingAddon tmediana centro">
                                <div className="icon-porcentaje blanco" />
                              </div>
                            </InputGroupAddon>
                            <Input
                              name="commission"
                              onChange={this.handleChange.bind(this)}
                              invalid={this.state.commission === ''}
                              defaultValue={
                                this.props.property
                                  ? `${this.state.commission}`
                                  : 'tipo'
                              }
                              type="select">
                              <option value="tipo" hidden>
                                Comisión que desea otorgar
                              </option>
                              <option value=".5">%50 de un mes de renta</option>
                              <option value=".75">
                                %75 de un mes de renta
                              </option>
                              <option value="1">%100 de un mes de renta</option>
                              <option value="1.25">
                                %125 de un mes de renta
                              </option>
                              <option value="1.5">
                                %150 de un mes de renta
                              </option>
                              <option value="1.75">
                                %175 de un mes de renta
                              </option>
                              <option value="2">%200 de un mes de renta</option>
                              <option value="3">%300 de un mes de renta</option>
                              <option value="4">%400 de un mes de renta</option>
                              <option value="5">%500 de un mes de renta</option>
                            </Input>
                          </InputGroup>
                        )}
                        <FormFeedback>
                          Ingrese la comisión que esta dispuesto a otorgar a
                          otra persona
                        </FormFeedback>
                      </FormGroup>

                      <MapsGoogleMaps
                        lat={this.state.location.lat}
                        lng={this.state.location.lng}
                        setOrigin={this.handleOrigin.bind(this)}
                        setLocation={location => this.setState({ location })}
                        setAddresAndPlaceId={this.setMapInfo.bind(this)}
                      />

                      <Row>
                        <Col xs={12} className="parchico">
                          <div className="fondoGrisClaro pchico">
                            <FormGroup className="sinpym">
                              <Label className="wbold rojo" for="fotos">
                                FOTOS
                              </Label>
                              <div className="wbold">
                                Favor de subir en este orden:
                              </div>
                              <div className="pizchico">
                                <span className="wbold">1</span>
                                .- Fachada
                              </div>
                              <div className="pizchico">
                                <span className="wbold">2</span>
                                .- Interiores
                              </div>
                              <div className="pizchico">
                                <span className="wbold">3</span>
                                .- Exteriores
                              </div>
                              <div className="pizchico">
                                <span className="wbold">4</span>
                                .- Areas comunes
                              </div>
                            </FormGroup>
                            {/*drop de fotos*/}
                            <Drop
                              imagenes={
                                this.props.property
                                  ? this.props.property.pictures
                                  : []
                              }
                              setPictures={files =>
                                this.setState(state => ({
                                  pictures: files,
                                }))
                              }
                              setReordering={orders => {
                                console.log(orders);
                                this.setState({ updatedPictures: orders });
                              }}
                              addDeletedPicture={deletedPicture =>
                                this.setState(state => ({
                                  deletedPictures: state.deletedPictures
                                    ? state.deletedPictures.concat([
                                        deletedPicture,
                                      ])
                                    : [deletedPicture],
                                }))
                              }
                            />
                            {/*fotos (que se hagan chicas en peso KB automaticamente)*/}

                            {false && (
                              <EliminarImagenes
                                imagenes={this.props.property.pictures}
                                setImagenes={img => {
                                  this.setState(state => {
                                    if (!state.deletedPictures) {
                                      return { deletedPictures: [img] };
                                    } else {
                                      return {
                                        deletedPictures: state.deletedPictures.concat(
                                          [img],
                                        ),
                                      };
                                    }
                                  });
                                }}
                              />
                            )}

                            <div className="mt-3 rojo wbold">
                              EVITE MOLESTIAS:
                            </div>
                            <div className="tchica justificado">
                              *Las fotos no pueden tener ni logotipo, ni datos
                              del usuario o inmobiliaria, ni contenido ajeno al
                              inmueble. De lo contrario no se publicará.
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <div className="centro parchico">
                        <Mutation
                          mutation={this.props.mutation}
                          context={{ token: this.context.token }}>
                          {(
                            createProperty,
                            { called, loading, data, error },
                          ) => (
                            <Button
                              onClick={e => {
                                e.preventDefault();
                                const {
                                  id,
                                  active,
                                  invalid,
                                  cellphone,
                                  name,
                                  country,
                                  city,
                                  state,
                                  bedrooms,
                                  bathrooms,
                                  squareMeters,
                                  constructionMeters,
                                  price,
                                  commission,
                                  deletedPictures,
                                  dueDate,
                                  operationType,
                                  pictures,
                                  updatedPictures,
                                  location,
                                  datasheet,
                                  __typename,
                                  ...rest
                                } = this.state;

                                if (this.props.property) {
                                  rest.id = this.props.property.id;
                                }
                                if (pictures && pictures.length > 0) {
                                  rest.pictures = pictures;
                                }
                                rest.origin = {
                                  city,
                                  state,
                                  country: country || 'MX',
                                };

                                rest.coordinates = {
                                  latitude: this.state.location.lat,
                                  longitude: this.state.location.lng
                                };
                                      console.log(rest)
                                if (
                                  updatedPictures &&
                                  updatedPictures.length > 0
                                ) {
                                  rest.updatedPictures = updatedPictures;
                                }
                                rest.operationType = operationType
                                  ? 'RENT'
                                  : 'BUY';
                                rest.bedrooms = parseFloat(bedrooms) || 0;
                                rest.bathrooms = parseFloat(bathrooms) || 0;
                                rest.squareMeters = parseFloat(squareMeters);
                                rest.price = parseFloat(price.replace(/,/g, ''));
                                rest.constructionMeters = parseFloat(
                                  constructionMeters,
                                );
                                rest.commission = parseFloat(commission);
                                if (deletedPictures) {
                                  console.log(
                                    'deletePictures',
                                    deletedPictures,
                                  );
                                  rest.deletedPictures = deletedPictures;
                                }
                                console.log(rest);

                                this.props.propertyValidator
                                  .validate(rest)
                                  .then(validated =>
                                    this.props.propertyValidator.cast(
                                      validated,
                                    ),
                                  )
                                  .then(validated => {
                                    console.log(validated);
                                    createProperty({
                                      variables: { data: validated },
                                    })
                                      .then(res => {
                                        console.log(res);
                                        Swal(
                                          'Felicidades',
                                          'Tu propiedad se subió con exito.',
                                          'success',
                                        ).then(redirect => {
                                          console.log('@@@@@', res);
                                          const op =
                                            res.data &&
                                            res.data.createProperty !==
                                              undefined
                                              ? res.data.createProperty
                                              : res.data.updateProperty;
                                          this.props.history.push(
                                            `/propiedad/${
                                              op.property.id
                                            }?o=true`,
                                          );
                                        });
                                      })
                                      .catch(err => {
                                        console.log(err);
                                        Swal(
                                          'Error',
                                          'Error al subir propiedad',
                                          'error',
                                        );
                                      });
                                  })
                                  .catch(err => {
                                    console.log(err);
                                    Swal(
                                      'Error',
                                      'Porfavor valide todos los campos',
                                      'error',
                                    );
                                  });
                              }}
                              className="botonAzul claseBoton2 w100"
                              disabled={loading}>
                              <div className="parmuychico ">Publicar</div>
                            </Button>
                          )}
                        </Mutation>
                      </div>
                      <div className="centro parmuychico pabmuygrande">
                        <Button
                          tag={Link}
                          to="/mis-propiedades"
                          className="botonRojo claseBoton2 w100">
                          <div className="parmuychico ">Cancelar</div>
                        </Button>
                      </div>
                    </div>
                  </React.Fragment>
                );
              }}
            </Query>
          </Col>
        </Row>
      </Container>
    );
  }
}
ContenidoPropiedad.contextType = AuthContext;
export default withRouter(ContenidoPropiedad);
