import React, { PureComponent } from 'react';
import { Query, Mutation } from '@apollo/react-components';
import ModalTarjeta from './ModalTarjeta';
import gql from 'graphql-tag';
import { FaCcMastercard, FaCcVisa, FaCcAmex ,FaCreditCard} from 'react-icons/fa';
import {
  Button,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  Badge,
  Spinner,
} from 'reactstrap';
import Swal from 'sweetalert';

export default class Pago extends PureComponent {
  state = { addCardOpen: false, selectedCard: null, cvc: '', tooltip: false };

  constructor(props) {
    super(props);
    this.iref = React.createRef();
  }
  componentDidMount() {
    console.log(window.OpenPay);
    const { OpenPay } = window;
    OpenPay.setId('m88ssnn7gbzbpsxgjeq3');
    OpenPay.setApiKey('pk_8ef63bcd6ff5465ab33f7b3c7300771c');
    OpenPay.setSandboxMode(true);
  }

  tooltip() {
    this.setState({ tooltip: !this.state.tooltip });
  }

  payMutation = gql`
    mutation($data: purchaseMembershipInput!) {
      purchaseMembership(data: $data) {
        status
      }
    }
  `;
  userQuery = gql`
    {
      getUser {
        user {
          id
          cards {
            id
            card_number
            brand
          }
          cart {
            items {
              id
              description
              budget
              rentBudget
              price
              zones
              propertyType
              operationType
              origin {
                city
              }
            }
            sum
          }
        }
      }
    }
  `;

  cardIcons = {
    visa: <FaCcVisa style={{ color: 'darkblue', fontSize: 30 }} />,
    mastercard: <FaCcMastercard style={{ color: '#F39B36', fontSize: 30 }} />,
    american_express: <FaCcAmex style={{ color: '#0A5EC9', fontSize: 30 }} />,
    carnet: <FaCreditCard style={{ color: '#D21E12', fontSize: 30 }} />,
    
  };
  render() {
    return (
      <Query
        query={this.userQuery}
        onCompleted={data => {
          if (
            data.getUser &&
            data.getUser.user.cards &&
            data.getUser.user.cards.length === 1
          ) {
            this.setState({ selectedCard: data.getUser.user.cards[0] });
          }
        }}>
        {({ data, loading, refetch }) => {
          if (loading || !data) {
            return <Spinner size="lg" />;
          }
          const { items, sum } = data.getUser.user.cart;
          const { cards } = data.getUser.user;
          return (
            <Row className="mt-4">
              <Col xs={12} md={8}>
                <span className="h2">Productos:</span>
                <ListGroup>
                  {items.map(item => (
                    <ListGroupItem key={`item-${item.id}`}>
                      <Row>
                        <Col xs={8}>
                          <ListGroupItemHeading>
                            Cliente que busca{' '}
                            <span className="wbold minus verde">
                              {item.operationType === 'BUY' && 'COMPRAR'}
                              {item.operationType === 'BUYRENT' &&
                                'COMPRAR o RENTAR'}
                              {item.operationType === 'RENT' && 'RENTAR'}
                            </span>{' '}
                            <span className="minus wbold">
                              {item.propertyType === 'APPARTMENT' &&
                                'departamento'}
                              {item.propertyType === 'HOUSE' && 'Casa'}
                              {item.propertyType === 'OFFICE' && 'Oficina'}
                              {item.propertyType === 'LAND' && 'Terreno'}
                              {item.propertyType === 'SHOP' && 'Local'}
                              {item.propertyType === 'STORAGE' && 'Bodega'}{' '}
                            </span>{' '}
                            en {item.origin.city}: {item.zones.join(', ')} por{' '}
                            {item.budget && (
                              <React.Fragment>${item.budget}</React.Fragment>
                            )}{' '}
                            {item.rentBudget && (
                              <React.Fragment>
                                ${item.rentBudget}
                              </React.Fragment>
                            )}{' '}
                          </ListGroupItemHeading>
                          <ListGroupItemHeading>
                            "{item.description}"
                          </ListGroupItemHeading>
                        </Col>
                        <Col xs={4} className="centro">
                          <Badge className="tgrande fondoVerde">
                            ${item.price}
                          </Badge>{' '}
                        </Col>
                      </Row>
                    </ListGroupItem>
                  ))}
                </ListGroup>
                <hr />
                <div className="derecha tenorme wbold pdemuychico">
                  <Badge className="fondoVerde blanco ">
                    <div className="parmuychico">Total: ${sum}</div>
                  </Badge>
                </div>
              </Col>
              <Col className="justify-content-center">
                {cards.length === 0 ? (
                  <h2 className="text-center">No hay tarjetas guardadas</h2>
                ) : (
                  <h2 className="text-center">
                    Seleccione una tarjeta para pagar
                  </h2>
                )}
                <ListGroup>
                  {cards.map(card => {
                    console.log("card", card)
                    return (
                      <ListGroupItem
                        tag="button"
                        onClick={() => {
                          this.setState({ selectedCard: card.id });
                        }}
                        className={
                          this.state.selectedCard === card.id
                            ? 'fondoGrisClaro'
                            : 'fondoBlanco'
                        }
                        action
                        key={card.id}>
                        {this.cardIcons[card.brand]}{' '}
                        <span className="font-weight-italic">
                          {card.card_number}
                        </span>
                      </ListGroupItem>
                    );
                  })}
                  <ListGroupItem
                    tag="button"
                    onClick={() =>
                      this.setState(state => ({
                        addCardOpen: !state.addCardOpen,
                      }))
                    }
                    action>
                    Añadir tarjeta
                  </ListGroupItem>
                </ListGroup>
                <hr />

                <Mutation
                  mutation={this.payMutation}
                  onCompleted={() =>
                    Swal(
                      'Pago exitoso',
                      'Los clientes ahora son tuyos, sus datos lo puedes ver en tus clientes',
                      'success',
                    )
                  }
                  onError={() =>
                    Swal(
                      'Error',
                      'Lo sentimos su tarjeta no paso, intentelo de nuevo o comuniquese con nosotros al 2228377162.',
                      'error',
                    )
                  }>
                  {(
                    cartPurchase,
                    { loading: loadingMutation, data: dataMutation },
                  ) => (
                    <React.Fragment>
                      <Button
                        size="lg"
                        onPointerEnter={() => {
                          this.setState({ tooltip: true });
                        }}
                        onPointerLeave={() => this.setState({ tooltip: false })}
                        onClick={() => {
                          Swal({
                            text:
                              'Ingrese los 3 digitos al reverso de la tarjeta (CVC)',
                            content: {
                              element: 'input',
                              attributes: {
                                placeholder: 'CVC',
                                type: 'text',
                              },
                            },
                          }).then(cvc => {
                            if (cvc) {
                              cartPurchase({
                                variables: {
                                  data: {
                                    plan_id: 'p2a2uknbpryhejgjkq04',
                                    source_id: this.state.selectedCard
                                  },
                                },
                              })
                                .then(console.log)
                                .catch(console.error);
                            }
                          });
                        }}
                        className="mt-2 botonVerde"
                        disabled={
                          loadingMutation || this.state.selectedCard === null
                        }>
                        Pagar
                      </Button>
                      {this.state.tooltip &&
                        this.state.selectedCard === null && (
                          <span className="pizmediano wbold verde">
                            Seleccione o agregue una tarjeta
                          </span>
                        )}
                    </React.Fragment>
                  )}
                </Mutation>
                <ModalTarjeta
                  open={this.state.addCardOpen}
                  toggle={() => {
                    this.setState(state => ({
                      addCardOpen: !state.addCardOpen,
                    }));
                    refetch();
                  }}
                />
              </Col>
            </Row>
          );
        }}
      </Query>
    );
  }
}
