import React, { Component } from 'react';
import AuthContext from '../../../../../AuthContext';
import { Row, Col, Button, Input } from 'reactstrap';
import { Mutation } from '@apollo/react-components';
import gql from 'graphql-tag';
import ContactoLead from './ContactoLead';
import ContenidoLead from '../../Prospectos/lead/ContenidoLead';

function numberWithCommas(x) {
  x = Math.round(x).toString();
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x)) x = x.replace(pattern, '$1,$2');
  return x;
}

class LeadAceptado extends Component {
  state = { aceptado: false };

  updateLeadMutation = gql`
    mutation UpdateLead($data: leadUpdateInput!) {
      updateLead(data: $data) {
        lead {
          id
          useful
          deleted
        }
      }
    }
  `;

  aceptado() {
    this.setState({ aceptado: !this.state.aceptado });
  }
  render() {
    return (
      <div className="pabmuychico centered2">
        {this.props.lead.accepted && (
          <div className="fondoBlanco sombraTodo contenedor3 heightLeadAceptados">
            <div className="pmediano">
              <ContactoLead lead={this.props.lead} />
              <Row className="parchico">
                <Col
                  xs={12}
                  className="wbold letraLeadDatos izquierda verde sinpymde">
                  PRESUPUESTO: $
                  {numberWithCommas(
                    this.props.lead.budget * this.props.currency.factor,
                  )}
                  <span className="tmuychica">{this.props.currency.name}</span>
                </Col>
              </Row>
              <div className="negro izquierda pabchico">
                <ContenidoLead lead={this.props.lead} />
                <div className="bottom pdechico">
                  <div className="pizmuychico pdemuychico parchico">
                    <div className="azul wbold">SEGUIMIENTO:</div>
                    <Input
                      type="textarea"
                      rows={3}
                      className="fondoAzulClaro azul"
                      placeholder="Seguimiento a cliente..."
                      onChange={({ target }) => {
                        this.setState({
                          followUp: target.value,
                        });
                      }}
                      defaultValue={this.props.lead.followUp}
                    />
                  </div>
                  <Row className="derecha pizmediano pdemuychico parmuychico">
                    <Col className="sinpymlados centro pabchico">
                      <Mutation
                        mutation={this.updateLeadMutation}
                        context={{ token: this.context.token }}>
                        {(updateLead, { error, loading, called, data }) => {
                          return (
                            <Button
                              className="botonVerde parmuychico claseBoton3 widthRemoverBoton"
                              disabled={
                                !this.state.followUp &&
                                this.state.followUp ===
                                  this.props.lead.followUp &&
                                loading
                              }
                              onClick={() => {
                                updateLead({
                                  variables: {
                                    data: {
                                      id: this.props.lead.id,
                                      followUp: this.state.followUp,
                                    },
                                  },
                                });
                              }}>
                              <div className="pizmicro pdemicro">
                                Guardar seguimiento
                              </div>
                            </Button>
                          );
                        }}
                      </Mutation>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
LeadAceptado.contextType = AuthContext;
export default LeadAceptado;
