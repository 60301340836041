import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Button,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from 'reactstrap';
import FsLightbox from 'fslightbox-react';
import PropertyContext from '../../../../PropertyContext';
import ImgDefault from '../../../imgs/img-default.jpg';
import ComponenteContactar from './ComponenteContactar';
import { Mutation } from '@apollo/react-components';
import gql from 'graphql-tag';
import swal from '@sweetalert/with-react';
import MyFavorita from './Favorita';

function numberWithCommas(x) {
  x = Math.round(x).toString();
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x)) x = x.replace(pattern, '$1,$2');
  return x;
}

class Propiedad extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      aceptado: false,
      // id: `carousel-${this.props.property.id}`,
      publicar: this.props.property.active,
      cambioPublicar: true,
      isMyFovoriteStatus: 'normal'
    };
  }

  deleteProperty = gql`
    mutation($filter: propertyUniqueWhereInput!) {
      deleteProperty(filter: $filter) {
        propertyId
      }
    }
  `;

  deactivateProperty = gql`
    mutation($data: propertyUpdateInput!) {
      updateProperty(data: $data) {
        property {
          id
          active
        }
      }
    }
  `;

  cambioPublicar() {
    this.setState({
      publicar: !this.state.publicar,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.activeIndex !== prevState.activeIndex) {
      const id = `${this.state.id}-p${this.state.activeIndex}`;
      const pic = document.getElementById(id);
      if (pic && pic.dataset.src) {
        pic.src = pic.dataset.src;
        delete pic.dataset.src;
      }
    }
  }

  aceptado() {
    this.setState({ aceptado: !this.state.aceptado });
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === this.props.property.pictures.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? this.props.property.pictures.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  sp = {
    HOUSE: 'Casa',
    APPARTMENT: 'Departamento',
    LAND: 'Terreno',
    OFFICE: 'Oficina',
    SHOP: 'Local',
    MEDICAL: 'Consultorio',
    STORAGE: 'Bodega',
    FACILITY: 'Nave industrial',
    BUILDING: 'Edificio',
    RANCH: 'Finca',
  };

  getMessage(property) {
    const { active, reviewed, accepted } = property;
    if (reviewed) {
      if (!accepted) {
        return 'Propiedad rechazada';
      } else {
        return active ? 'Activa' : 'Inactiva';
      }
    } else {
      return 'Propiedad en aprobación';
    }
  }

  setFavoriteStatus(status) {
    this.setState({
      isMyFovoriteStatus: status
    });
  }

  render() {
    // FRONT REPLICANDO APP
    // const { activeIndex } = this.state;
    // const slides = this.props.property.pictures.map((foto, i) => {
    //   return (
    //     <CarouselItem
    //       onExiting={this.onExiting.bind(this)}
    //       onExited={this.onExited.bind(this)}
    //       key={`ci${i}`}>
    //       {i === 0 ? (
    //         <img
    //           async
    //           src={`${foto.url}`}
    //           id={`${this.state.id}-p${i}`}
    //           className="tfoto"
    //           alt={`i${i}`}
    //           onClick={e => {
    //             this.setState((state, props) => ({
    //               modalIsOpen: !state.modalIsOpen,
    //             }));
    //           }}
    //         />
    //       ) : (
    //         <img
    //           async
    //           data-src={`${foto.url}`}
    //           id={`${this.state.id}-p${i}`}
    //           className="tfoto"
    //           alt={`i${i}`}
    //           onClick={e => {
    //             this.setState((state, props) => ({
    //               modalIsOpen: !state.modalIsOpen,
    //             }));
    //           }}
    //         />
    //       )}
    //     </CarouselItem>
    //   );
    // });

    const hasBedrooms = this.props.property.bedrooms !== 0;
    const hasBathrooms = this.props.property.bathrooms !== 0;
    const hasLandMeters = this.props.property.squareMeters !== 0;
    const hasConstructionMeters = this.props.property.constructionMeters !== 0;

    const isHouse = this.props.property.propertyType === 'HOUSE';
    const isAppartment = this.props.property.propertyType === 'APPARTMENT';
    const isLand = this.props.property.propertyType === 'LAND';
    const isOffice =
      this.props.property.propertyType === 'OFFICE' ||
      this.props.property.propertyType === 'MEDICAL' ||
      'RANCH' ||
      'BUILDING';
    const isStorage = this.props.property.propertyType === 'STORAGE';
    const isShop = this.props.property.propertyType === 'SHOP';

    const isRent = this.props.property.operationType === 'RENT';
    return (
      <div className="centro">
        <FsLightbox
          slide={this.state.activeIndex}
          toggler={this.state.modalIsOpen}
          sources={this.props.property.pictures.map(pic => pic.url)}
        />
        <div className="pabmediano centered2">
          <div
            className={`${
              this.props.miPropiedad ? 'tmipropiedad' : 'tpropiedad'
            } fondoBlanco sombraPropiedad`}>
            {this.props.miPropiedad && (
              <div className="gris parmuychico mayus tchica wbold">
                {this.getMessage(this.props.property)}
              </div>
            )}

            <Link
              className="linkSF"
              to={`/propiedad/${this.props.property.id}${
                this.props.miPropiedad ? '?o=true' : ''
              }`}>
              {/*AreaTransparencia*/}
              <React.Fragment>
                <div
                  className={`${!this.props.miPropiedad &&
                    'redondeadoArribaPropiedad'} fondoBlancoOpacity blanco wbold parmuychico posicionAbsoluta`}>
                  <Row>
                    <Col xs={6} className="sinpymde">
                      <div className="pizchico izquierda verde">
                        $
                        {numberWithCommas(
                          this.props.property.price *
                            this.props.currency.factor,
                        )}
                        <span className="tmuychica">
                          {this.props.currency.name}
                        </span>
                      </div>
                    </Col>
                    <Col xs={6} className="derecha sinpymiz azul">
                      <div className="pdechico">
                        {this.props.property.operationType === 'RENT' ? (
                          <div>
                            <span className="tchica">COMISIÓN:</span>{' '}
                            {this.props.property.commission === 0.5 ? (
                              <span className="tchica">
                                <sup>1</sup>&frasl;
                                <sub>2</sub>
                              </span>
                            ) : (
                              <span className="tchica">
                                {this.props.property.commission}
                              </span>
                            )}{' '}
                            <span className="tchica">
                              {this.props.property.commission >= 1.1
                                ? 'meses'
                                : 'mes'}
                            </span>
                          </div>
                        ) : (
                          <div>
                            <span className="tchica">Comisión:</span>{' '}
                            {this.props.property.commission}%
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              </React.Fragment>
              {/*  carrousel aqui no
                                    {this.props.property.pictures.length !== 0 ? (
                                      <Carousel
                                        activeIndex={activeIndex}
                                        next={this.next.bind(this)}
                                        previous={this.previous.bind(this)}
                                        interval={false}>
                                        <CarouselIndicators
                                          items={this.props.property.pictures.map((foto, i) => ({
                                            key: foto.url,
                                          }))}
                                          activeIndex={activeIndex}
                                          onClickHandler={this.goToIndex.bind(this)}
                                        />
                                        {slides}
                                        <CarouselControl
                                          direction="prev"
                                          directionText="Previous"
                                          onClickHandler={this.previous.bind(this)}
                                        />
                                        <CarouselControl
                                          direction="next"
                                          directionText="Next"
                                          onClickHandler={this.next.bind(this)}
                                        />
                                      </Carousel>
                                    ) : (
                                      <img
                                        src="https://blog.stylingandroid.com/wp-content/themes/lontano-pro/images/no-image-slide.png"
                                        className="tfoto"
                                      />
                                    )}
                                  */}
              <img
                className={`${!this.props.miPropiedad &&
                  'redondeadoArribaPropiedad'} tfoto`}
                src={
                  this.props.property.pictures[0]
                    ? this.props.property.pictures[0].url
                    : ImgDefault
                }
              />
              {/*operationType*/}
              <div
                className={`${
                  this.props.miPropiedad
                    ? 'fondoNegro posicionRenta'
                    : 'fondoVerde posicionRentaPublica'
                } blanco wbold parmicro`}
                style={{ top: this.props.miPropiedad ? 200 : 179 }}>
                {this.props.property.operationType === 'RENT'
                  ? 'RENTA'
                  : 'VENTA'}
              </div>
              {/*ComponenteAreaVerde*/}
              <React.Fragment>
                <div
                  className={`${
                    this.props.miPropiedad ? 'fondoNegro' : 'fondoVerde'
                  } pizchico pdechico blanco`}>
                  <div className="centro parmicro3 pabmicro">
                    <div className="wbold tchica">
                      <React.Fragment>
                        {this.sp[this.props.property.propertyType]}
                      </React.Fragment>
                      {this.props.property.furnished === true ? (
                        <React.Fragment>
                          {' '}
                          {this.props.property.propertyType === 'APPARTMENT' ||
                          'BUILDING' ||
                          'MEDICAL' ||
                          'LAND' ||
                          'SHOP'
                            ? 'amueblado'
                            : 'amueblada'}
                        </React.Fragment>
                      ) : (
                        ''
                      )}{' '}
                      |{' '}
                      {this.props.property.address
                        .replace(/,/g, ' -')
                        .slice(0, 77)}
                      {this.props.property.address.length > 77 && '...'}
                    </div>
                  </div>
                </div>
              </React.Fragment>
              {/*ComponenteIconos*/}
              <React.Fragment>
                <div className="pladoschico2 verde">
                  <div className="tchica parmuychico">
                    <React.Fragment>
                      {/*squareMeters*/}
                      <React.Fragment>
                        <div className="icon-regla-vacia d-inline tmediana" />{' '}
                        {this.props.property.squareMeters}
                        <span className="minus">m</span>
                        <sup>2</sup>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </React.Fragment>
                    </React.Fragment>
                    {isLand && (
                      <React.Fragment>
                        &nbsp;&nbsp;&nbsp; Precio por m² : $
                        {numberWithCommas(
                          this.props.property.price /
                            this.props.property.squareMeters,
                        )}
                      </React.Fragment>
                    )}
                    {!isLand && (
                      <React.Fragment>
                        {/*constructionMeters*/}

                        {!isRent && (
                          <React.Fragment>
                            <div className="icon-construccion d-inline" />{' '}
                            {this.props.property.constructionMeters}
                            <span className="minus">m</span>
                            <sup>2</sup>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </React.Fragment>
                        )}
                        {/*bedrooms*/}
                        {hasBedrooms && !(isShop || isStorage || isOffice) && (
                          <React.Fragment>
                            <div className="icon-cama d-inline tmediana" />{' '}
                            {this.props.property.bedrooms}
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </React.Fragment>
                        )}
                        {/*bathrooms*/}
                        {hasBathrooms && !(isShop || isStorage || isOffice) && (
                          <React.Fragment>
                            <div className="icon-escusado d-inline" />{' '}
                            {this.props.property.bathrooms}
                          </React.Fragment>
                        )}
                        {isRent && (isShop || isStorage || isOffice) && (
                          <React.Fragment>
                            &nbsp;&nbsp;&nbsp; Precio por m² : $
                            {numberWithCommas(
                              this.props.property.price /
                                this.props.property.squareMeters,
                            )}
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </React.Fragment>
            </Link>
            {/*Descripcion*/}
            <React.Fragment>
              <div className="tchica parmuychico pabchico pladoschico2">
                {this.props.property.description.substring(0, 65)}
                {this.props.property.description.length >= 65 && '...'}
              </div>
            </React.Fragment>
            <div className="centro pabchico posicionBotones">
              {this.props.miPropiedad ? (
                <div className="pladosenorme">
                  <Row>
                    <Col>
                      {/*detener*/}
                      {(this.props.property.active === true ||
                        this.state.publicar === true) &&
                        !(
                          this.props.property.active === false &&
                          this.state.publicar === false
                        ) && (
                          <div className="centro">
                            <Mutation mutation={this.deactivateProperty}>
                              {(deactivateProperty, { loading, called }) => (
                                <Button
                                  onClick={() => {
                                    if (!called && !loading) {
                                      deactivateProperty({
                                        variables: {
                                          data: {
                                            id: this.props.property.id,
                                            active: false,
                                          },
                                        },
                                      });
                                      this.cambioPublicar();
                                    }
                                  }}
                                  className="botonGris botonEditarPropiedad contenedor">
                                  <div className="icon-pausa blanco centro centrado tgrande" />
                                </Button>
                              )}
                            </Mutation>
                          </div>
                        )}
                      {/*publicar*/}
                      {(this.props.property.active === false ||
                        this.state.publicar === false) &&
                        (this.props.property.active !== true &&
                          this.state.publicar !== true) && (
                          <div className="centro">
                            <Mutation mutation={this.deactivateProperty}>
                              {(activateProperty, { loading, called }) => (
                                <Button
                                  onClick={() => {
                                    if (!called && !loading) {
                                      activateProperty({
                                        variables: {
                                          data: {
                                            id: this.props.property.id,
                                            active: true,
                                          },
                                        },
                                      });
                                      this.cambioPublicar();
                                    }
                                  }}
                                  className="botonVerde botonEditarPropiedad contenedor">
                                  <div className="icon-play blanco centro centrado tgrande" />
                                </Button>
                              )}
                            </Mutation>
                          </div>
                        )}
                    </Col>
                    <Col>
                      <Button
                        onClick={() => {
                          this.context.setProperty(this.props.property);
                          this.props.history.push(
                            `${this.props.match.url}/${this.props.property.id}`,
                          );
                        }}
                        className="botonAzul botonEditarPropiedad contenedor">
                        <div className="centro icon-editar centrado pizmicro" />
                      </Button>
                    </Col>
                    <Col>
                      <Mutation mutation={this.deleteProperty}>
                        {(deleteProperty, { loading, called }) => (
                          <div className="centro">
                            <Button
                              onClick={() => {
                                swal({
                                  title:
                                    '¿Estas seguro de eliminar la propiedad?',
                                  text:
                                    'Una vez eliminado, no será posible recuperar la propiedad',
                                  icon: 'warning',
                                  buttons: true,
                                  dangerMode: true,
                                }).then(willDelete => {
                                  if (willDelete) {
                                    swal(
                                      'Su propiedad ha sido ELIMINADA, exitosamente',
                                      {
                                        icon: 'success',
                                      },
                                    );
                                    deleteProperty({
                                      variables: {
                                        filter: {
                                          id: this.props.property.id,
                                        },
                                      },
                                    });
                                  } else {
                                    swal('Se cancelo');
                                  }
                                });
                              }}
                              className="botonRojo botonEditarPropiedad contenedor">
                              <div className="icon-quitar blanco centro centrado tgrande" />
                            </Button>
                          </div>
                        )}
                      </Mutation>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="centro tmuychica gris">
                      {this.props.property.active ? 'Detener' : 'Publicar'}
                    </Col>
                    <Col className="centro tmuychica gris">Editar</Col>
                    <Col className="centro tmuychica gris">Borrar</Col>
                  </Row>
                </div>
              ) : (<div className='favorite-container'>
                <ComponenteContactar
                  demo={this.props.demo}
                  property={this.props.property}
                  auth={{ user: this.props.property.advertiser }}
                />
                <MyFavorita property={this.props.property}/>
              </div>)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
Propiedad.contextType = PropertyContext;
export default Propiedad;
