import React from 'react';
import { Collapse, Row, Col } from 'reactstrap';
import { FaPencilRuler, FaPen } from 'react-icons/fa';

class SeleccionarEditar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      active: false
    };
    this.toggleActive = this.toggleActive.bind(this);
  }

  toggleActive = () => {
    this.setState({ active: !this.state.active });
  };

  toggleCollapse = () => {
   this.props.onChangeCollapse(!this.props.collapse)
  };

  render() {

    return (
      <React.Fragment>
        <Row
          onClick={this.toggleCollapse}
          onMouseEnter={this.toggleActive}
          onMouseLeave={this.toggleActive}
          className="parchico">
          <Col
            xs={4}
            sm={5}
            className="wbolder claseDatos"
            activeClassName="claseDatos2">
            <abbr className={this.props.color + ' teditarmediana'}>
              {this.props.concepto}
            </abbr>
          </Col>
          <Col xs={6} sm={6} md={5} className="gris teditarchica">
            <span className="dato">{this.props.valor}</span>
          </Col>
          {!this.props.falso && (
            <Col md={1} className="d-none d-md-block">
              <span className={this.state.active ? 'underline' : 'normal'}>
                Editar
              </span>
            </Col>
          )}
          {!this.props.falso && (
            <Col md={1} className="d-none d-md-block">
              <span className={this.state.active ? 'underline' : 'normal'} />
              {(this.state.active || this.props.collapse) && <FaPen />}
            </Col>
          )}

          {!this.props.falso && (
            <Col xs={1} className="d-block d-md-none">
              <div>
                {this.props.collapse ? (
                  <FaPencilRuler className="negro tmediana" />
                ) : (
                    <FaPen className="negro tmediana" />
                  )}
              </div>
            </Col>
          )}
        </Row>
        <Row>
          <Col xs={11} className="sinpymde">
            {!this.props.falso && (
              <Collapse
                isOpen={this.props.collapse}
                className={`${this.props.fondo} pmediano`}>
                <div className="pchico">{this.props.children}</div>
              </Collapse>
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default SeleccionarEditar;
