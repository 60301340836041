import React from 'react';
import { Spinner } from 'reactstrap';
const url =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:4000'
    : 'https://graph.client.mx';
const CerrarSesion = props => {
  fetch(`${url}/auth/local/logout`, {
    method: 'post',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  })
    .then(res => res.json())
    .then(result => {
      console.log(result);
      if (result.success) {
        props.history.push('/');
      }
    });

  return (
    <div className="parmuygrande centro">
      <div className="verde wbold pmuychico centro tmuygrande">
        Cerrando sesión
      </div>
      <Spinner size="lg" color="success" />
    </div>
  );
};

export default CerrarSesion;
