// @format
import React, {useState, useContext} from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
} from 'reactstrap';
import './postsignup.sass';
import AuthContext from '../../AuthContext';
import {useMutation} from '@apollo/react-hooks';
import gql from 'graphql-tag';

const updateUserMutation = gql`
  mutation UpdateUser($data: userUpdateInput!) {
    updateUser(data: $data) {
      user {
        firstName
        lastName
        phone
      }
    }
  }
`;

export default props => {
  const context = useContext(AuthContext);
  let userInfo = sessionStorage.getItem('userInfo');
  if (!userInfo) console.error('No user info');
  userInfo = JSON.parse(userInfo);

  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [updateUser, {data, called, loading, error}] = useMutation(
    updateUserMutation,
    {
      context: {token: context.token},
    },
  );

  return (
    <Container>
      <Row>
        <Col xs={{size: 8, offset: 2}} lg={{size: 6, offset: 3}}>
          <Form
            className="formShadow p-2 padding-25-perc"
            onSubmit={e => {
              e.preventDefault();
              if (name && lastName) {
                updateUser({
                  variables: {
                    data: {
                      id: userInfo.id,
                      firstName: name,
                      lastName: lastName,
                      phone: phone ? phone : undefined,
                    },
                  },
                  onCompleted: data => {
                    console.log(data);
                    if (
                      data.updateUser.user.firstName === name &&
                      data.updateUser.user.lastName
                    ) {
                      props.history.push('/inicio/inicio');
                    }
                  },
                  onError: error => {
                    console.log(error);
                  },
                });
              }
            }}>
            <h4 className="text-left">
              Por favor, proporcionanos un poco mas de información
            </h4>
            <FormGroup>
              <Input
                className="my-0"
                id="name"
                value={name}
                onChange={({target}) => setName(target.value)}
                placeholder="Nombre"
                type="text"
              />
            </FormGroup>
            <FormGroup>
              <Input
                className="my-0"
                id="lastname"
                value={lastName}
                onChange={({target}) => setLastName(target.value)}
                placeholder="Apellido"
                type="text"
              />
            </FormGroup>
            <FormGroup>
              <Input
                className="my-0"
                id="phone"
                value={phone}
                onChange={({target}) => setPhone(target.value)}
                placeholder="Telefono"
                type="text"
              />
            </FormGroup>
            <Input type="submit" className="botonVerde" value="Enviar" />
          </Form>
        </Col>
      </Row>
    </Container>
  );
};
