import React, { Component } from 'react';
import AuthContext from '../../../AuthContext';
import { Row, Col, Button, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FaWrench, FaScrewdriver, FaHammer } from 'react-icons/fa';

class Estadisticas extends Component {
  render() {
    return (
      <React.Fragment>
        <Container>
          <div className="testadisticas centro pabchico">
            <FaWrench className="cafe" />
            <FaScrewdriver className="amarillo" />
            <FaHammer className="gris" />
            <div className="verde wbold tconstruccion">En construcción...</div>
          </div>
          <div className="centro parmuygrande pabmediano">
            <Button
              tag={Link}
              to="/inicio"
              className="wbold pizmediano pdemediano tgrande botonGris claseBoton2">
              Regresar
            </Button>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}
Estadisticas.contextType = AuthContext;
export default Estadisticas;
