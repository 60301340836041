import React, { Component } from 'react';
import { Button, Row, Col } from 'reactstrap';
import FinalizarOperacion from './FinalizarOperacion';
import { FaWhatsapp, FaPhone } from 'react-icons/fa';

class ContactoLead extends Component {
  render() {
    return (
      <React.Fragment>
        <React.Fragment>
          <Row>
            <Col
              xs={8}
              className="mayus wbold azul letraLeadDatos izquierda sinpymde">
              Datos del cliente
            </Col>
            <Col className="derecha pdegrande">
              <FinalizarOperacion />
            </Col>
          </Row>
          <Row className="letraLeadPresupuesto gris">
            <Col xs={3} className="wbold izquierda negro">
              Nombre:
            </Col>{' '}
            <Col xs={9} className="izquierda">
              {this.props.lead.contact.name}
            </Col>
            <Col xs={3} className="wbold izquierda negro">
              Telefono:
            </Col>{' '}
            <Col xs={9} className="izquierda">
              {this.props.lead.contact.cellphone}
            </Col>
          </Row>
          <Row>
            <Col className="d-block d-md-none">
              <div className="wbold tgrande">
                <Button
                  href={`tel:${this.props.lead.contact.cellphone}`}
                  className="botonTransparente claseBotonFooter">
                  <FaPhone className="paddingmini tmediana azul" />
                </Button>
                <Button
                  href={`https://wa.me/52${
                    this.props.lead.contact.cellphone
                  }?text=Hola%20${
                    this.props.lead.contact.name
                  },%20tengo%20una%20propiedad%20que%20cuenta%20con%20tus%20requisitos.`}
                  className="botonTransparente claseBotonFooter">
                  <FaWhatsapp className="verde tgrande" />
                </Button>
              </div>
            </Col>
          </Row>
          <div className="lmuychica fondoAzul" />
        </React.Fragment>
      </React.Fragment>
    );
  }
}

export default ContactoLead;
