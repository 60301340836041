import React, { useState, useContext } from 'react';
import AuthContext from '../../../../../AuthContext';
import { Mutation } from '@apollo/react-components';
import gql from 'graphql-tag';
import {
  Button,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from 'reactstrap';

const updateLeadMutation = gql`
  mutation UpdateLead($data: leadUpdateInput!) {
    updateLead(data: $data) {
      lead {
        id
        useful
        deleted
      }
    }
  }
`;

export default props => {
  const [toggle, setToggle] = useState(false);
  const context = useContext(AuthContext);
  return (
    <Mutation mutation={updateLeadMutation} context={{ token: context.token }}>
      {(updateLead, { loading, error, data, called }) => {
        return (
          <React.Fragment>
            <div
              onClick={() => setToggle(!toggle)}
              className="linkAzul2 subrayado d-inline tchica centro">
              Finalizar operación{' '}
            </div>
            <Modal isOpen={toggle} toggle={() => setToggle(!toggle)}>
              <ModalHeader>
                <div className="wbold verde">
                  ¿Cerraste la operación con el cliente?
                </div>
              </ModalHeader>
              <ModalBody className="fondoPantalla">
                <Row className="parchico">
                  <Col className="centro">
                    <Button
                      className="botonVerde botonFinalizar"
                      onClick={() => {
                        updateLead({
                          variables: {
                            useful: true,
                            deleted: true,
                          },
                        });
                      }}>
                      Si
                    </Button>
                  </Col>
                  <Col className="centro">
                    <Button
                      className="botonRojo botonFinalizar"
                      onClick={() => {
                        updateLead({
                          variables: {
                            useful: false,
                            deleted: true,
                          },
                        });
                      }}>
                      No
                    </Button>
                  </Col>
                </Row>
                <div className="parchico tchica centro">
                  *Esta información nos sirve para mejorar nuestro sistema y
                  darte mejores resultados. Gracias.
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  className="botonNegro2"
                  onClick={() => setToggle(!toggle)}>
                  Cancelar
                </Button>
              </ModalFooter>
            </Modal>
          </React.Fragment>
        );
      }}
    </Mutation>
  );
};
