import React, { Component } from 'react';
import { Button } from 'reactstrap';

class BotonWhatsapp extends Component {
  sp = {
    HOUSE: 'Casa',
    APPARTMENT: 'Departamento',
    LAND: 'Terreno',
    OFFICE: 'Oficina',
    SHOP: 'Local',
    MEDICAL: 'Consultorio',
    STORAGE: 'Bodega',
    FACILITY: 'Nave industrial',
    BUILDING: 'Edificio',
    RANCH: 'Finca',
  };

  render() {
    return (
      <React.Fragment>
        <Button
          href={`https://wa.me/52${
            this.props.property.advertiser.cellphone
          }?text=Hola ${this.props.property.advertiser.firstName} {
            this.props.property.advertiser.lastName
          }, te contacto sobre ${
            this.props.property.propertyType === 'APPARTMENT'
              ? 'el *departamento*'
              : ''
          }${this.props.property.propertyType === 'HOUSE' ? 'la *casa*' : ''}${
            this.props.property.propertyType === 'OFFICE' ? 'la *oficina*' : ''
          }${
            this.props.property.propertyType === 'MEDICAL'
              ? 'el *consultorio*'
              : ''
          }${
            this.props.property.propertyType === 'LAND' ? 'el *terreno*' : ''
          }${this.props.property.propertyType === 'SHOP' ? 'el *local*' : ''}${
            this.props.property.propertyType === 'STORAGE' ? 'la *bodega*' : ''
          }${
            this.props.property.propertyType === 'BUILDING'
              ? 'el *edificio*'
              : ''
          }${this.props.property.propertyType === 'RANCH' ? 'la *finca*' : ''}${
            this.props.property.propertyType === 'FACILITY'
              ? 'la *nave industrial*'
              : ''
          } número: *${this.props.property.id}* que ${
            this.props.property.operationType === 'BUY' ? '*vendes*' : ''
          }${
            this.props.property.operationType === 'RENT' ? '*rentas*' : ''
          } en *CLIENT*, ubicado en ${this.props.property.zone} por *$${
            this.props.property.price
          }*. Atentamente, ${this.props.auth.user.firstName} ${
            this.props.auth.user.lastName
          } ${
            this.props.property.agency
              ? `de ${this.props.property.agency.name}`
              : ''
          }.`}
          className={`botonGris pabmicro ${this.props.chico &&
            'botonContactarTelefono'}`}>
          <div className="d-inline icon-whatsapp-2" />
        </Button>
      </React.Fragment>
    );
  }
}

export default BotonWhatsapp;
