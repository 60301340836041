import React, { PureComponent } from 'react';
import { Row, Col, Card, Button } from 'reactstrap';

import Frame from '../frame/Frame';
import { FaWhatsapp, FaPhone } from 'react-icons/fa';

class Seccion5 extends PureComponent {
	render() {
		return (
			<Frame
				id={this.props.id}
				clase="botonBlanco3"
				className="hPage section fondo5">
				{/*TODO ocultar esta vista de desarrollo*/}
				{/*<Desarrollo />*/}
				<Col md={1} className="d-none d-md-block" />
				<section>
					<Row>
						<Col xs={12} id="cardInicio">
							<Card className="pabmuygrande transparente2 alturaCardInicio">
								<span className="pabchico textoInicio wbold negro">
									Contáctanos:
								</span>
								<div className="wbold tgrande">contacto@client.mx</div>
								<Row>
									<Col className="d-block d-md-none">
										<div className="wbold tgrande">
											<Button
												href="tel:2227067092"
												className="botonTransparente claseBotonFooter">
												<FaPhone className="paddingmini tgrande amarillo" />
											</Button>
											<Button
												href="https://wa.me/522227067092?text=Hola,%20me%20podrían%20dar%20más%20información%20sobre%20CLIENT."
												className="botonTransparente claseBotonFooter">
												<FaWhatsapp className="verde tmuygrande" />
											</Button>
										</div>
									</Col>
									<Col className="d-none d-md-block">
										<div className="wbold tgrande">2227067092</div>
									</Col>
								</Row>
								<div className="parchico">
									<Button
										href="/iniciar-sesion#registrate"
										className="botonRojo botonInicio parchico pizgrande pdegrande">
										¡Registrate aquí!
									</Button>
								</div>
							</Card>
						</Col>
					</Row>
				</section>
				<Col md={1} className="d-none d-md-block" />
			</Frame>
		);
	}
}

export default Seccion5;
