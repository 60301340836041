//@format
import React, { Component } from 'react';
import { Row, Col, Button, Input } from 'reactstrap';
import ReactFullpage from '@fullpage/react-fullpage';
import swal from '@sweetalert/with-react';
import * as yup from 'yup';
import { setLocale } from 'yup';
import LogoChicoVerde from '../imgs/logoChicoVerde.png'

const schema = yup.object().shape({
  email: yup.string().email(),
  password: yup.string().required(),
});

const registro = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  phone: yup.string().required(),
  email: yup
    .string()
    .email()
    .required(),
  password: yup.string().required(),
});

const authUrl =
  process.env.NODE_ENV === 'development'
    ? `${process.env.HTTPS ? 'https' : 'http'}://localhost:4000`
    : 'https://graph.client.mx';

class IniciarSesion extends Component {
  componentDidMount() {
    window.scrollTo(0, 1);
  }

  state = {
    firstName: '',
    lastName: '',
    phone: 0,
    email: '',
    password: '',
    passwordNew: '',
    _id: '',
  };

  handleChange({ target }) {
    let properValue;
    properValue = target.value;
    this.setState({ [target.name]: properValue });
  }

  _login() {
    schema
    .validate(this.state)
    .then(valid => {
      fetch(`${authUrl}/auth/local/authenticate`, {
        method: 'post',
        credentials: 'include',
        body: JSON.stringify({
          email: this.state.email,
          password: this.state.password,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.success) {
            this.props.history.push('/mis-propiedades');
          } else {
            if (result.reason) {
              swal(
                'ERROR',
                'El usuario no existe',
                'error',
              );
            } else {
              swal(
                'ERROR',
                'Contraseña equivocada',
                'error',
              );
            }
          }
        })
        .catch(console.error);
    })
    .catch(err => {
      swal('Error', 'Email invalido', 'error');
      console.error(err);
      this.setState({ invalid: true });
    });
  }

  render() {
    const fblogin = response => {
      if (response.authResponse) {
        console.log(response.authResponse);
        fetch(`${authUrl}/auth/facebook`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${response.authResponse.accessToken}`,
          },
          credentials: 'include',
        })
          .then(response => {
            console.log(response);
            return response.text();
          })
          .then(() => {
            this.props.history.push('/inicio');
          });
      }
    };
    window.fblogin = fblogin;
    return (
      <div className="fondoIniciar contenedor">
        <div className="centrado2">
          <div className="logoPosicion">
            <img
              className="centro logoRegistrate"
              src={
                LogoChicoVerde
              }
              alt="logotipo de client"
            />
          </div>
        </div>
        <ReactFullpage
          licenseKey="23D069B2-6B424173-ADAD6D62-6CB56498"
          controlArrows={false}
          anchors={[
            'iniciar-sesion',
            'registrate',
            'recuperar-contraseña',
            'reset-contraseña',
          ]}
          keyboardScrolling={true}
          render={({ state, fullpageApi }) => {
            return (
              <ReactFullpage.Wrapper>
                {/*secion iniciar sesion*/}
                <div className="section">
                  <div className="slide" data-anchor="iniciar-sesion">
                    <div className="h100">
                      <div className="tmuygrande mayus verde wbold centro">
                        Iniciar sesión
                      </div>
                      <div className="centro parchico">
                        <div
                          class="fb-login-button"
                          data-width=""
                          data-scope="public_profile,email"
                          data-size="medium"
                          data-button-type="login_with"
                          data-auto-logout-link="false"
                          data-use-continue-as="false"
                          data-onlogin="fblogin"
                        />
                      </div>
                      <div className="gris wbold parmediano">
                        Iniciar sesión con email:
                      </div>
                      <div className="contenedor" style={{ height: '38px' }}>
                        <div className="centrado">
                          <Input
                            name="email"
                            className="inputIniciar"
                            type="email"
                            value={this.state.email}
                            onChange={this.handleChange.bind(this)}
                            placeholder="Correo electrónico"
                          />
                        </div>
                      </div>
                      <div className="contenedor" style={{ height: '38px' }}>
                        <div className="centrado">
                          <Input
                            name="password"
                            className="inputIniciar"
                            type="password"
                            onChange={this.handleChange.bind(this)}
                            placeholder="Contraseña"
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                  this._login();
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="centro parchico">
                        <Button
                          onClick={() => {
                            this._login();
                          }}
                          className="botonVerde claseBoton3 pladoschico parmuychico">
                          Iniciar sesión
                        </Button>
                      </div>
                      <div className="parchico">
                        <Button
                          href="#iniciar-sesion/recuperar-contraseña"
                          className="botonTransparente">
                          <div className="azul">¿Olvidaste tu contraseña?</div>
                        </Button>
                      </div>
                      <div className="parmediano">
                        <Button
                          href="#registrate"
                          className="botonTransparente">
                          <div>¿Aun no eres usuario?</div>
                          <div className="rojo">Registrate aquí</div>
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="slide" data-anchor="recuperar-contraseña">
                    <div className="h100">
                      <div className="tmuygrande mayus verde wbold centro">
                        Recuperar Contraseña
                      </div>
                      <div className="gris wbold parmediano">
                        Si ha olvidado su contraseña, puede restablecerla aquí.
                      </div>
                      <div className="contenedor" style={{ height: '38px' }}>
                        <div className="centrado">
                          <Input
                            name="email"
                            className="inputIniciar"
                            type="email"
                            onChange={this.handleChange.bind(this)}
                            placeholder="Correo electrónico"
                          />
                        </div>
                      </div>
                      <div className="centro parchico">
                        <Button
                          href="#iniciar-sesion"
                          className="claseBoton3 pladoschico parmuychico">
                          Cancelar
                        </Button>
                        <Button
                          className="ml-2 botonVerde claseBoton3 pladoschico parmuychico"
                          onClick={() => {
                            fetch(`${authUrl}/auth/local/resetPassword`, {
                              method: 'post',
                              credentials: 'include',
                              body: JSON.stringify({
                                email: this.state.email,
                              }),
                              headers: {
                                'Content-Type': 'application/json',
                              },
                            })
                              .then(res => res.json())
                              .then(result => {
                                console.log(result);
                                if (result.success) {
                                  swal(
                                    'Código enviado',
                                    'Se ha enviado un código de verificación a su correo',
                                    'success',
                                  );
                                  window.location.href =
                                    '#iniciar-sesion/reset-contraseña';
                                } else {
                                  if (result.reason) {
                                    swal(
                                      'ERROR',
                                      'El usuario no existe',
                                      'error',
                                    );
                                  }
                                }
                              })
                              .catch(console.error);
                          }}>
                          Enviar
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="slide" data-anchor="reset-contraseña">
                    <div className="h100">
                      <div className="tmuygrande mayus verde wbold centro">
                        Recuperar Contraseña
                      </div>
                      <div className="gris wbold parmediano">
                        Ingresar código de validación
                      </div>
                      <div className="contenedor" style={{ height: '38px' }}>
                        <div className="centrado">
                          <Input
                            name="_id"
                            className="inputIniciar"
                            type="_id"
                            onChange={this.handleChange.bind(this)}
                            placeholder="Código de validación"
                          />
                        </div>
                      </div>
                      <div className="gris wbold parmediano">
                        Ingresar nueva contraseña
                      </div>
                      <div className="contenedor" style={{ height: '38px' }}>
                        <div className="centrado">
                          <Input
                            name="passwordNew"
                            className="inputIniciar"
                            type="password"
                            onChange={this.handleChange.bind(this)}
                            placeholder="Nueva contraseña"
                          />
                        </div>
                      </div>
                      <div className="centro parchico">
                        <Button
                          href="#iniciar-sesion/recuperar-contraseña"
                          className="claseBoton3 pladoschico parmuychico">
                          Cancelar
                        </Button>
                        <Button
                          className="ml-2 botonVerde claseBoton3 pladoschico parmuychico"
                          onClick={() => {
                            fetch(`${authUrl}/auth/local/validationReset`, {
                              method: 'post',
                              credentials: 'include',
                              body: JSON.stringify({
                                password: this.state.passwordNew,
                                _id: this.state._id,
                              }),
                              headers: {
                                'Content-Type': 'application/json',
                              },
                            })
                              .then(res => res.json())
                              .then(result => {
                                console.log(result);
                                if (result.success) {
                                  swal(
                                    '¡Éxito!',
                                    'La contraseña ha sido cambiada',
                                    'success',
                                  );
                                  window.location.href = '/iniciar-sesion';
                                } else {
                                  if (result.reason) {
                                    swal(
                                      'ERROR',
                                      'Código de validación incorrecto',
                                      'error',
                                    );
                                  }
                                }
                              })
                              .catch(console.error);
                          }}>
                          Enviar
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                {/*secion registro*/}

                <div className="section">
                  <div className="h100">
                    <div className="tmuygrande mayus verde wbold centro">
                      Regístrate
                    </div>
                    <div className="centro parchico">
                      <div
                        class="fb-login-button"
                        data-width=""
                        data-size="medium"
                        data-button-type="login_with"
                        data-auto-logout-link="false"
                        data-use-continue-as="false"
                        data-onlogin="fblogin"
                      />
                    </div>
                    <div className="gris wbold parmediano">
                      o completa la información:
                    </div>
                    <div className="contenedor" style={{ height: '38px' }}>
                      <div className="centrado">
                        <Input
                          name="firstName"
                          className="inputIniciar"
                          type="text"
                          placeholder="Nombre"
                          onChange={this.handleChange.bind(this)}
                        />
                      </div>
                    </div>
                    <div className="contenedor" style={{ height: '38px' }}>
                      <div className="centrado">
                        <Input
                          name="lastName"
                          className="inputIniciar"
                          type="text"
                          placeholder="Apellido"
                          onChange={this.handleChange.bind(this)}
                        />
                      </div>
                    </div>
                    <div className="contenedor" style={{ height: '38px' }}>
                      <div className="centrado">
                        <Input
                          name="phone"
                          className="inputIniciar"
                          type="text"
                          placeholder="Teléfono"
                          maxLength="10"
                          onChange={this.handleChange.bind(this)}
                        />
                      </div>
                    </div>
                    <div className="contenedor" style={{ height: '38px' }}>
                      <div className="centrado">
                        <Input
                          name="email"
                          className="inputIniciar"
                          type="email"
                          placeholder="Correo electrónico"
                          onChange={this.handleChange.bind(this)}
                        />
                      </div>
                    </div>
                    <div className="contenedor" style={{ height: '38px' }}>
                      <div className="centrado">
                        <Input
                          name="password"
                          className="inputIniciar"
                          type="password"
                          placeholder="Contraseña"
                          onChange={this.handleChange.bind(this)}
                        />
                      </div>
                    </div>
                    <Row>
                      <Col
                        xs={{ size: 10, offset: 1 }}
                        sm={{ size: 6, offset: 3 }}
                        className="centro gris tchica
                        parchico">
                        *Al registrarte aceptas nuestros{' '}
                        <span className="wbold negro">
                          términos y condiciones
                        </span>
                        .
                        <div className="parmuychico">
                          <Button
                            className="botonVerde claseBoton3 pladoschico parmuychico"
                            onClick={() => {
                              registro
                                .validate(this.state)
                                .then(valid => {
                                  fetch(`${authUrl}/auth/local/signup`, {
                                    method: 'post',
                                    credentials: 'include',
                                    body: JSON.stringify({
                                      email: this.state.email,
                                      password: this.state.password,
                                      firstName: this.state.firstName,
                                      lastName: this.state.lastName,
                                      phone: this.state.phone,
                                    }),
                                    headers: {
                                      'Content-Type': 'application/json',
                                    },
                                  })
                                    .then(res => res.json())
                                    .then(result => {
                                      console.log(result);
                                      if (result.success) {
                                        swal(
                                          'El usuario fue registrado',
                                          'Se te envió un correo de confirmación.',
                                          'success',
                                        ).then(() => {
                                          window.location.hash =
                                            'iniciar-sesion';
                                        });
                                      }
                                    })
                                    .catch(error => {
                                      console.log(error);
                                      if (error) {
                                        swal(
                                          'ERROR',
                                          'El correo electrónico ya existe.',
                                          'error',
                                        );
                                      }
                                    });
                                })
                                .catch(error => {
                                  console.log(error);
                                  swal(
                                    'Error',
                                    error.errors.join(','),
                                    'error',
                                  );
                                });
                            }}>
                            Concluir registro
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <div className="parmediano">
                      <Button
                        href="#iniciar-sesion"
                        className="botonTransparente">
                        <div>¿Ya eres usuario?</div>
                        <div className="rojo">Inicia sesión aquí</div>
                      </Button>
                    </div>
                  </div>
                </div>
              </ReactFullpage.Wrapper>
            );
          }}
        />
      </div>
    );
  }
}

export default IniciarSesion;
