import React, { useEffect, useState, useContext } from 'react';
import Heart from '../../../imgs/lotties/heart-with-particles.json';
import { FaHeart } from 'react-icons/fa';
import gql from 'graphql-tag';
import { Mutation } from '@apollo/react-components';
import HeartGif from '../../../imgs/heart.gif';

const addToFavorites = gql`
  mutation AddToFavorites($filter: propertyUniqueWhereInput!) {
    addToFavorites(filter: $filter) {
      id
    }
  }
`;

const removeFromFavorites = gql`
  mutation RemoveFromFavorites($filter: propertyUniqueWhereInput!) {
    removeFromFavorites(filter: $filter) {
      id
    }
  }
`;

const MyFavorita = ({ property }) => {
  const [state, setState] = useState('normal');

  useEffect(() => {
    if (property.isFavorite) {
      setState('favorite');
    }
  }, []);

  return (
    <div className='heart-in-right'>
      {state === 'normal' && (
        <Mutation
          mutation={addToFavorites}
          onCompleted={() => {
            setState('lottie');
            setTimeout(() => {
              setState('favorite');
            }, 1000);
          }}>
          {(addFavorite, { loading, called, data }) => (
            <div
              onClick={() => {
                addFavorite({
                  variables: {
                    filter: {
                      id: property.id,
                    },
                  },
                });
              }}>
              <FaHeart />
            </div>
          )}
        </Mutation>
      )}
      {state === 'lottie' && (
        <img src={HeartGif} style={{height: '35px', width: '35px'}} />
      )}
      {state === 'favorite' && (
        <Mutation
          mutation={removeFromFavorites}
          onCompleted={() => {
            setState('normal');
          }}>
          {(removeFavorite, { loading, called, data }) => (
            <div
              onClick={() => {
                removeFavorite({
                  variables: {
                    filter: {
                      id: property.id,
                    },
                  },
                });
              }}>
              <FaHeart color="red" />
            </div>
          )}
        </Mutation>
      )}
    </div>
  );
};

export default MyFavorita;
