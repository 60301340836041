// @format
import React, { useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import gql from 'graphql-tag';
import ContenidoPropiedad from './ContenidoPropiedad';
import * as yup from 'yup';
import PropertyContext from '../../../PropertyContext';

const propertyValidator = yup
  .object()
  .required()
  .shape({
    id: yup.number().required(),
    bathrooms: yup.number().when('propertyType', {
      is: val => val !== 'LAND',
      then: yup.number().nullable(),
      otherwise: yup.number(),
    }),
    bedrooms: yup.number().when('propertyType', {
      is: val => val === 'HOUSE' || val === 'APPARTMENT',
      then: yup.number(),
      otherwise: yup.number().nullable(),
    }),
    commission: yup.number(),
    constructionMeters: yup.number().nullable(),
    origin: yup.object().shape({
      city: yup.string(),
      country: yup.string(),
      state: yup.string(),
    }),
    address: yup.string().min(1),
    placeId: yup.string().min(1),
    description: yup.string(),
    operationType: yup.string(),
    propertyType: yup.string(),
    pictures: yup.array(),
    price: yup.number(),
    squareMeters: yup.number(),
    zone: yup.string(),
    deletedPictures: yup.array().of(
      yup.object().shape({
        id: yup.number().required(),
        url: yup.string().required(),
      }),
    ),
  });

const updatePropertyMutation = gql`
  mutation($data: propertyUpdateInput!) {
    updateProperty(data: $data) {
      property {
        id
      }
    }
  }
`;

export default function(props) {
  const { id } = useParams();
  const { property } = useContext(PropertyContext);
  if (!property || (property && property.id !== id)) {
    let history = useHistory();
    history.push('/mis-propiedades');
  }
  console.log('Property', property);
  return (
    <ContenidoPropiedad
      property={property}
      propertyValidator={propertyValidator}
      mutation={updatePropertyMutation}
      tipo="Editar propiedad"
    />
  );
}
