// @format
import React, { useContext, useState } from 'react';
import Prospectos from './Clientes/Prospectos/Prospectos';
import Inicio from './Inicio/Inicio';
import Clientes from './Clientes/Clientes/Clientes';
import Propiedades from './Propiedades/Propiedades';
import PropiedadID from './Propiedades/propiedad/PropiedadID';
import MisPropiedades from './Propiedades/MisPropiedades';
import PropiedadesFavoritas from './Propiedades/PropiedadesFavoritas';
import SubirPropiedad from './Propiedades/SubirPropiedad';
import EditarPropiedad from './Propiedades/EditarPropiedad';
import Carrito from './Carrito/Carrito';
import Pago from './Carrito/Pago';
import CerrarSesion from '../objetos/CerrarSesion';
import Estadisticas from './Estadisticas/Estadisticas';
import Perfil from './Perfil/Perfil';
import AuthContext from '../../AuthContext';
import PropertyContext from '../../PropertyContext';
import { TopContainer } from '../contenedor';
import Menu from '../Menu';
import { Alert } from 'reactstrap';
import { Switch, Route, useLocation } from 'react-router-dom';
import HaztePremium from '../objetos/HaztePremium';
import Suscripcion from '../Logeado/Perfil/Suscripcion'
import LogoVerde from '../imgs/logoVerde.png'

var styles = {
  bmMenu: {
    background: '#008742',
  },

  bmMorphShape: {
    fill: '#008742',
  },
  bmBurgerButton: {
    zIndex: 1040,
  },
};

export const rutas = [
  /* {
    name: 'Inicio',
    path: `/inicio`,
    component: Inicio,
    exact: true,
  }, */
  // {
  //   name: 'Clientes',
  //   path: `/clientes`,
  //   component: Prospectos,
  //   exact: true,
  // },
  // {
  //   name: 'Mis clientes',
  //   path: `/mis-clientes`,
  //   component: Clientes,
  //   exact: true,
  // },
  /* {
    name: 'Propiedades',
    path: `/propiedades`,
    component: Propiedades,
    exact: true,
  }, */
  {
    name: 'Mis propiedades',
    path: `/mis-propiedades`,
    component: MisPropiedades,
    exact: true,
  },
  /* {
    name: 'Propiedades Favoritas',
    path: `/propiedades-favoritas`,
    component: PropiedadesFavoritas,
    exact: true,
  }, */
  // {
  //   name: 'Carrito',
  //   path: `/carrito`,
  //   component: Carrito,
  //   exact: true,
  // },
  {
    name: 'Perfil',
    path: `/perfil`,
    component: Perfil,
    exact: true,
  },
  // {
  //   name: 'Suscripcion',
  //   path: `/suscripcion`,
  //   component: Suscripcion,
  //   exact: true,
  // },
  {
    componente: <div className="lmuychica fondoBlanco" />,
  },
  {
    name: 'Cerrar Sesión',
    path: `/logout`,
    component: CerrarSesion,
    exact: true,
  },
];

export default props => {
  const { user } = useContext(AuthContext);

  const [property, setProperty] = useState(null);

  let location = useLocation();

  return (
    <PropertyContext.Provider value={{ property, setProperty }}>
      <Menu
        logotipo={
          LogoVerde
        }
        rutas={rutas}
        color="fondoBlanco"
        styles={styles}
      />{' '}
      {/* { location.pathname !== '/suscripcion' && <HaztePremium /> } */}
      <TopContainer>
        {/* <Alert color="info" className="mt-2" isOpen={user && !user.verified}>
          Por favor verifique su email
        </Alert>*/}
        <Switch>
          {rutas
            .filter(ruta => !ruta.componente)
            .map(ruta => (
              <Route
                key={ruta.name}
                path={ruta.path}
                component={ruta.component}
                exact={ruta.exact}
              />
            ))}
          <Route
            key="Subir propiedad"
            path={`${props.match.url}subir-propiedad`}
            component={SubirPropiedad}
            exact
          />
          <Route
            key="PropiedadID"
            path={`${props.match.url}propiedad/:id`}
            component={PropiedadID}
            exact
          />
          <Route
            key="Editar propiedad"
            path={`${props.match.url}mis-propiedades/:id`}
            component={EditarPropiedad}
            exact
          />
          <Route
            key="Estadisticas"
            path={`${props.match.url}estadisticas`}
            component={Estadisticas}
            exact
          />
          <Route
            key="Servicios"
            path={`${props.match.url}servicios`}
            component={Estadisticas}
            exact
          />
        </Switch>
      </TopContainer>
    </PropertyContext.Provider>
  );
};
