import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import AutorizarPropiedad from "./propiedad/AutorizarPropiedad";
import PropiedadAdmin from "./propiedad/PropiedadAdmin";
import FiltrarPropiedades from "../../Logeado/Propiedades/FiltrarPropiedades";
import { FaPlus } from "react-icons/fa";

function numberWithCommas(x) {
	x = Math.round(x).toString();
	var pattern = /(-?\d+)(\d{3})/;
	while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
	return x;
}

class Publicaciones extends Component {
	state = {
		open: false,
		leads: [],
		show: 0,
		busqueda: "",
		country: "",
		state: "",
		city: ""
	};

	user = {
		id: "2342",
		currency: { name: "MXN", factor: 1 }
	};

	data = {
		properties: [
			{
				id: "2342",
				active: false,
				propertyType: "Departamento",
				operationType: "Venta",
				landMeters: "250",
				constructionMeters: "250",
				zone: "Lomas de Angelópolis",
				bedrooms: "3",
				bathrooms: "3.5",
				commission: "2",
				pictures: [
					{
						url:
							"https://propiedadescom.s3.amazonaws.com/files/292x200/e78ca74a24a87a6b8fb4a0157766c594.jpg"
					}
				],
				description:
					"Departamento en fffffasfdasfasdfammmmmmmf ffffffffffffffffffff fffffffffffffffffffffffffffffffffff bonita, con jardin, area comun, alberca, billar, nueva, cerca de las universidades, garage para dos coches",
				price: "2780000",
				dueDate: "11/11/11",
				contact: {
					name: "Juan Alberto",
					lastName: "Hernandez Casas",
					cel: "2227937492",
					email: "juanhcasas@gmail.com"
				},
				origin: { country: "México", state: "Puebla", city: "Puebla" }
			},
			{
				id: "234239",
				active: false,
				propertyType: "Departamento",
				operationType: "Renta",
				landMeters: "100",
				constructionMeters: "178",
				zone: "La Noria",
				bedrooms: "2",
				bathrooms: "3",
				commission: ".5",
				pictures: [
					{
						url:
							"https://e41ef2ad9891d7dd1442-94bb8684ffa0a53fb3624a3fb44b51a2.ssl.cf1.rackcdn.com/cliente/11217/fotosproductos/casa-sola-residencial-en-renta-en-fraccionamiento-lomas-de-angelopolis-san-andres-cholula-55718.jpg"
					}
				],
				description:
					"Departamento en zona bonita, con jardin, area comun, alberca, billar, nueva, cerca de las universidades, garage para dos coches",
				price: "14000",
				dueDate: "11/11/11",
				contact: {
					name: "Juan Alberto",
					lastName: "Hernandez Casas",
					cel: "2227937492",
					email: "juanhcasas@gmail.com"
				},
				origin: { country: "México", state: "Puebla", city: "Puebla" }
			},
			{
				id: "2342",
				active: false,
				propertyType: "Departamento",
				operationType: "Venta",
				landMeters: "250",
				constructionMeters: "250",
				zone: "Lomas de Angelópolis",
				bedrooms: "3",
				bathrooms: "3.5",
				commission: "2",
				pictures: [
					{
						url:
							"http://fotos.crminmobiliario.com/cliente/11217/fotosproductos/casa-sola-residencial-en-venta-en-colonia-el-mirador-puebla-65201.jpg"
					}
				],
				description:
					"Departamento en zona bonita, con jardin, area comun, alberca, billar, nueva, cerca de las universidades, garage para dos coches",
				price: "2780000",
				dueDate: "11/11/11",
				contact: {
					name: "Juan Alberto",
					lastName: "Hernandez Casas",
					cel: "2227937492",
					email: "juanhcasas@gmail.com"
				},
				origin: { country: "México", state: "Puebla", city: "Puebla" }
			},
			{
				id: "234239",
				active: false,
				propertyType: "Departamento",
				operationType: "Renta",
				landMeters: "100",
				constructionMeters: "178",
				zone: "La Noria",
				bedrooms: "2",
				bathrooms: "3",
				commission: ".5",
				pictures: [
					{
						url:
							"http://s3.amazonaws.com/assets.moveglobally.com/property_images/629712/9657219/EB-BJ9712.jpg?1516150166"
					}
				],
				description:
					"Departamento en zona bonita, con jardin, area comun, alberca, billar, nueva, cerca de las universidades, garage para dos coches",
				price: "14000",
				dueDate: "11/11/11",
				contact: {
					name: "Juan Alberto",
					lastName: "Hernandez Casas",
					cel: "2227937492",
					email: "juanhcasas@gmail.com"
				},
				origin: { country: "México", state: "Puebla", city: "Puebla" }
			},
			{
				id: "234239",
				active: false,
				propertyType: "Departamento",
				operationType: "Venta",
				landMeters: "250",
				constructionMeters: "250",
				zone: "Lomas de Angelópolis",
				bedrooms: "3",
				bathrooms: "3.5",
				commission: "2",
				pictures: [
					{
						url:
							"http://fotos.crminmobiliario.com/cliente/11217/fotosproductos/casa-sola-residencial-en-venta-en-colonia-el-mirador-puebla-65201.jpg"
					}
				],
				description:
					"Departamento en zona bonita, con jardin, area comun, alberca, billar, nueva, cerca de las universidades, garage para dos coches",
				price: "2780000",
				dueDate: "11/11/11",
				contact: {
					name: "Juan Alberto",
					lastName: "Hernandez Casas",
					cel: "2227937492",
					email: "juanhcasas@gmail.com"
				},
				origin: { country: "México", state: "Puebla", city: "Puebla" }
			}
		]
	};

	render() {
		return (
			<React.Fragment>
				{/*Vista corredor muestra*/}
				<div className="centro parmuygrande">
					<div className="pabmuygrande">
						<div className="wbold tenorme">
							PUBLICACIONES POR AUTORIZAR
						</div>
						<React.Fragment>
							{this.data.properties[0].active === false && (
								<div className="contenedor alturaPropiedadAutorizar">
									<AutorizarPropiedad
										property={this.data.properties[0]}
										currency={this.user.currency}
										cambio
									/>
								</div>
							)}
						</React.Fragment>
					</div>
					<div className="pabmuychico">
						<Row>
							<Col
								xs={{ size: 12 }}
								md={{ size: 10, offset: 1 }}
								lg={{ size: 8, offset: 2 }}
							>
								<div className="parmuychico pabmediano">
									<a href="/subir-propiedad">
										<div className="botonAzul wbold pchico">
											<Row>
												<Col xs={2} />
												<Col
													xs={7}
													className="sinpymlados"
												>
													Publica tus propiedades
												</Col>

												<Col xs={3} className="centro">
													<div className="pizMas">
														<FaPlus className="tchica" />
													</div>
												</Col>
											</Row>
										</div>
									</a>
								</div>
								<FiltrarPropiedades />
							</Col>
						</Row>
						<div className="paddingPropiedades">
							<Row className="parmediano">
								{this.data.properties.map(property => (
									<React.Fragment>
										{property.active === false && (
											<Col
												xs={12}
												sm={6}
												md={6}
												lg={4}
												xl={3}
												className="contenedor alturaPropiedad"
											>
												<PropiedadAdmin
													property={property}
													currency={
														this.user.currency
													}
												/>
											</Col>
										)}
									</React.Fragment>
								))}
							</Row>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default Publicaciones;
