import React, { useContext, useState } from 'react';
import AuthContext from '../../../../AuthContext';
import { Row, Col, Button } from 'reactstrap';
import Lead from './lead/Lead';
import LeadChico from './lead/LeadChico';
import FiltrosClientes from '../FiltrosClientes';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import { Query } from '@apollo/react-components';
import { FaShoppingCart } from 'react-icons/fa';
import Dummy from './Dummy';

const user = {
  id: '23489',
  balance: '5000',
  currency: { name: 'MXN', factor: 1 },
  budgets: [
    {
      origin: {
        country: 'México',
        state: 'Puebla',
        city: 'Puebla',
      },
      amount: '1900',
    },
    {
      origin: {
        country: 'México',
        state: 'Puebla',
        city: 'Cholula',
      },
      amount: '4000',
    },
  ],
};

const leadFragment = gql`
  fragment LeadFields on Lead {
    id
    accepted
    rejected
    dueDate
    origin {
      state
      city
    }
    zones
    price
    propertyType
    operationType
    furnished
    bedrooms
    bathrooms
    description
    budget
    rentBudget
  }
`;

const leadQuery = gql`
  query GetLeads($filter: leadWhereInput, $skip: Int, $take: Int) {
    getLeads(filter: $filter, skip: $skip, take: $take) {
      leads {
        ...LeadFields
      }
    }
  }
  ${leadFragment}
`;

const newLeadSubscription = gql`
  subscription {
    newLead {
      lead {
        ...LeadFields
      }
      event
    }
  }
  ${leadFragment}
`;

const take = 12;
let skip = 0;

export default props => {
  const authContext = useContext(AuthContext);
  const [tamano, setTamano] = useState(1);
  return (
    <div>
      <Query
        query={leadQuery}
        fetchPolicy="cache-and-network"
        variables={{ filter: { accepted: false }, skip, take }}>
        {({ data, loading, error, fetchMore, subscribeToMore }) => {
          console.log('Data: ', data);
          console.log('Error: ', error);
          if (loading && !data)
            return (
              <div className="centro parmediano">
                <h2 className="verde wbold">Cargando cartera de clientes...</h2>
              </div>
            );
          // TODO redirect to error page
          if (error)
            return (
              <div className="centro parmediano">
                <h2>{error.message}</h2>
              </div>
            );

          const { leads } = data.getLeads;

          return (
            <div className="centro parmediano">
              <Dummy
                exec={() => {
                  subscribeToMore({
                    document: newLeadSubscription,
                    updateQuery: (prev, { subscriptionData }) => {
                      if (!subscriptionData) {
                        return prev;
                      }
                      const { lead, event } = subscriptionData.data.newLead;
                      return Object.assign({}, prev, {
                        getLeads: {
                          ...prev.getLeads,
                          leads:
                            event === 'ADD'
                              ? prev.getLeads.leads.concat([lead])
                              : prev.getLeads.leads.filter(
                                  l => l.id !== lead.id,
                                ),
                        },
                      });
                    },
                  });
                }}
              />
              <div className="pabmuychico">
                <Row>
                  <Col
                    xs={12}
                    sm={{ size: 10, offset: 1 }}
                    md={{ size: 12, offset: 0 }}>
                    <div className="paddingLeads">
                      {/*   Filtros clientes */}
                      <div className="pabgrande">
                        <FiltrosClientes
                          tamano={tamano}
                          chico={() => setTamano(1)}
                          mediano={() => setTamano(2)}
                          grande={() => setTamano(3)}
                        />
                      </div>
                      <Row>
                        {leads.map(lead => (
                          <React.Fragment>
                            {tamano === 1 && (
                              <React.Fragment>
                                <Col
                                  xs={12}
                                  md={6}
                                  xl={4}
                                  className="centradoLead pabmediano">
                                  <LeadChico
                                    lead={lead}
                                    currency={user.currency}
                                  />
                                </Col>
                              </React.Fragment>
                            )}
                            {tamano === 3 && (
                              <React.Fragment>
                                <Col
                                  xs={12}
                                  md={6}
                                  xl={4}
                                  className="alturaLead centradoLead">
                                  <Lead lead={lead} currency={user.currency} />
                                </Col>
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        ))}
                      </Row>
                      <Row className="justify-content-center">
                        <Col>
                          <Button
                            className="wbold claseBoton2 botonGris"
                            onClick={e => {
                              e.preventDefault();
                              if (!loading) {
                                skip += take;
                                fetchMore({
                                  variables: { skip, take },
                                  updateQuery: (prev, { fetchMoreResult }) => {
                                    if (!fetchMoreResult) return prev;
                                    const {
                                      getLeads: { leads: newLeads },
                                    } = fetchMoreResult;
                                    prev.getLeads.leads = prev.getLeads.leads.concat(
                                      newLeads,
                                    );
                                    const retval = Object.assign({}, prev);
                                    console.log(retval);
                                    return retval;
                                  },
                                });
                              }
                            }}>
                            <div className="pizmediano pdemediano">
                              Cargar más prospectos
                            </div>
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>

                <Button tag={Link} to="/carrito" className="volador botonRojo">
                  <div className="botonVolador">
                    <FaShoppingCart className="blanco tmuygrande" />
                  </div>
                </Button>
              </div>
            </div>
          );
        }}
      </Query>
    </div>
  );
};
