import React, { PureComponent } from 'react';
import { Row, Col } from 'reactstrap';
import Frame from '../frame/Frame';
import LogoBlanco from '../../imgs/logoBlanco.png'

class Seccion3 extends PureComponent {
  state = {
    aceptado: false,
  };

  render() {
    return (
      <React.Fragment>
        <Frame
          id={this.props.id}
          className="fondoGrisOpacity hpage section fondo3"
          clase="botonAmarillo">
          <section className="parenorme blanco contenedor">
            <div className="fondoVerde areaOpacityClient centered2 w100">
              <div className="wbold blanco letraClient">
                <div className="parmediano">
                  <img
                    src={
                      LogoBlanco
                    }
                    alt="logo blanco client"
                    className="logoIconos"
                  />
                  <div
                    className={`tareaClient ${
                      this.props.soloPropiedades
                        ? 'centro parchico'
                        : 'izquierda'
                    }`}>
                    Somos una empresa que busca generar equipo en el sector
                    inmobiliario.{' '}
                    {!this.props.soloPropiedades ? 'Otorgamos ' : 'Buscamos '}{' '}
                    clientes para tus bienes inmuebles buscando que generes
                    ventas y rentas en sinergia con inmobiliarios de tu ciudad.{' '}
                    {!this.props.soloPropiedades &&
                      'Los clientes que entregamos solo son asignados a una sola inmobiliaria.'}
                  </div>
                  {!this.props.soloPropiedades && (
                    <Row>
                      <Col className="paddingIconos">
                        <img
                          src={
                            'https://e-client.s3.amazonaws.com/imagenes-client/iconos.svg'
                          }
                          alt="iconos de client"
                          className="tamanoIconos"
                        />
                      </Col>
                    </Row>
                  )}
                </div>
              </div>
            </div>
          </section>
        </Frame>
      </React.Fragment>
    );
  }
}

export default Seccion3;
