// @format
import React, { Component, useContext } from 'react';
import AuthContext from '../../../AuthContext';
import { Row, Col, Button, Spinner } from 'reactstrap';
import Propiedad from './propiedad/Propiedad';
import FiltrarPropiedades from './FiltrarPropiedades';
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';
import { FaPlus, FaSearch } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function numberWithCommas(x) {
  x = Math.round(x).toString();
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x)) x = x.replace(pattern, '$1,$2');
  return x;
}

class PropiedadesFavoritas extends Component {
  constructor(props) {
    super(props);
    this.loadFilter();
    props.history.listen(this.loadFilter);
  }
  loadFilter() {
    try {
      const session = sessionStorage.getItem('filter:propiedades');
      if (session) {
        const sessionData = JSON.parse(session);
        const { orderBy, ...filter } = sessionData;
        this.filter = filter;
        this.orderBy = orderBy;
      }
    } catch (err) {
      console.error(err);
    }
  }
  state = {
    open: false,
    leads: [],
    show: 0,
    skip: 0,
    busqueda: '',
    country: '',
    state: '',
    city: '',
    filter: undefined,
    orderBy: undefined,
  };

  user = {
    id: '23489',
    balance: '0',
    currency: { name: 'MXN', factor: 1 },
    budgets: [
      {
        origin: {
          country: 'México',
          state: 'Puebla',
          city: 'Puebla',
        },
        amount: '1900',
      },
      {
        origin: {
          country: 'México',
          state: 'Puebla',
          city: 'Cholula',
        },
        amount: '4000',
      },
    ],
  };

   propertiesQuery = gql`
    query($filter: propertyWhereInput, $skip: Int, $take: Int) {
      getUser {
        user {
          favorites {
            id
            active
            accepted
            reviewed
            propertyType
            operationType
            squareMeters
            constructionMeters
            zone
            bedrooms
            bathrooms
            furnished
            commission
            pictures {
              id
              order
              url
            }
            description
            price
            dueDate
            advertiserId
            advertiser {
              agency {
                name
              }
              rating
              phone
              lastName
              firstName
            }
            address
            placeId
            origin {
              country
              state
              city
            }
            datasheet
            location {
              latitude
              longitude
            }
            isFavorite
          }
          id
          verified
          posts(filter: $filter, skip: $skip, take: $take) {
            id
          }
        }
      }
    }
  `;

  setRefetch(refetch) {
    if (refetch) {
      this.refetch = refetch;
    }
  }
  take = 24;
  dummy = true;
  refetch = null;

  render() {
    return (
      <React.Fragment>
        {/*Vista corredor muestra*/}
        <div className="centro parmuygrande">
          <div className="pabmuychico">
            <Row className="pabgrande parmuychico">
              <Col
                xs={{ size: 12 }}
                md={{ size: 10, offset: 1 }}
                lg={{ size: 8, offset: 2 }}>
                <div className="parmuychico">
                  <Link to="/subir-propiedad">
                    <div className="centrado">
                      <div className="botonVerde wbold pchico botonVerMasPropiedades">
                        <Row>
                          <Col xs={3} className="derecha">
                            <div className="parmicro2 icon-nueva-propiedad tmuygrande pdemuychico" />
                          </Col>
                          <Col
                            xs={9}
                            className="sinpymlados izquierda parmicro3">
                            Publica tus propiedades
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Link>
                </div>
              </Col>
              <Col>
                <FiltrarPropiedades
                  setFilter={({ filter, orderBy }) => {
                    this.filter = filter;
                    this.orderBy = orderBy;
                    if (this.refetch) {
                      this.refetch({
                        skip: 0,
                        take: this.take,
                        filter,
                        orderBy,
                      });
                    }
                    this.setState({ skip: 0 });
                  }}
                />
              </Col>
            </Row>

            <Query
              query={this.propertiesQuery}
              variables={{
                skip: 0,
                filter: this.filter,
                orderBy: this.orderBy,
              }}
              context={{ token: this.context.token }}
              onCompleted={data => {
                this.setState(state => ({
                  skip: state.skip + this.take,
                }));
              }}
              fetchPolicy="cache-and-network">
              {({ data, loading, error, fetchMore, refetch }) => {
                if (loading && !data)
                  return (
                    <div className="parmuygrande centro tmuygrande">
                      <div className="verde wbold pmuychico centro tmuygrande">
                        Cargando propiedades
                      </div>
                      <Spinner size="lg" color="success" />
                    </div>
                  );
                if (error)
                  return (
                    <p className="centro rojo wbold pchico">{error.message}</p>
                  );
                if (!this.refetch) {
                  this.setRefetch(refetch);
                }

                let properties = data.getUser.user.favorites;
                properties = properties.map(item => {
                    return {
                    ...item,
                    isFavorite: true
                    };
                })

                return (
                  <div className="paddingPropiedades">
                    {loading && <Spinner />}
                    <div className="parmuychico wbold gris tenorme">
                      Propiedades Favoritas
                    </div>
                    <Row className="parmediano">
                      {properties.map(property => (
                        <React.Fragment>
                          {property.active === true && (
                            <Col
                              xs={12}
                              md={6}
                              lg={4}
                              xl={3}
                              className="contenedor alturaPropiedad">
                              <Propiedad
                                auth={useContext(AuthContext)}
                                property={property}
                                currency={this.user.currency}
                              />
                            </Col>
                          )}
                        </React.Fragment>
                      ))}
                    </Row>
                    {properties.length > 1 && properties.length >= this.take && (
                      <div className="centro pabchico">
                        <Button
                          className="botonGris claseBoton2 botonVerMasPropiedades"
                          disabled={loading}
                          onClick={e => {
                            e.preventDefault();
                            if (!loading) {
                              const { skip } = this.state;
                              fetchMore({
                                variables: {
                                  skip,
                                  take: this.take,
                                  filter: this.filter,
                                  orderBy: this.orderBy,
                                },
                                updateQuery: (prev, { fetchMoreResult }) => {
                                  if (!fetchMoreResult) return prev;
                                  const {
                                    user: {posts: newPosts},
                                  } = fetchMoreResult.getUser;
                                    prev.getUser.user.posts = prev.getUser.user.favorites.concat(
                                    newPosts,
                                  );
                                  const retval = Object.assign({}, prev);
                                  console.log(retval);
                                  return retval;
                                },
                              });
                            }
                          }}>
                          {loading && <Spinner />}
                          Ver más
                        </Button>
                      </div>
                    )}
                  </div>
                );
              }}
            </Query>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
PropiedadesFavoritas.contextType = AuthContext;
export default PropiedadesFavoritas;
