import React, { Component } from 'react';
import AuthContext from '../../../../../AuthContext';
import { Row, Col, Button, Input } from 'reactstrap';
import { Mutation } from '@apollo/react-components';
import gql from 'graphql-tag';
import ContactoLead from './ContactoLead';
import ContenidoLead from '../../Prospectos/lead/ContenidoLead';
import Presupuesto from '../../Prospectos/lead/Presupuesto';

function numberWithCommas(x) {
  x = Math.round(x).toString();
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x)) x = x.replace(pattern, '$1,$2');
  return x;
}

class LeadAceptado extends Component {
  state = { normal: false };

  updateLeadMutation = gql`
    mutation UpdateLead($data: leadUpdateInput!) {
      updateLead(data: $data) {
        lead {
          id
          useful
          deleted
        }
      }
    }
  `;

  normal() {
    this.setState({ normal: !this.state.normal });
  }

  render() {
    return (
      <div className="pabmuychico">
        {/*chico*/}
        {!this.state.normal && (
          <React.Fragment>
            <div className="fondoBlanco sombraTodo heightLeadAceptadosChico">
              <div className="pmuychico">
                <Presupuesto
                  clientes
                  chico
                  lead={this.props.lead}
                  currency={this.props.currency}
                />
                <ContenidoLead chico lead={this.props.lead} />
                <div className="centro">
                  <Button
                    onClick={this.normal.bind(this)}
                    className="botonTransparente">
                    <div className="gris wbold claseBoton tContenidoLead">
                      Ver más
                    </div>
                  </Button>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
        {/*nomal*/}
        <React.Fragment>
          {this.state.normal && (
            <div className="fondoBlanco sombraTodo heightLeadAceptadosChico">
              <div className="parchico pizchico pdechico">
                <ContactoLead lead={this.props.lead} />
                <Presupuesto
                  lead={this.props.lead}
                  currency={this.props.currency}
                />
                <div className="negro izquierda pabchico">
                  <ContenidoLead lead={this.props.lead} />
                  <div className="pdechico">
                    <div className="pizmuychico pdemuychico parchico">
                      <div className="azul wbold">SEGUIMIENTO:</div>
                      <Input
                        type="textarea"
                        rows={3}
                        className="fondoAzulClaro azul"
                        placeholder="Seguimiento a cliente..."
                        onChange={({ target }) => {
                          this.setState({
                            followUp: target.value,
                          });
                        }}
                        defaultValue={this.props.lead.followUp}
                      />
                    </div>
                    <Row className="derecha pizmediano pdemuychico parmuychico">
                      <Col className="sinpymlados centro pabmuychico">
                        <Mutation
                          mutation={this.updateLeadMutation}
                          context={{ token: this.context.token }}>
                          {(updateLead, { error, loading, called, data }) => {
                            return (
                              <Button
                                className="botonVerde parmuychico claseBoton3 widthRemoverBoton"
                                disabled={
                                  !this.state.followUp &&
                                  this.state.followUp ===
                                    this.props.lead.followUp &&
                                  loading
                                }
                                onClick={() => {
                                  updateLead({
                                    variables: {
                                      data: {
                                        id: this.props.lead.id,
                                        followUp: this.state.followUp,
                                      },
                                    },
                                  });
                                }}>
                                <div className="pizmicro pdemicro">
                                  Guardar seguimiento
                                </div>
                              </Button>
                            );
                          }}
                        </Mutation>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          )}
        </React.Fragment>
      </div>
    );
  }
}
LeadAceptado.contextType = AuthContext;
export default LeadAceptado;
