import React, { Component } from 'react';
import AuthContext from '../../../AuthContext';
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';
import { Row, Col, Button } from 'reactstrap';
import LeadChico from '../Clientes/Prospectos/lead/LeadChico';
import { FaShoppingCart } from 'react-icons/fa';
import { Link } from 'react-router-dom';

class Carrito extends Component {
  user = {
    id: '23489',
    balance: '5950',
    currency: { name: 'MXN', factor: 1 },
    budgets: [
      {
        origin: { country: 'México', state: 'Puebla', city: 'Puebla' },
        amount: '1950',
      },
      {
        origin: { country: 'México', state: 'Puebla', city: 'Cholula' },
        amount: '4000',
      },
    ],
  };

  leadQuery = gql`
    {
      getUser {
        user {
          id
          cart {
            items {
              id
              accepted
              rejected
              dueDate
              origin {
                state
                city
              }
              zones
              price
              propertyType
              operationType
              furnished
              bedrooms
              bathrooms
              description
              budget
              rentBudget
            }
          }
        }
      }
    }
  `;

  render() {
    return (
      <Query query={this.leadQuery} context={{ token: this.context.token }}>
        {({ data, error, loading }) => {
          if (loading)
            return (
              <div className="centro parmediano">
                <h2 className="verde wbold">
                  Cargando información de tu carrito...
                </h2>
              </div>
            );
          if (error) return <h2>{error.message}</h2>;
          const leads = data.getUser.user.cart.items;
          return (
            <div className="centro parmuygrande">
              <FaShoppingCart className="azul tepica centro pabmediano" />
              <div className="pabmuychico">
                {leads.length !== 0 && (
                  <Button className="botonComprar" tag={Link} to="/pago">
                    <div className="pizmediano pdemediano parmicro pabmicro tmediana">
                      PAGAR
                    </div>
                  </Button>
                )}

                {leads.length === 0 && (
                  <React.Fragment>
                    <div className="centro tgrande parmuychico pabmediano">
                      Aún no tienes{' '}
                      <span className="wbold verde">clientes</span> en tu{' '}
                      <span className="wbold azul">carrito</span>, da click aquí
                      abajo para elegir y comprar clientes.
                    </div>
                    <Button className="botonComprar" tag={Link} to="/clientes">
                      <div className="pizmediano pdemediano pabmicro tgrande">
                        Comprar clientes
                      </div>
                    </Button>
                  </React.Fragment>
                )}

                <div className="pabmuychico">
                  <Row>
                    <Col
                      xs={12}
                      sm={{ size: 10, offset: 1 }}
                      md={{ size: 12, offset: 0 }}>
                      <div className="paddingLeads">
                        <Row className="pargrande">
                          {leads.map(lead => (
                            <React.Fragment>
                              <Col xs={12} className="centradoLead pabmediano">
                                <LeadChico
                                  carrito={true}
                                  lead={lead}
                                  currency={this.user.currency}
                                />
                              </Col>
                            </React.Fragment>
                          ))}
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}
Carrito.contextType = AuthContext;
export default Carrito;
