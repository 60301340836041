// @format
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/Default.sass';
import './css/style.css';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { WebSocketLink } from 'apollo-link-ws';
import { onError } from 'apollo-link-error';
import { ApolloLink, split } from 'apollo-link';
import { createUploadLink } from 'apollo-upload-client';
import { ApolloProvider } from '@apollo/react-components';
import { getMainDefinition } from 'apollo-utilities';
import moment from 'moment';
import 'moment/locale/es-do';
moment.locale('es-do');

const cache = new InMemoryCache({
  dataIdFromObject: object => object.id,
});

const request = async operation => {
  const context = operation.getContext();
  if (context.token) {
    operation.setContext({
      headers: {
        authorization: `Bearer ${context.token}`,
      },
    });
  }
};

const wsLink = new WebSocketLink({
  uri:
    process.env.NODE_ENV === 'development'
      ? `ws://localhost:4000/graphql`
      : 'wss://graph.client.mx/graphql',
  options: {
    reconnect: true,
  },
});

const httpLink = createUploadLink({
  uri:
    process.env.NODE_ENV === 'development'
      ? `${process.env.HTTPS ? 'https' : 'http'}://localhost:4000/public`
      : 'https://graph.client.mx/public',
  credentials: 'include',
});

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        //sendToLoggingService(graphQLErrors);
        console.error('graphql error handle');
      }
      if (networkError) {
        //logoutUser();
        console.error('Network error handle');
      }
    }),
    link,
  ]),
  cache,
});
ReactDOM.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
