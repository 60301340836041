import React from 'react';
import {
  Button,
  Col,
  Row,
  Input,
  InputGroup,
  FormGroup,
  FormFeedback,
  InputGroupAddon,
} from 'reactstrap';
import SeleccionarEditarDatos from './SeleccionarEditarDatos';
import { FaPhone, FaUser } from 'react-icons/fa';
import { Mutation } from '@apollo/react-components';
import gql from 'graphql-tag';
import BtnUpdateUser from './BtnUpdateUser';

class DatosUsuario extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      firstName: props.user.firstName,
      lastName: props.user.lastName,
      phone: props.user.phone,
      agency: props.user.agency && props.user.agency.name,
      password: '',
      newPassword: '',
      repeatPassword: '',
      collapse: ''
    };
  }

  updateUser = gql`
    mutation($data: userUpdateInput!) {
      updateUser(data: $data) {
        user {
          id
          email
          firstName
          lastName
          agency {
            name
          }
        }
      }
    }
  `;

  handlePasswordChange({ target }) {
    const { name, value } = target;
    this.setState((state, props) => {
      return {
        password: {
          ...state.password,
          [name]: value,
        },
      };
    });
  }

  toggleShowPassword() {
    this.setState((state, props) => {
      return {
        password: {
          ...state.password,
          show: !state.password.show,
        },
      };
    });
  }

  render() {
    return (
      <section>
        <div className="centro wbold tgrande parmediano parmuygrande">
          <Row>
            <Col xs={4} md={5} className="derecha sinpymde">
              <FaUser />
            </Col>{' '}
            <Col xs={8} md={7} className="izquierda pizchico parmicro2">
              Datos Usuario
            </Col>
          </Row>
        </div>

        {/*nombre*/}
        <SeleccionarEditarDatos
          color="negro"
          fondo="fondoPantalla"
          concepto="Nombre:"
          valor={`${this.state.firstName} ${this.state.lastName}`}
          collapse={this.state.collapseNombre}
          onChangeCollapse={(collapseValue) => {
            this.setState({ collapseNombre : collapseValue });
          }}
          >
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <Button className="botonGris botonInput2">
                <div className="icon-id-3 tgrande" />
              </Button>
            </InputGroupAddon>
            <Input
              name="firtsName"
              type="text"
              placeholder="Ingrese su nombre(s)"
              defaultValue={this.state.firstName}
              value={this.state.firstName}
              onChange={({ target }) => {
                this.setState({
                  firstName: target.value,
                });
              }}
            />
          </InputGroup>
          <InputGroup className="parmicro2">
            <InputGroupAddon addonType="prepend">
              <Button className="botonGris botonInput2">
                <div className="icon-id-3 tgrande" />
              </Button>
            </InputGroupAddon>
            <Input
              name="lastName"
              type="text"
              placeholder="Ingrese su(s) apellido(s)"
              defaultValue={this.state.lastName}
              value={this.state.lastName}
              onChange={({ target }) => {
                this.setState({
                  lastName: target.value,
                });
              }}
            />
          </InputGroup>
          <BtnUpdateUser
            mutation={this.updateUser}
            data={{
              id: this.props.user.id,
              firstName: this.state.firstName,
              lastName: this.state.lastName,
            }}
            setCollapse={( collapseValue ) => {
              this.setState({ collapseNombre: collapseValue});
            }}
          />
        </SeleccionarEditarDatos>

        {/*empresa*/}
        <SeleccionarEditarDatos
          color="negro"
          fondo="fondoPantalla"
          concepto="Empresa:"
          valor={`${this.state.agency ? this.state.agency : 'N/A'}`}
          collapse={this.state.collapseEmpresa}
          onChangeCollapse={(collapseValue) => {
            this.setState({ collapseEmpresa : collapseValue });
          }}
        >
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <Button className="botonGris botonInput2">
                <div className="icon-real-estate-2 tgrande" />
              </Button>
            </InputGroupAddon>
            <Input
              name="agency"
              type="text"
              placeholder="Nombre de la empresa"
              defaultValue={this.state.agency ? this.state.agency : ''}
              value={this.state.agency}
              onChange={({ target }) => {
                this.setState({
                  agency: target.value,
                });
              }}
            />
          </InputGroup>
          <BtnUpdateUser
            mutation={this.updateUser}
            data={{
              id: this.props.user.id,
              agency: { name: this.state.agency },
            }}
            setCollapse={( collapseValue ) => {
              this.setState({ collapseEmpresa: collapseValue});
            }}
          />
        </SeleccionarEditarDatos>

        {/*telefono*/}
        <SeleccionarEditarDatos
          fondo="fondoPantalla"
          color="negro"
          concepto="Teléfono"
          valor={this.props.user.phone}
          collapse={this.state.collapseTel}
          onChangeCollapse={(collapseValue) => {
            this.setState({ collapseTel : collapseValue });
          }}>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <Button className="botonGris botonInput2">
                <FaPhone />
              </Button>
            </InputGroupAddon>
            <Input
              name="telefono"
              type="text"
              placeholder="Ingrese su telefono"
              defaultValue={this.state.phone}
              value={this.state.phone}
              onChange={({ target }) => {
                this.setState({
                  phone: target.value,
                });
              }}
            />
          </InputGroup>
          <BtnUpdateUser
            mutation={this.updateUser}
            data={{ id: this.props.user.id, phone: this.state.phone }}
            setCollapse={( collapseValue ) => {
              this.setState({ collapseTel: collapseValue});
            }}
          />
        </SeleccionarEditarDatos>

        {/* puesto
        <SeleccionarEditarDatos
          fondo="fondoPantalla"
          color="negro"
          concepto="Puesto"
          valor={this.props.puesto}>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <Button className="botonGris botonInput2">
                <div className="d-inline icon-contrato" />
              </Button>
            </InputGroupAddon>
            <Input type="select">
              <option value="">Dueño</option>
              <option value="">Director</option>
              <option value="">Gerente</option>
              <option value="">Empleado</option>
            </Input>
          </InputGroup>
          <div className="centro parmuychico pabmicro">
            <Button className="botonGris tchica">ACTUALIZAR</Button>
          </div>
        </SeleccionarEditarDatos> */}

        {/*email*/}
        <SeleccionarEditarDatos
          color="negro"
          concepto="Email"
          valor={this.props.user.email}
          falso
        />

        {/*contraseña*/}
        <SeleccionarEditarDatos
          fondo="fondoPantalla"
          color="negro"
          concepto="Contrase&ntilde;a"
          valor="********"
          collapse={this.state.collapsePassword}
          onChangeCollapse={(collapseValue) => {
            this.setState({ collapsePassword : collapseValue });
          }}>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <Button
                className="botonGris botonInput2"
                onClick={this.toggleShowPassword.bind(this)}>
                <div className="icon-password tgrande" />
              </Button>
            </InputGroupAddon>
            <Input
              name="password"
              type={this.state.password.show ? 'text' : 'password'}
              value={this.state.password.password}
              onChange={this.handlePasswordChange.bind(this)}
              placeholder="Antigua contraseña"
            />
          </InputGroup>
          <InputGroup className="parmicro2">
            <InputGroupAddon addonType="prepend">
              <Button
                className="botonGris botonInput2"
                onClick={this.toggleShowPassword.bind(this)}>
                <div className="icon-password tgrande" />
              </Button>
            </InputGroupAddon>
            <Input
              name="newPassword"
              value={this.state.password.newPassword}
              type={this.state.password.show ? 'text' : 'password'}
              onChange={this.handlePasswordChange.bind(this)}
              placeholder="Nueva contraseña"
            />
          </InputGroup>
          <FormGroup>
            <InputGroup className="parmicro2">
              <InputGroupAddon addonType="prepend">
                <Button
                  className="botonGris botonInput2"
                  onClick={this.toggleShowPassword.bind(this)}>
                  <div className="icon-password tgrande" />
                </Button>
              </InputGroupAddon>
              <Input
                fondo="fondoPantalla"
                name="repeatPassword"
                type={this.state.password.show ? 'text' : 'password'}
                value={this.state.password.repeatPassword}
                onChange={this.handlePasswordChange.bind(this)}
                placeholder="Repite la nueva contrase&ntilde;a"
                invalid={
                  this.state.password.newPassword &&
                  this.state.password.newPassword !==
                    this.state.password.repeatPassword
                }
                valid={
                  this.state.password.newPassword &&
                  this.state.password.newPassword ===
                    this.state.password.repeatPassword
                }
              />
              <FormFeedback valid>Las contraseñas coinciden</FormFeedback>
              <FormFeedback>Las contraseñas no coinciden</FormFeedback>
            </InputGroup>
          </FormGroup>
          <div className="linkNegro tchica centro">
            <a href="/recuperar-contrasena" className="wbold">
              ¿Olvidaste tu contrase&ntilde;a?
            </a>
          </div>
          <BtnUpdateUser
            changePassword="true"
            password={this.state.password.password}
            newPassword={this.state.password.newPassword}
            newPasswordR={this.state.password.repeatPassword}
            setCollapse={( collapseValue ) => {
              this.setState({ collapsePassword: collapseValue});
            }}
          />
        </SeleccionarEditarDatos>
      </section>
    );
  }
}

export default DatosUsuario;
