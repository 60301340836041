import React from "react";
import { Col, Row, Input } from "reactstrap";
import SeleccionarEditarDatos from "./SeleccionarEditarDatos";

class MetodosPago extends React.PureComponent {
  render() {
    return (
      <section>
        <div className="centro wbold tgrande parmediano">
          <p>Método(s) de Pago</p>
        </div>
        <SeleccionarEditarDatos
          fondo="fondoGrisClaro"
          color="azul"
          concepto="Tarjeta"
          valor="**** **** **** 0443"
        >
          <div className="fondoGrisClaro pmediano" />
        </SeleccionarEditarDatos>
        {this.props.metodos.map(metodo => (
          <SeleccionarEditarDatos
            fondo="fondoGrisClaro"
            color="azul"
            concepto={metodo.tipo_de_pago}
            valor={"Cuenta: " + metodo.cuenta}
          >
            {!this.props.tipo_de_pago === "Efectivo" && (
              <React.Fragment>
                <div className="fondoGrisClaro pmediano">
                  <Row>
                    {this.props.tipo_de_pago === "Transferencia" && (
                      <Col xs={12} className="centro">
                        <Input
                          name="banco"
                          className="letraChicaInput"
                          type="text"
                          placeholder="Banco"
                          defaultValue={this.props.banco}
                        />
                        <Input
                          name="numero_de_cuenta"
                          className="letraChicaInput"
                          type="number"
                          placeholder="Número de cuenta"
                          defaultValue={this.props.numero_de_cuenta}
                        />
                      </Col>
                    )}
                    {this.props.tipo_de_pago === "Tarjeta" && (
                      <Col xs={12} className="centro">
                        <Input
                          name="titular"
                          className="letraChicaInput"
                          type="text"
                          placeholder="Titular"
                          defaultValue={this.props.titular}
                        />
                        <Input
                          name="banco"
                          className="letraChicaInput"
                          type="text"
                          placeholder="Banco"
                          defaultValue={this.props.banco}
                        />
                        <Input
                          name="numero_de_tarjeta"
                          className="letraChicaInput"
                          type="number"
                          placeholder="Número de tarjeta"
                          defaultValue={this.props.numero_de_tarjeta}
                        />
                        <Input
                          name="fecha"
                          className="letraChicaInput"
                          type="date"
                          placeholder="Fecha vencimiento"
                          defaultValue={this.props.fecha}
                        />
                        <Input
                          name="codigo"
                          className="letraChicaInput"
                          type="number"
                          placeholder="Codigo (3 digitos)"
                          defaultValue={this.props.codigo}
                        />
                      </Col>
                    )}
                    {this.props.tipo_de_pago === "Cheque" && (
                      <Col xs={12} className="centro">
                        <Input
                          name="banco"
                          className="letraChicaInput"
                          type="text"
                          placeholder="Banco"
                          defaultValue={this.props.banco}
                        />
                      </Col>
                    )}
                  </Row>
                </div>
              </React.Fragment>
            )}
          </SeleccionarEditarDatos>
        ))}
      </section>
    );
  }
}

export default MetodosPago;
