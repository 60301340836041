import React, { Component } from "react";
import {
	Row,
	Col,
	Button,
	Carousel,
	CarouselItem,
	CarouselControl,
	CarouselIndicators,
	Input,
	FormGroup,
	InputGroup,
	InputGroupAddon,
	FormFeedback
} from "reactstrap";
import FsLightbox from "fslightbox-react";

function numberWithCommas(x) {
	x = Math.round(x).toString();
	var pattern = /(-?\d+)(\d{3})/;
	while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
	return x;
}

class AutorizarPropiedad extends Component {
	state = {
		activeIndex: 0,
		aceptado: false,
		id: `carousel-${this.props.property.id}`,
		modalIsOpen: false
	};

	propertyType = ["HOUSE", "APPARTMENT", "LAND", "OFFICE", "STORAGE", "SHOP"];

	sp = {
		APPARTMENT: "Departamento",
		HOUSE: "Casa",
		LAND: "Terreno",
		OFFICE: "Oficina",
		STORAGE: "Bodega",
		SHOP: "Local"
	};

	aceptado() {
		this.setState({ aceptado: !this.state.aceptado });
	}

	onExiting() {
		this.animating = true;
	}

	onExited() {
		this.animating = false;
	}

	next() {
		if (this.animating) return;
		const nextIndex =
			this.state.activeIndex === this.props.property.pictures.length - 1
				? 0
				: this.state.activeIndex + 1;
		this.setState({ activeIndex: nextIndex });
	}

	previous() {
		if (this.animating) return;
		const nextIndex =
			this.state.activeIndex === 0
				? this.props.property.pictures.length - 1
				: this.state.activeIndex - 1;
		this.setState({ activeIndex: nextIndex });
	}

	goToIndex(newIndex) {
		if (this.animating) return;
		this.setState({ activeIndex: newIndex });
	}

	render() {
		const { activeIndex } = this.state;
		const slides = this.props.property.pictures.map((foto, i) => {
			return (
				<CarouselItem
					onExiting={this.onExiting.bind(this)}
					onExited={this.onExited.bind(this)}
					key={`ci${i}`}
				>
					{i === 0 ? (
						<img
							async
							src={`${foto.url}`}
							id={`${this.state.id}-p${i}`}
							className="tfoto"
							alt={`i${i}`}
							onClick={e => {
								this.setState((state, props) => ({
									modalIsOpen: !state.modalIsOpen
								}));
							}}
						/>
					) : (
						<img
							async
							data-src={`${foto.url}`}
							id={`${this.state.id}-p${i}`}
							className="tfoto"
							alt={`i${i}`}
							onClick={e => {
								this.setState((state, props) => ({
									modalIsOpen: !state.modalIsOpen
								}));
							}}
						/>
					)}
				</CarouselItem>
			);
		});

		return (
			<React.Fragment>
				<FsLightbox
					slide={this.state.activeIndex}
					toggler={this.state.modalIsOpen}
					sources={this.props.property.pictures.map(pic => pic.url)}
				/>
				<div className="pabmediano centered2">
					<div className="fondoGrisClaro4 tpropiedadAutorizar">
						<div className="negro parmuychico mayus tchica wbold">
							<Row>
								<Col className="centro" xs={4}>
									{this.props.property.origin.state}
								</Col>
								<Col className="centro pizmicro">
									Ciudad de {this.props.property.origin.city}
								</Col>
							</Row>
							<div className="centro">
								Zona: {this.props.property.zone}
							</div>
						</div>
						<div className="fondoVerde blanco wbold parmuychico pabmuychico">
							<Row className="tchica pdemuychico pizmuychico">
								<Col xs={6} className="mayus derecha pdemicro">
									<FormGroup className="sinpym">
										<InputGroup>
											<InputGroupAddon addonType="prepend">
												<div className="paddingAddon3 fondoBlanco borderBlanco centro">
													<div className="icon-descuento-1 tmediana verde" />
												</div>
											</InputGroupAddon>
											<Input
												name="operationType"
												className="letraChicaInput"
												type="select"
												defaultValue={
													this.props.property
														.operationType
												}
											>
												<option
													hidden
													value={
														this.props.property
															.operationType
													}
												>
													{
														this.props.property
															.operationType
													}
												</option>
												<option value="Venta">
													Venta
												</option>
												<option value="Renta">
													Renta
												</option>
											</Input>
										</InputGroup>
										<FormFeedback>Operación</FormFeedback>
									</FormGroup>
								</Col>
								<Col xs={6} className="centro pizmicro">
									<FormGroup className="sinpym">
										<InputGroup>
											<InputGroupAddon addonType="prepend">
												<div className="paddingAddon3 fondoBlanco borderBlanco centro">
													<div className="icon-dolar tmediana verde" />
												</div>
											</InputGroupAddon>
											<Input
												name="price"
												className="letraChicaInput"
												type="number"
												defaultValue={Math.round(
													this.props.property.price *
														this.props.currency
															.factor
												)}
											/>
										</InputGroup>
										<FormFeedback>Precio</FormFeedback>
									</FormGroup>
								</Col>
								<Col
									xs={6}
									className="izquierda pdemicro blanco parmicro"
								>
									<FormGroup className="sinpym">
										<InputGroup>
											<InputGroupAddon addonType="prepend">
												<div className="paddingAddon3 fondoBlanco borderBlanco centro">
													<div className="icon-monedas tmediana verde" />
												</div>
											</InputGroupAddon>
											<Input
												name="currency"
												className="letraChicaInput"
												type="select"
												defaultValue={
													this.props.currency.name
												}
											>
												<option
													hidden
													value={
														this.props.currency.name
													}
												>
													{this.props.currency.name}
												</option>
												<option value="MXN">MXN</option>
												<option value="USD">USD</option>
											</Input>
										</InputGroup>
										<FormFeedback>Divisa</FormFeedback>
									</FormGroup>
								</Col>
								<Col
									xs={6}
									className="izquierda pizmicro blanco parmicro"
								>
									<FormGroup className="sinpym">
										{this.props.property.operationType ===
											"Venta" && (
											<InputGroup>
												<InputGroupAddon addonType="prepend">
													<div className="paddingAddon3 fondoBlanco borderBlanco centro">
														<div className="icon-pago tmediana verde" />
													</div>
												</InputGroupAddon>
												<Input
													name="commission"
													className="letraChicaInput"
													type="select"
													defaultValue={
														this.props.property
															.commission
													}
												>
													<option
														defaultValue={
															this.props.property
																.commission
														}
														hidden
													>
														{
															this.props.property
																.commission
														}
													</option>
													<option value=".5">
														% 1
													</option>
													<option value="1.5">
														% 1.5
													</option>
													<option value="2">
														% 2
													</option>
													<option value="2.5">
														% 2.5
													</option>
													<option value="3">
														% 3
													</option>
													<option value="3.5">
														% 3.5
													</option>
													<option value="4">
														% 4
													</option>
													<option value="4.5">
														% 4.5
													</option>
													<option value="5">
														% 5
													</option>
													<option value="5.5">
														% 5.5
													</option>
													<option value="6">
														% 6
													</option>
													<option value="6.5">
														% 6.5
													</option>
													<option value="7">
														% 7
													</option>
													<option value="7.5">
														% 7.5
													</option>
													<option value="8">
														% 8
													</option>
													<option value="8.5">
														% 8.5
													</option>
													<option value="9">
														% 9
													</option>
													<option value="9.5">
														% 9.5
													</option>
													<option value="10">
														% 10
													</option>
												</Input>
											</InputGroup>
										)}

										{this.props.property.operationType ===
											"Renta" && (
											<InputGroup>
												<InputGroupAddon addonType="prepend">
													<div className="paddingAddon3 fondoBlanco borderBlanco centro">
														<div className="icon-pago tmediana verde" />
													</div>
												</InputGroupAddon>
												<Input
													name="commission"
													className="letraChicaInput"
													type="select"
													defaultValue={
														this.props.property
															.commission
													}
												>
													<option
														defaultValue={
															this.props.property
																.commission
														}
														hidden
													>
														{
															this.props.property
																.commission
														}
													</option>
													<option value=".5">
														%50 de un mes de renta
													</option>
													<option value=".75">
														%75 de un mes de renta
													</option>
													<option value="1">
														%100 de un mes de renta
													</option>
													<option value="1.25">
														%125 de un mes de renta
													</option>
													<option value="1.5">
														%150 de un mes de renta
													</option>
													<option value="1.75">
														%175 de un mes de renta
													</option>
													<option value="2">
														%200 de un mes de renta
													</option>
													<option value="3">
														%300 de un mes de renta
													</option>
													<option value="4">
														%400 de un mes de renta
													</option>
													<option value="5">
														%500 de un mes de renta
													</option>
												</Input>
											</InputGroup>
										)}
										<FormFeedback>Comisión</FormFeedback>
									</FormGroup>
								</Col>
							</Row>
						</div>
						<Carousel
							activeIndex={activeIndex}
							next={this.next.bind(this)}
							previous={this.previous.bind(this)}
							interval={false}
						>
							<CarouselIndicators
								items={this.props.property.pictures.map(
									(foto, i) => ({
										key: foto.url
									})
								)}
								activeIndex={activeIndex}
								onClickHandler={this.goToIndex.bind(this)}
							/>
							{slides}
							<CarouselControl
								direction="prev"
								directionText="Previous"
								onClickHandler={this.previous.bind(this)}
							/>
							<CarouselControl
								direction="next"
								directionText="Next"
								onClickHandler={this.next.bind(this)}
							/>
						</Carousel>
						<div className="pizmicro pdemicro">
							<div className="centro parmicro">
								<div className="wbold gris mayus tchica pizmuychico pdemicro">
									<FormGroup className="sinpym">
										<InputGroup>
											<InputGroupAddon addonType="prepend">
												<div className="paddingAddon3 fondoVerde borderVerde centro">
													<div className="icon-casa blanco tmediana" />
												</div>
											</InputGroupAddon>
											<Input
												name="propertyType"
												className="letraChicaInput"
												type="select"
											>
												<option
													value={
														this.props.property
															.propertyType
													}
													hidden
												>
													{
														this.props.property
															.propertyType
													}
												</option>
												{this.propertyType.map(
													property => (
														<option
															value={property}
														>
															{this.sp[property]}
														</option>
													)
												)}
											</Input>
										</InputGroup>
										<FormFeedback>
											Tipo de propiedad
										</FormFeedback>
									</FormGroup>
								</div>
							</div>
							<Row className="tchica parmicro pabmuychico pizmuychico pdemicro">
								<Col xs={6} className="centro pdemicro">
									<FormGroup className="sinpym">
										<InputGroup>
											<InputGroupAddon addonType="prepend">
												<div className="paddingAddon3 fondoRojo centro">
													<div className="icon-regla-vacia tchica blanco" />
												</div>
											</InputGroupAddon>
											<Input
												name="landMeters"
												className="letraChicaInput"
												type="number"
												defaultValue={
													this.props.property
														.landMeters
												}
												placeholder={`Terreno: ${
													this.props.property
														.landMeters
												}`}
											/>
										</InputGroup>
										<FormFeedback>
											Indique la zona en dónde se
											encuentra la propiedad
										</FormFeedback>
									</FormGroup>
								</Col>
								<Col xs={6} className="izquierda pizmicro">
									<FormGroup className="sinpym">
										<InputGroup>
											<InputGroupAddon addonType="prepend">
												<div className="paddingAddon3 fondoRojo centro">
													<div className="icon-construccion tchica blanco" />
												</div>
											</InputGroupAddon>
											<Input
												name="constructionMeters"
												className="letraChicaInput"
												type="number"
												defaultValue={
													this.props.property
														.constructionMeters
												}
												placeholder={`Construcción: ${
													this.props.property
														.constructionMeters
												}`}
											/>
										</InputGroup>
										<FormFeedback>
											Indique la zona en dónde se
											encuentra la propiedad
										</FormFeedback>
									</FormGroup>
								</Col>
								<Col
									xs={6}
									className="centro pdemicro parmicro"
								>
									<FormGroup className="sinpym">
										<InputGroup>
											<InputGroupAddon addonType="prepend">
												<div className="paddingAddon3 fondoRojo centro">
													<div className="icon-cama tchica blanco" />
												</div>
											</InputGroupAddon>
											<Input
												name="bedrooms"
												className="letraChicaInput"
												type="number"
												defaultValue={
													this.props.property.bedrooms
												}
												placeholder={`Cuartos: ${
													this.props.property.bedrooms
												}`}
											/>
										</InputGroup>
										<FormFeedback>
											Indique la zona en dónde se
											encuentra la propiedad
										</FormFeedback>
									</FormGroup>
								</Col>
								<Col
									xs={6}
									className="izquierda pizmicro parmicro"
								>
									<FormGroup className="sinpym">
										<InputGroup>
											<InputGroupAddon addonType="prepend">
												<div className="paddingAddon3 fondoRojo centro">
													<div className="icon-escusado tchica blanco" />
												</div>
											</InputGroupAddon>
											<Input
												name="bathrooms"
												className="letraChicaInput"
												type="number"
												step=".5"
												defaultValue={
													this.props.property
														.bathrooms
												}
												placeholder={`Baños: ${
													this.props.property
														.bathrooms
												}`}
											/>
										</InputGroup>
										<FormFeedback>
											Indique cuantos baños
										</FormFeedback>
									</FormGroup>
								</Col>
							</Row>

							<div className="tchica parchico pabmuychico">
								<Input
									name="description"
									type="textarea"
									className="tchica"
									rows={5}
									placeholder={`Descripción: ${
										this.props.property.description
									}`}
									defaultValue={
										this.props.property.description
									}
								/>
							</div>
							{this.props.cambio && (
								<div>
									<Button className="claseBoton3 pladoschico botonGris">
										<div className="icon-documento d-inline" />{" "}
										Guardar cambios
									</Button>
								</div>
							)}
							<div className="centro bottomPropiedad">
								<Button className="botonAzul claseBoton3">
									<div className="parmicro pizmicro pdemicro">
										Aceptar
									</div>
								</Button>
								<Button className="botonRojo claseBoton3">
									<div className="parmicro pizmicro pdemicro">
										Rechazar
									</div>
								</Button>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default AutorizarPropiedad;
