import React, { PureComponent } from "react";
import { Modal, ModalBody, Button } from "reactstrap";
import anime from "animejs/lib/anime.es.js";

class ModalPrimeraVisita extends PureComponent {
	constructor(props) {
		super(props);
		const showModal = localStorage.getItem("showModal");
		this.state = {
			isOpen: false,
			modal: !showModal
		};
	}

	componentDidMount() {
		window.scrollTo(0, 1);
	}

	modal() {
		this.setState({
			modal: !this.state.modal
		});
		localStorage.setItem("showModal", true);
	}

	sweepIn(origin, destination, direction) {
		if (destination.index === 0) {
			anime({
				targets: ".fadeIn2",
				opacity: 1,
				scale: 1.2,
				duration: 800,
				delay: 800
			});

			anime({
				targets: ".fadeIn3",
				opacity: 1,
				scale: 1.2,
				duration: 800,
				delay: 1600
			});

			anime({
				targets: ".fadeIn4",
				opacity: 1,
				scale: 1.2,
				delay: 2400
			});

			anime({
				targets: ".fadeIn5",
				opacity: 1,
				scale: 1.2,
				duration: 800,
				delay: 3200
			});

			anime({
				targets: ".fadeIn6",
				opacity: 1,
				scale: 1.2,
				duration: 800,
				delay: 3800
			});
		}
	}

	toggle() {
		this.setState({
			isOpen: !this.state.isOpen
		});
	}
	render() {
		return (
			<React.Fragment>
				<Modal isOpen={this.state.modal} toggle={this.modal.bind(this)}>
					<div className="centro wbold tgrande fondoVerde blanco pmediano">
						¡Regístrate ahora!
					</div>
					<ModalBody className="wbold negro">
						<div className="fadeIn2 paddingModal">
							<div className="icon-checkbox opcionesPorque parmuychico d-inline gris" />{" "}
							Regístrate ahora y obten $500 pesos de regalo
						</div>
						<div className="parchico">
							<div className="fadeIn3 paddingModal">
								<div className="icon-checkbox opcionesPorque parmuychico d-inline gris" />{" "}
								Sin compromiso
							</div>
						</div>
						<div className="parchico">
							<div className="fadeIn4 paddingModal">
								<div className="icon-checkbox opcionesPorque parmuychico d-inline gris" />{" "}
								Sin necesidad de ingresar tu tarjeta
							</div>
						</div>
						<div className="parchico">
							<div className="fadeIn5 paddingModal">
								<div className="icon-checkbox opcionesPorque parmuychico d-inline gris" />{" "}
								¡Te garantizamos resultados!
							</div>
						</div>
						<div className="parchico">
							<div className="fadeIn6 paddingModal centro">
								<Button
									href="/iniciar-sesion#registrate"
									className="botonAzul claseBoton3 pladoschico botonRegistrate claseBoton2 parmuychico"
								>
									¡Registrate aquí!
								</Button>
								<div
									onClick={this.modal.bind(this)}
									className="linkRojo2 subrayado tchica parchico"
								>
									Registrarse después
								</div>
							</div>
						</div>
					</ModalBody>
				</Modal>
			</React.Fragment>
		);
	}
}

export default ModalPrimeraVisita;
