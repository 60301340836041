import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import {
  Inicio,
  Logeado,
  Error404,
  IniciarSesion,
  Terminos,
  Admin,
  PostSignup,
} from './components';
import PrivateRoute from './PrivateRoute';


const App = () => {
  return (
    
      <Switch>
        <Route exact path="/post-signup" component={PostSignup} />
        <Route path="/admin" component={Admin} />
        <Route path="/iniciar-sesion" component={IniciarSesion} />
        <Route path="/terminos-y-condiciones" component={Terminos} />
        <Route exact path="/" component={Inicio} />
        <PrivateRoute path="/" component={Logeado} />
        <Route render={() => <Error404 />} />
      </Switch>
    
  );
};

export default App;
