import React, { Component } from 'react';
import {
  Row,
  Col,
  Input,
  Form,
  FormGroup,
  FormText,
  FormFeedback,
  InputGroup,
  InputGroupText,
  Label,
  InputGroupAddon,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  ButtonGroup,
  CustomInput,
  Collapse,
} from 'reactstrap';
import { FaSearch, FaHome, FaTrash, FaTimes } from 'react-icons/fa';
import {
  IoMdArrowDropright,
  IoMdArrowDropdown,
  IoMdArrowDropup,
} from 'react-icons/io';
import MapsGoogleMaps from './Location/MapsGoogleMaps';
import PropTypes from 'prop-types';

const ORIGINAL_LAT = 19.0379295;
const ORIGINAL_LNG = -98.2034607;

class FiltrarPropiedades extends Component {
  constructor(props) {
    super(props);
    this.setAndSwapOrder = this.setAndSwapOrder.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  state = {
    applyFilter: false,
    open: false,
    orderBy: '', // set to string to use string methods cast to undefined if empty
    zoneOpen: false,
    orderByOpen: false,
    commission_gt: undefined,
    price: undefined,
    operationType: undefined,
    propertyType: undefined,
    id_in: [],
    latitude: ORIGINAL_LAT,
    longitude: ORIGINAL_LNG,
    address: '',
    placeId: ''
  };

  componentDidMount() {
    this.loadSession();
  }

  componentWillMount() {
    this.loadSession();
  }

  clear(session) {
    this.setState({
      orderBy: '', // set to string to use string methods cast to undefined if empty
      commission_gt: 0,
      price: undefined,
      operationType: undefined,
      propertyType: undefined,
      id_in: [],
      latitude: 19.0379295,
      longitude: -98.2034607,
      address: '',
      placeId: ''
    });
    if (session) {
      sessionStorage.removeItem(this.props.storageId);
      sessionStorage.removeItem(this.props.storageId+'address');
    }
  }

  loadSession() {
    try {
      const session = sessionStorage.getItem(this.props.storageId);
      if (session) {
        const sessionData = JSON.parse(session);
        this.setState(sessionData);
        if (Object.keys(sessionData).length > 0){
          this.setState({ applyFilter: true });
          if (sessionData.location){
            let addressSession = sessionStorage.getItem(this.props.storageId+'address');
            let address = JSON.parse(addressSession);
            this.setState({ latitude: sessionData.location.coordinates.latitude });
            this.setState({ longitude: sessionData.location.coordinates.longitude });
            this.setState({ address: address.address });
            this.setState({ placeId: address.placeId });
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  getCollapseSign(value) {
    return value ? <IoMdArrowDropdown /> : <IoMdArrowDropright />;
  }

  getOrderDirection() {
    return this.state.orderBy.endsWith('_ASC') ? (
      <IoMdArrowDropup />
    ) : (
        <IoMdArrowDropdown />
      );
  }

  getorderByComponentInfo = () => ({
    created: this.state.orderBy.startsWith('CREATED'),
    price: this.state.orderBy.startsWith('PRICE'),
    commission: this.state.orderBy.startsWith('COMMISSION'),
  });

  setAndSwapOrder(ascending, descending) {
    this.setState(state => {
      if (state.orderBy === ascending) {
        return { orderBy: descending };
      } else {
        return { orderBy: ascending };
      }
    });
  }

  commissionRangeInfo() {
    switch (this.state.operationType) {
      case 'RENT':
        return {
          step: 50,
          max: 500,
        };
      case 'BUY':
        return {
          step: 0.5,
          max: 10,
        };
      default:
        return {
          step: 0.5,
          max: 20,
        };
    }
  }

  setCommission({ target }) {
    const numberValue = parseFloat(target.value);
    if (!this.state.operationType && numberValue > 10) {
      const value = (target.value - 10) * 50;
      this.setState({ commission_gt: value });
    } else {
      this.setState({ commission_gt: numberValue });
    }
  }

  getCommission() {
    const numberValue = this.state.commission_gt;
    if (!this.state.operationType && numberValue > 10) {
      const value = 10 + numberValue / 50;
      return value;
    } else {
      return numberValue;
    }
  }

  propertyType = ['HOUSE', 'APPARTMENT', 'LAND', 'OFFICE', 'STORAGE', 'SHOP'];

  sp = {
    APPARTMENT: 'Departamento',
    HOUSE: 'Casa',
    LAND: 'Terreno',
    OFFICE: 'Oficina',
    STORAGE: 'Bodega',
    SHOP: 'Local',
  };

  handleChange({ target }) {
    const { name, value, type, dataset } = target;
    let val = value;
    switch (type) {
      case 'number':
        val = parseFloat(value, 10);
        break;
      case 'select-one':
        val = target.selectedOptions[0].value;
        break;
      default:
        if (dataset.type === 'array') {
          val = val.split(',');
        }
        break;
    }
    this.setState((state, _) => ({
      [name]: val,
    }));
  }
  open() {
    if (this.state.open) {
      this.clear();
    } else {
      this.loadSession();
    }
    this.setState({ open: !this.state.open });
  }

  formatPriceValue (price) {
    let val = price.replace(/,/g, '');
    const exp = /(\d)(?=(\d{3})+(?!\d))/g;
    const rep = '$1,';
    let arr = val.toString().split('.');
    arr[0] = arr[0].replace(exp,rep);
    let newPrice= arr[1] ? arr.join('.'): arr[0];
    return newPrice
  }
  
  setMapInfo(address, placeId) {
    this.setState({ address, placeId });
  }

  propertyTypeOptions = () => {
    const opts = Object.entries(this.sp);
    return opts.map(pt => <option value={pt[0]}>{pt[1]}</option>);
  };

  toggleZone = () => this.setState(state => ({ zoneOpen: !state.zoneOpen }));
  toggleOrderBy = () =>
    this.setState(state => ({ orderByOpen: !state.orderByOpen }));

  setFilter() {
    var {
      commission_gt,
      price,
      operationType,
      propertyType,
      id_in,
      latitude,
      longitude,
      placeId,
      address
    } = this.state;

    if (price) {
      price = parseFloat(price.replace(/,/g, ''));
    }

    const orderBy = this.state.orderBy === '' ? undefined : this.state.orderBy; // set to string to use string methods cast to undefined if empty
    const filter = {
      commission_gt,
      price,
      operationType,
      propertyType,
      id_in: id_in.length ? id_in.map(parseInt) : undefined,
      location:
        latitude === ORIGINAL_LAT || longitude === ORIGINAL_LNG
          ? undefined
          : {
            coordinates: {
              latitude,
              longitude,
            },
          },
    };
    const addressData = {
      placeId,
      address,
    }

    this.props.setFilter({ filter, orderBy });
    sessionStorage.setItem(
      this.props.storageId,
      JSON.stringify(Object.assign({}, filter, { orderBy })),
    );
    sessionStorage.setItem(this.props.storageId+'address', 
      JSON.stringify(addressData));
  }

  getButton() {
    return window.matchMedia('(max-width: 768px)').matches ? (
      <React.Fragment>
        {this.state.applyFilter ?
          (<Button onClick={this.open.bind(this)} className="float-btn" style={{ backgroundColor: '#7E1416' }}>
            <FaTimes />{' '}
          </Button>
          ) :
          <Button onClick={this.open.bind(this)} className="float-btn">
            <FaSearch />{' '}
          </Button>
        }
      </React.Fragment>
    ) : (
        <React.Fragment>
          {this.state.applyFilter ? (
            <Button onClick={() => {
              this.clear(true);
              this.setState({ applyFilter: false })
              setTimeout(this.setFilter.bind(this), 500);
            }} color="link" style={{ color: 'grey' }}>
              Limpiar filtros
            </Button>
          ) : ''}
          <Button onClick={this.open.bind(this)} className="botonVerde">
            <FaSearch />{' '}
          </Button>
        </React.Fragment>
      );
  }

  commisionComponent() {
    const commissionRangeInfo = this.commissionRangeInfo();
    return (
      <React.Fragment>
        <Label for="commission">Comisión</Label>
        <Row>
          <Col>
            <FormGroup>
              <CustomInput
                type="range"
                defaultValue={0}
                value={this.getCommission()}
                step={commissionRangeInfo.step}
                min={0}
                max={commissionRangeInfo.max}
                onChange={this.setCommission.bind(this)}
                name="commission_gt"
                id="commission"
              />
              <FormText>Mostraremos comisiones iguales o mayores</FormText>
            </FormGroup>
          </Col>
          <Col xs={2}>
            <span>{this.state.commission_gt}%</span>
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  render() {
    const orderByComponentInfo = this.getorderByComponentInfo();
    return (
      <React.Fragment>
        {this.getButton()}
        <Modal isOpen={this.state.open} toggle={this.open.bind(this)}>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="id">Buscar por propiedad</Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>#</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    id="id"
                    name="id_in"
                    onChange={this.handleChange}
                    value={this.state.id_in.join(',')}
                    data-type="array"
                    placeholder="Número de propiedad"
                  />
                </InputGroup>
              </FormGroup>
              <Label for="operationType">Operación</Label>
              <FormGroup>
                <ButtonGroup id="operationType" className="w-100">
                  <Button
                    id="operationType"
                    className="fondoVerde text-white"
                    onClick={() => this.setState({ operationType: 'BUY' })}
                    active={this.state.operationType === 'BUY'}>
                    Venta
                  </Button>
                  <Button
                    id="operationType"
                    className="fondoVerde text-white"
                    onClick={() => this.setState({ operationType: 'RENT' })}
                    active={this.state.operationType === 'RENT'}>
                    Renta
                  </Button>
                  <Button
                    id="operationType"
                    className="fondoVerde text-white"
                    onClick={() => this.setState({ operationType: undefined })}
                    active={!this.state.operationType}>
                    Ambos
                  </Button>
                </ButtonGroup>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="propertyType">Tipo de propiedad</Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaHome />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    id="propertyType"
                    value={this.state.propertyType || 'lorem'}
                    type="select"
                    onChange={this.handleChange}
                    name="propertyType">
                    <option value="lorem" hidden>
                      Seleccionar
                    </option>
                    {this.propertyTypeOptions()}
                  </Input>
                </InputGroup>
              </FormGroup>
              <Label for="price">Precio</Label>
              <Row form>
                <Col>
                  <FormGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText className="fondoVerde text-white">
                          $
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        id="price"
                        min="0"
                        step="1000"
                        name="price"
                        value={this.state.price}
                        onChange={({ target }) => {
                            let newPrice = this.formatPriceValue(target.value);
                            this.setState({ price:  newPrice});
                          }
                        }
                        placeholder="Precio (aproximado)"
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
            <Label id="location-collapse" onClick={this.toggleZone.bind(this)}>
              {this.getCollapseSign(this.state.zoneOpen)}
              Zona <small className="text-muted">(click para ver)</small>
            </Label>
            <Collapse isOpen={this.state.zoneOpen}>
              <MapsGoogleMaps
                destination={this.state.address}
                lat={this.state.latitude}
                lng={this.state.longitude}
                setLocation={({ lat, lng }) =>
                  this.setState({ latitude: lat, longitude: lng })
                }
                setAddresAndPlaceId={this.setMapInfo.bind(this)}
              />
              <small className="text-muted">
                * Búsqueda de 1Km a la redonda
              </small>
            </Collapse>
            <hr />
            <Label
              for="operationType"
              className="mt-2"
              onClick={this.toggleOrderBy.bind(this)}>
              {this.getCollapseSign(this.state.orderByOpen)}
              Ordenar por <small className="text-muted">(click para ver)</small>
            </Label>
            <Collapse isOpen={this.state.orderByOpen}>
              <FormGroup>
                <ButtonGroup id="operationType" className="w-100">
                  <Button
                    name="operationType"
                    className="fondoVerde text-white"
                    onClick={() =>
                      this.setAndSwapOrder('CREATED_ASC', 'CREATED_DESC')
                    }
                    active={orderByComponentInfo.created}>
                    {orderByComponentInfo.created && this.getOrderDirection()}
                    Fecha
                  </Button>
                  <Button
                    name="operationType"
                    className="fondoVerde text-white"
                    onClick={() =>
                      this.setAndSwapOrder('COMMISSION_ASC', 'COMMISSION_DESC')
                    }
                    active={orderByComponentInfo.commission}>
                    {orderByComponentInfo.commission &&
                      this.getOrderDirection()}
                    Comisión
                  </Button>
                  <Button
                    name="operationType"
                    className="fondoVerde text-white"
                    onClick={() =>
                      this.setAndSwapOrder('PRICE_ASC', 'PRICE_DESC')
                    }
                    active={orderByComponentInfo.price}>
                    {orderByComponentInfo.price && this.getOrderDirection()}
                    Precio
                  </Button>
                </ButtonGroup>
              </FormGroup>
            </Collapse>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                this.setFilter();
                this.open();
                this.setState({ applyFilter: true });
              }}
              className="fondoVerde">
              <FaSearch /> Buscar
            </Button>
            <Button
              onClick={() => {
                this.clear(true);
                this.setState({ applyFilter: false })
                setTimeout(this.setFilter.bind(this), 500);
              }}
              className="fondoRojo">
              <FaTrash /> Reiniciar
            </Button>
            <Button onClick={this.open.bind(this)}>Cancelar</Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

FiltrarPropiedades.propTypes = {
  setFilter: PropTypes.func.isRequired,
  storageId: PropTypes.string,
};

FiltrarPropiedades.defaultProps = {
  storageId: 'filter:propiedades',
};

export default FiltrarPropiedades;
