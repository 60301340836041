import React, { Component } from 'react';
import {
	Row,
	Col,
	Button,
	Carousel,
	CarouselItem,
	CarouselControl,
	CarouselIndicators,
} from 'reactstrap';
import FsLightbox from 'fslightbox-react';

function numberWithCommas(x) {
	x = Math.round(x).toString();
	var pattern = /(-?\d+)(\d{3})/;
	while (pattern.test(x)) x = x.replace(pattern, '$1,$2');
	return x;
}

class PropiedadAdmin extends Component {
	state = {
		activeIndex: 0,
		aceptado: false,
		id: `carousel-${this.props.property.id}`,
		modalIsOpen: false,
	};

	aceptado() {
		this.setState({ aceptado: !this.state.aceptado });
	}

	onExiting() {
		this.animating = true;
	}

	onExited() {
		this.animating = false;
	}

	next() {
		if (this.animating) return;
		const nextIndex =
			this.state.activeIndex === this.props.property.pictures.length - 1
				? 0
				: this.state.activeIndex + 1;
		this.setState({ activeIndex: nextIndex });
	}

	previous() {
		if (this.animating) return;
		const nextIndex =
			this.state.activeIndex === 0
				? this.props.property.pictures.length - 1
				: this.state.activeIndex - 1;
		this.setState({ activeIndex: nextIndex });
	}

	goToIndex(newIndex) {
		if (this.animating) return;
		this.setState({ activeIndex: newIndex });
	}

	render() {
		const { activeIndex } = this.state;
		const slides = this.props.property.pictures.map((foto, i) => {
			return (
				<CarouselItem
					onExiting={this.onExiting.bind(this)}
					onExited={this.onExited.bind(this)}
					key={`ci${i}`}>
					{i === 0 ? (
						<img
							async
							src={`${foto.url}`}
							id={`${this.state.id}-p${i}`}
							className="tfoto"
							alt={`i${i}`}
							onClick={e => {
								this.setState((state, props) => ({
									modalIsOpen: !state.modalIsOpen,
								}));
							}}
						/>
					) : (
						<img
							async
							data-src={`${foto.url}`}
							id={`${this.state.id}-p${i}`}
							className="tfoto"
							alt={`i${i}`}
							onClick={e => {
								this.setState((state, props) => ({
									modalIsOpen: !state.modalIsOpen,
								}));
							}}
						/>
					)}
				</CarouselItem>
			);
		});

		return (
			<React.Fragment>
				<FsLightbox
					slide={this.state.activeIndex}
					toggler={this.state.modalIsOpen}
					sources={this.props.property.pictures.map(pic => pic.url)}
				/>
				<div className="pabmediano centered2">
					<div className="fondoGrisClaro tpropiedad">
						<div className="negro parmuychico mayus tchica wbold">
							<Row>
								<Col className="centro" xs={4}>
									Puebla
								</Col>
								<Col className="centro sinpymiz">Ciudad de Puebla</Col>
							</Row>
						</div>
						<div className="fondoVerde blanco wbold parmuychico">
							<Row>
								<Col xs={7}>
									<Row>
										<Col xs={5} className="mayus derecha sinpymde">
											{this.props.property.operationType}
										</Col>
										<Col xs={7} className="centro sinpymlados">
											$
											{numberWithCommas(
												this.props.property.price * this.props.currency.factor,
											)}
											<span className="tmuychica">
												{this.props.currency.name}
											</span>
										</Col>
									</Row>
								</Col>
								<Col xs={5} className="izquierda sinpymiz sinpymde blanco">
									{this.props.property.operationType === 'Renta' ? (
										<div>
											<span className="tchica">Comisión:</span>{' '}
											{this.props.property.commission === '.5' ? (
												<span className="tchica">
													<sup>1</sup>&frasl;
													<sub>2</sub>
												</span>
											) : (
												<span className="tchica">
													{this.props.property.commission}
												</span>
											)}{' '}
											<span className="tchica">mes</span>
										</div>
									) : (
										<div>
											<span className="tchica">Comisión:</span>{' '}
											{this.props.property.commission}.5%
										</div>
									)}
								</Col>
							</Row>
						</div>
						<Carousel
							activeIndex={activeIndex}
							next={this.next.bind(this)}
							previous={this.previous.bind(this)}
							interval={false}>
							<CarouselIndicators
								items={this.props.property.pictures.map((foto, i) => ({
									key: foto.url,
								}))}
								activeIndex={activeIndex}
								onClickHandler={this.goToIndex.bind(this)}
							/>
							{slides}
							<CarouselControl
								direction="prev"
								directionText="Previous"
								onClickHandler={this.previous.bind(this)}
							/>
							<CarouselControl
								direction="next"
								directionText="Next"
								onClickHandler={this.next.bind(this)}
							/>
						</Carousel>
						<div className="pizmicro pdemicro">
							<div className="centro parmicro pabmuychico">
								<div className="wbold gris mayus tchica">
									{this.props.property.propertyType} -{' '}
									{this.props.property.zone}
								</div>
							</div>
							<Row className="tchica parmuychico">
								<Col xs={7}>
									<Row>
										<Col className="centro sinpymde">
											<div className="icon-regla-vacia d-inline" />{' '}
											{this.props.property.landMeters}
											<span className="minus">m</span>
											<sup>2</sup>
										</Col>
										<Col className="centro sinpymde">
											<div className="icon-construccion d-inline" />{' '}
											{this.props.property.constructionMeters}
											<span className="minus">m</span>
											<sup>2</sup>
										</Col>
									</Row>
								</Col>
								<Col className="izquierda sinpymde">
									<div className="icon-cama d-inline" />{' '}
									{this.props.property.bedrooms}
								</Col>
								<Col className="izquierda sinpymiz">
									<div className="icon-escusado d-inline" />{' '}
									{this.props.property.bathrooms}
								</Col>
							</Row>
							<div className="tchica parchico pabmuychico">
								{this.props.property.description}
							</div>
							<div className="centro bottomPropiedad">
								<Button className="botonRojo claseBoton3">
									<div className="parmicro pizmicro pdemicro">
										Adquirir contacto
									</div>
								</Button>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default PropiedadAdmin;
