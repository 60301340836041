import React, { Fragment, Component } from 'react';
import { Input, Label } from 'reactstrap';
import GoogleMapReact from 'google-map-react';
import Marker from './Marker';
import PropTypes from 'prop-types';

const apikey = 'AIzaSyCzL2mD8YKv2cFPPILLQ43FRQM5k0qqKnI';
const mapsUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:5000'
    : 'https://polar-temple-10437.herokuapp.com';

export default class MapsGoogleMaps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      center: [this.props.lat, this.props.lng],
      zoom: 9,
      draggable: true,
      lat: this.props.lat,
      lng: this.props.lng,
      destination: this.props.destination,
      predictions: [],
    };
  }
  onChangeDestination(destination) {
    this.setState({ destination });
    const autocompleteURL = `${mapsUrl}/maps/api/place/autocomplete/json?key=${apikey}&input=${destination}&location=${
      this.state.lat
    },${this.state.lng}&radius=2000`;
    try {
      fetch(autocompleteURL)
        .then(result => result.json())
        .then(json => {
          console.log(json);
          this.setState({ predictions: json.predictions });
        })
        .catch(console.error);
    } catch (err) {
      console.log('DestinationError', err);
    }
  }

  async onChangePin(a) {
    console.log('entra');
    const placeURL = `${mapsUrl}/maps/api/place/details/json?place_id=${a}&fields=geometry,address_component&key=${apikey}`;
    try {
      const result = await fetch(placeURL);
      const json = await result.json();
      console.log("Changerpin",json);
      console.log(json.result.geometry.location.lat);
      const lat = json.result.geometry.location.lat;
      const lng = json.result.geometry.location.lng;
      this.setState({ lat, lng });
      this.props.setLocation({ lat, lng });
      this.props.setOrigin(json.result.address_components);
      // this.setState({predictions: json.predictions});
            //
    } catch (err) {
      console.log('PinError', err);
    }
  }
  async _get_image(lat, lng) {
    const staticMapURL = `${mapsUrl}/maps/api/staticmap?center=${lat},${lng}&zoom=14&size=400x400&markers=color:green%7Clabel:C%7C${lat},${lng}&format=jpeg&key=${apikey}`;
    console.log('staticURL', staticMapURL);
    try {
      const result = await fetch(staticMapURL);
      console.log('MapImg', result);
      const json = await result.json();
    } catch (err) {
      console.log('MapImg', err);
    }
  }
  onTextPress(event, text, placeID) {
    console.log(event);
    this.setState({ destination: text });
    this.setState({ predictions: [] });
    console.log('text', text, 'PlaceId', placeID);
    this.props.setAddresAndPlaceId(text, placeID);
    this.onChangePin(placeID);
  }

  onCircleInteraction(childKey, childProps, mouse) {
    // function is just a stub to test callbacks
    this.setState({
      draggable: false,
      lat: mouse.lat,
      lng: mouse.lng,
    });

    console.log(
      'onCircleInteraction called with. LAT Y LON ',
      this.state.lat,
      this.state.lng,
    );
  }
  onCircleInteraction2(childKey, childProps, mouse) {
    // function is just a stub to test callbacks
    this.setState({
      draggable: false,
      lat: mouse.lat,
      lng: mouse.lng,
    });

    console.log(
      'onCircleInteraction2 called with. LAT Y LON ',
      this.state.lat,
      this.state.lng,
    );
  }
  onCircleInteraction3(childKey, childProps, mouse) {
    this.setState({ draggable: true });
  }
  _onChange = ({ center, zoom }) => {
    this.setState({
      center: center,
      zoom: zoom,
    });
  };

  async geocodeAddress(lat, lng) {
    const geocodeURL = `${mapsUrl}/maps/api/geocode/json?latlng=${lat},${lng}&key=${apikey}`;
    console.log('staticURL', geocodeURL);
    try {
      const result = await fetch(geocodeURL);
      console.log('Geocode', result);
      const { results } = await result.json();
      if (Array.isArray(results) && results.length > 0) {
        const first = results.shift();
        this.props.setAddresAndPlaceId(first.formatted_address, first.place_id);
        this.props.setOrigin(first.address_components);
        this.setState({ destination: first.formatted_address });
      }
    } catch (err) {
      console.log('Geocode err', err);
    }
  }
  render() {
    const predictions = this.state.predictions.map(prediction => (
      <Label
        style={{
          backgroundColor: 'white',
          padding: 5,
          margin: 0,
          fontSize: 18,
          width: '100%',
          border: '1px solid grey',
        }}
        key={prediction.id}
        onClick={e =>
          this.onTextPress(e, prediction.description, prediction.place_id)
        }>
        {' '}
        {prediction.description}
      </Label>
    ));
    return (
      <Fragment>
        <div>
          <Input
            style={{
              marginRight: 5,
              padding: 5,
              backgroundColor: 'white',
            }}
            placeholder="Calle. Ejem. Zócalo de Puebla"
            value={this.state.destination}
            onChange={({ target }) => this.onChangeDestination(target.value)}
          />
          {predictions}
        </div>
        <div className="w-100" style={{ height: '450px', marginTop: '20px' }}>
          <GoogleMapReact
            draggable={this.state.draggable}
            onChange={this._onChange}
            bootstrapURLKeys={{
              key: 'AIzaSyCzL2mD8YKv2cFPPILLQ43FRQM5k0qqKnI',
            }}
            defaultCenter={this.state.center}
            center={[this.props.lat, this.props.lng]}
            defaultZoom={this.state.zoom}
            onChildMouseDown={this.onCircleInteraction.bind(this)}
            onChildMouseUp={this.onCircleInteraction3.bind(this)}
            onChildMouseMove={this.onCircleInteraction2.bind(this)}
            onChildClick={() => {
              console.log('child click');
              console.log('LAT Y LON ', this.state.lat, this.state.lng);
              const { lat, lng } = this.state;
              this.props.setLocation({ lat, lng });
              this.geocodeAddress(lat, lng).then(() => {
                console.log('Premios');
              });
              this._get_image(lat, lng);
            }}
            onClick={() => console.log('mapClick')}>
            <Marker
              lat={this.state.lat}
              lng={this.state.lng}
              name="Aqui"
              color="red"
            />
          </GoogleMapReact>
        </div>
      </Fragment>
    );
  }
}

MapsGoogleMaps.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number,
  setOrigin: PropTypes.func,
  setAddresAndPlaceId: PropTypes.func,
};

MapsGoogleMaps.defaultProps = {
  lat: 19.0379295,
  lng: -98.2034607,
  setOrigin: origin => {},
  setAddresAndPlaceId: (address, placeId) => {},
};
