// @format
import React, { Component } from 'react';
import AuthContext from '../../../AuthContext';
import gql from 'graphql-tag';
import ContenidoPropiedad from './ContenidoPropiedad';
import * as yup from 'yup';

const propertyValidator = yup
  .object()
  .required()
  .shape({
    id: yup.number().nullable(),
    bathrooms: yup.number().when('propertyType', {
      is: val => val !== 'LAND',
      then: yup.number().nullable(),
      otherwise: yup.number().required('- Baños\n'),
    }),
    bedrooms: yup.number().when('propertyType', {
      is: val => val === 'HOUSE' || val === 'APPARTMENT',
      then: yup.number().required('- Habitaciones\n'),
      otherwise: yup.number().nullable(),
    }),
    address: yup
      .string()
      .min(1)
      .required(),
    placeId: yup
      .string()
      .min(1)
      .required(),
    commission: yup.number().required('- Commisión\n'),
    constructionMeters: yup.number().nullable(),
    origin: yup
      .object()
      .required()
      .shape({
        country: yup.string().required('- País\n'),
        state: yup.string().required('- Estado\n'),
        city: yup.string().required('- Ciudad\n'),
      }),
    description: yup.string().required('- Descripción \n'),
    operationType: yup
      .string()
      .required('- Tipo de operación (Compra ó Renta)\n'),
    propertyType: yup.string().required('- Tipo de propiedad\n'),
    pictures: yup.array().required(),
    price: yup
      .number()
      .typeError('- Precio\n')
      .required('- Precio\n'),
    squareMeters: yup
      .number()
      .typeError('- Metros cuadrados\n')
      .required('- Metros cuadrados\n'),
    zone: yup.string(),
  });

class SubirPropiedad extends Component {
  constructor(props) {
    super(props);
    this.zoneRef = React.createRef();
  }

  handleChange({ target }) {
    const { name, value, type } = target;
    let val = value;
    switch (type) {
      case 'number':
        val = parseFloat(value, 10);
        break;
      case 'select-one':
        val = target.selectedOptions[0].value;
        break;
      default:
        break;
    }
    this.setState((state, _) => ({
      [name]: val,
    }));
  }

  typesQuery = gql`
    {
      operationTypes
      propertyTypes
    }
  `;

  createPropertyMutation = gql`
    mutation($data: propertyCreateInput!) {
      createProperty(data: $data) {
        property {
          id
        }
      }
    }
  `;

  render() {
    return (
      <ContenidoPropiedad
        propertyValidator={propertyValidator}
        mutation={this.createPropertyMutation}
        tipo="Sube tu propiedad"
      />
    );
  }
}
SubirPropiedad.contextType = AuthContext;
export default SubirPropiedad;
