import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import CreditCart from './Credit_card';

const ModalExample = props => {
  return (
    <div>
      <Modal isOpen={props.open} toggle={props.toggle}>
        <div className="fondoPago parmuychico" toggle={props.toggle}>
          <div className="blanco wbold tgrande parmediano pabmediano centro">
            AGREGAR TARJETA
          </div>
        </div>
        <ModalBody>
          <CreditCart afterSave={props.toggle} />
        </ModalBody>
        <ModalFooter>
          <Button className="botonRojo" onClick={props.toggle}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalExample;
