//@format
import React, { PureComponent } from 'react';
import { Row, Col, Input, FormGroup, FormFeedback, Button } from 'reactstrap';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import styles from './Drop.module.css';

class Drop extends PureComponent {
  state = {
    invalid: false,
    fotos: [],
    reordenamientos: [],
  };
  open() {
    this.setState({ open: !this.state.open });
  }

  componentDidMount() {
    this.readers = this.props.imagenes
      .sort((a, b) => a.order - b.order)
      .map(img => ({
        id: img.id,
        ordenOriginal: img.order,
        reader: { result: img.url, readyState: 2 },
        orden: img.order,
      }));
    this.onRead();
  }

  readers = [];

  onDrop(acceptedFiles, _rejectedFiles) {
    let files = acceptedFiles.map((file, i) => {
      var fr = new FileReader();
      fr.onload = this.onRead.bind(this);
      fr.readAsDataURL(file);
      return {
        file,
        name: file.name,
        reader: fr,
        orden: this.readers.length + i,
      };
    });
    this.readers = this.readers.concat(files).sort((a, b) => a.orden - b.orden);

    this.props.setPictures(
      this.readers
        .filter(r => !r.id)
        .map(r => ({ order: r.orden, picture: r.file })),
    );
  }

  drop(e) {
    console.log('reorder drop');
    let data = parseInt(e.dataTransfer.getData('text'));
    let ownData = parseInt(e.target.dataset['index']);
    if (data === ownData) {
      return;
    }

    let primera = this.readers.findIndex(reader => reader.orden == data);
    let segunda = this.readers.findIndex(reader => reader.orden == ownData);
    this.readers[primera].orden = ownData;
    this.readers[segunda].orden = data;

    this.props.setPictures(
      this.readers
        .filter(r => !r.id)
        .sort((a, b) => a.orden - b.orden)
        .map(r => ({ order: r.orden, picture: r.file })),
    );
    console.log('why');
    this.props.setReordering(
      this.readers
        .filter(r => !!r.id)
        .sort((a, b) => a.orden - b.orden)
        .map(r => ({
          order: r.orden,
          id: typeof r.id === 'string' ? parseInt(r.id) : r.id,
        })),
    );
    this.onRead();
  }

  onRead() {
    console.log(this);
    let imagenes = [];
    this.readers.forEach(fr => {
      if (fr.reader.readyState === 2) {
        imagenes.push({ foto: fr.reader.result, orden: fr.orden });
      }
    });
    this.setState({ fotos: imagenes.sort((a, b) => a.orden - b.orden) });
  }

  dragStart(e) {
    e.dataTransfer.setData('text', e.target.dataset['index']);
  }

  dragEnd(_e) {}

  dragEnter(e) {
    e.target.classList.toggle('border');
    e.target.classList.toggle('border-warning');
  }

  dragLeave(e) {
    e.target.classList.toggle('border');
    e.target.classList.toggle('border-warning');
  }

  dragOver(e) {
    e.preventDefault();
  }

  removeImagen(e) {
    const index = this.readers.findIndex(
      fr => fr.reader.result === e.target.src,
    );
    if (index === -1) {
      return;
    }
    const temp = this.readers[index];
    this.readers.splice(index, 1);
    if (temp.id) {
      this.props.addDeletedPicture({ id: temp.id, url: temp.reader.result });
    } else {
      this.props.setPictures(
        this.readers
          .filter(r => !r.id)
          .sort((a, b) => a.orden - b.orden)
          .map(r => ({ order: r.orden, picture: r.file })),
      );
    }
    this.onRead();
  }

  render() {
    return (
      <React.Fragment>
        <FormGroup>
          <div className="parmediano">
            <div className="tchica">
              De click en el recuadro para subir sus fotos
            </div>
            <Dropzone
              id="fotos"
              name="fotos"
              accept="image/*"
              onDrop={this.onDrop.bind(this)}
            />
            <Input
              type="hidden"
              invalid={this.state.invalid && this.state.fotos.length === 0}
            />
            <FormFeedback>Debes de subir al menos una foto</FormFeedback>
          </div>
        </FormGroup>
        <Row className="pabmediano">
          {this.state.fotos
            .sort((a, b) => a.orden - b.orden)
            .map((imagen, i) => {
              return (
                <Col
                  className="pabmmediano centro"
                  key={i}
                  xs={6}
                  sm={4}
                  md={3}>
                  <img
                    draggable
                    src={imagen.foto}
                    data-index={imagen.orden}
                    alt="thumbnail"
                    className="img-thumbnail"
                    onClick={this.removeImagen.bind(this)}
                    onDragStart={this.dragStart.bind(this)}
                    onDragEnd={this.dragEnd.bind(this)}
                    onDragEnter={this.dragEnter.bind(this)}
                    onDragLeave={this.dragLeave.bind(this)}
                    onDragOver={this.dragOver.bind(this)}
                    onDrop={this.drop.bind(this)}
                  />
                </Col>
              );
            })}
        </Row>
        <Row>
          <Col>
            <small>
              Arrastre las imagenes para ordenarlas o presione una imagen para
              eliminarla
            </small>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

Drop.propTypes = {
  setPictures: PropTypes.func.isRequired,
  setReordering: PropTypes.func,
  addDeletedPicture: PropTypes.func,
  imagenes: PropTypes.array,
};

Drop.defaultProps = {
  imagenes: [],
  setReordering: param => {
    console.log(param);
  },
  addDeletedPicture: param => {},
};

export default Drop;
