import React, { PureComponent } from 'react';
import { Row, Col, Collapse } from 'reactstrap';
import { FaPlus, FaMinus } from 'react-icons/fa';

class Pregunta extends PureComponent {
	state = {
		activo: false,
	};

	activo() {
		this.setState({ activo: !this.state.activo });
	}

	render() {
		return (
			<React.Fragment>
				<Row
					className="negro izquierda tletrapregunta"
					onClick={this.activo.bind(this)}>
					{!this.state.activo && (
						<Col className="derecha">
							<FaPlus className="rojo" />
						</Col>
					)}
					{this.state.activo && (
						<Col className="derecha">
							<FaMinus className="rojo" />
						</Col>
					)}
					<Col xs={10} md={11} className="sinpymiz">
						{this.props.pregunta}
					</Col>
				</Row>
				<Collapse
					className="rojo izquierda trespuesta"
					isOpen={this.state.activo}>
					<Row>
						<Col
							xs={{ size: 10, offset: 2 }}
							md={{ size: 11, offset: 1 }}
							className="sinpymiz pdegrande">
							<span className="rojo">- </span>
							{this.props.children}
						</Col>
					</Row>
				</Collapse>
			</React.Fragment>
		);
	}
}

export default Pregunta;
