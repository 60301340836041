import React, { useState, useContext } from 'react';
import { Row, Col, Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import BotonWhatsapp from './BotonWhatsapp';
import { FaStar, FaRegStar } from 'react-icons/fa';
import swal from 'sweetalert';
import AuthContext from '../../../../AuthContext';

const sp = {
  HOUSE: 'Casa',
  APPARTMENT: 'Departamento',
  LAND: 'Terreno',
  OFFICE: 'Oficina',
  SHOP: 'Local',
  MEDICAL: 'Consultorio',
  STORAGE: 'Bodega',
  FACILITY: 'Nave industrial',
  BUILDING: 'Edificio',
  RANCH: 'Finca',
};

function ContactarBoton(props) {
  return (
    <Button
      onClick={props.onClick}
      className={`${props.ID ? 'botonAzul' : 'botonRojo'} claseBoton3`}>
      <div
        className={`parmicro pizchico pdemuychico ${
          props.ID ? 'botonContactarID' : 'botonContactar'
        }`}>
        <div className="pizchico">
          <Row>
            <Col xs={3} className="pizmediano sinpymde derecha">
              <div className="d-inline icon-id-3 tgrande" />
            </Col>
            <Col xs={9} className="pizchico parmicro1 izquierda">
              Contactar
            </Col>
          </Row>
        </div>
      </div>
    </Button>
  );
}

function ContactarModal(props) {
  return (
    <Modal
      className="pladosresponsivo parepico"
      isOpen={props.contactar}
      toggle={() => props.setContactar(!props.contactar)}>
      <ModalBody>
        <div className="wbold centro pabchico">
          {`${props.property.advertiser.firstName} ${
            props.property.advertiser.lastName
          } `}
        </div>
        {props.auth.user.agency ? (
          <Row className="justify-content-center">
            <Col className="text-center">{props.auth.user.agency.name}</Col>
          </Row>
        ) : null}
        <Row className="justify-content-center">
          <Col className="text-center">
            <span style={{ fontSize: '12px', color: 'black' }}>
              {(() => {
                const stars = [];
                for (let i = 0; i < 5; i++) {
                  i < props.auth.user.rating
                    ? stars.push(
                        <FaStar
                          style={{
                            paddingTop: 0,
                            marginLeft: 2,
                            marginRight: 2,
                            fontSize: '13px',
                            color: '#008742',
                          }}
                        />,
                      )
                    : stars.push(
                        <FaRegStar
                          style={{
                            paddingTop: 0,
                            marginLeft: 2,
                            marginRight: 2,
                            fontSize: '13px',
                            color: '#008742',
                          }}
                        />,
                      );
                }

                return stars;
              })()}
            </span>
          </Col>
        </Row>

        <Row>
          {/*chica*/}
          <Col className="centro d-block d-sm-none">
            <Button
              href={`tel:${props.property.advertiser.phone}`}
              className="botonGris pabmicro botonContactarTelefono">
              <div className="d-inline icon-telefono" />
            </Button>
            <div className="d-inline pizchico">
              <BotonWhatsapp
                property={props.property}
                auth={props.auth}
                chico
              />
            </div>
          </Col>
          {/*mediana*/}
          <Col className="centro d-none d-sm-block">
            <Button
              disabled={!props.property.advertiser.phone}
              onClick={() =>
                swal('Numero de contacto', `${props.property.advertiser.phone}`)
              }
              className="botonGris pabmicro">
              <div className="d-inline icon-telefono" />
            </Button>
            <div className="d-inline pizmediano">
              <BotonWhatsapp property={props.property} auth={props.auth} />
            </div>
          </Col>
        </Row>
        <div className=" parchico centro">
          <Button
            onClick={() => props.setContactar(!props.contactar)}
            className="botonRojo botonCancelar">
            CANCELAR
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default function ComponenteContactar(props) {
  const [contactar, setContactar] = useState(false);
  const ctx = useContext(AuthContext);
  let isVerified = false;
  if (props.demo) {
    isVerified = true;
  } else {
    isVerified = ctx.user.verified;
  }

  const buttonClick = isVerified
    ? () => setContactar(!contactar)
    : () =>
        swal(
          'Verificando',
          'Estamos verificando tu cuenta, pronto podrás contactar a otros inmobiliarios, gracias por tu paciencia',
          'info',
        );
  return (
    <React.Fragment>
      {ContactarBoton({ onClick: buttonClick })}
      {isVerified && ContactarModal({ ...props, setContactar, contactar })}
    </React.Fragment>
  );
}
