import React, { Component } from 'react';
import AuthContext from '../../../../../AuthContext';
import { Button } from 'reactstrap';
import ContenidoLead from './ContenidoLead';
import Presupuesto from './Presupuesto';
import { Mutation } from '@apollo/react-components';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
function numberWithCommas(x) {
  x = Math.round(x).toString();
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x)) x = x.replace(pattern, '$1,$2');
  return x;
}

class Lead extends Component {
  state = { aceptado: false, normal: false };

  aceptado() {
    this.setState({ aceptado: !this.state.aceptado });
  }
  normal() {
    this.setState({ normal: !this.state.normal });
  }
  render() {
    return (
      <React.Fragment>
        {!this.props.lead.accepted && (
          <div className="fondoBlanco sombraTodo contenedor3 heightLead">
            <div className="pmediano">
              <div className="fondoAzul blanco wbold tprecio parmuychico pabmicro borderPrecio">
                Precio: ${numberWithCommas(this.props.lead.price)}{' '}
                <span className="tchica">{this.props.currency.name}</span>
              </div>
              <Presupuesto
                lead={this.props.lead}
                currency={this.props.currency}
              />
              {!this.state.aceptado && <ContenidoLead lead={this.props.lead} />}
              {!this.props.carrito && !this.state.aceptado && (
                <Mutation
                  mutation={gql`
                    mutation($data: addToCartInput!) {
                      addToCart(data: $data)
                    }
                  `}>
                  {(addToCart, { loading }) => (
                    <React.Fragment>
                      {!this.props.carrito && (
                        <div className="bottom centro parchico pabmediano">
                          <Button
                            disabled={loading}
                            onClick={() => {
                              addToCart({
                                variables: {
                                  data: {
                                    itemId: parseInt(this.props.lead.id, 10),
                                  },
                                },
                              }).then(data => this.aceptado());
                            }}
                            className="botonComprar">
                            COMPRAR
                          </Button>
                        </div>
                      )}
                    </React.Fragment>
                  )}
                </Mutation>
              )}
              <React.Fragment>
                {(this.state.aceptado || this.props.carrito) && (
                  <div className="bottom centro parchico pabmediano">
                    <Button
                      onClick={this.aceptado.bind(this)}
                      className="botonEliminarCarrito">
                      ELIMINAR DEL CARRITO
                    </Button>
                  </div>
                )}
              </React.Fragment>
              {this.state.aceptado && !this.props.carrito && (
                <React.Fragment>
                  <div className="gris centro wbold parenorme">
                    Felicidades, este cliente ya casi es tuyo da{' '}
                    <Link to="/carrito" className="subrayado linkAzul2">
                      click aqui para pagarlo
                    </Link>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
Lead.contextType = AuthContext;
export default Lead;
