// @format
import React, { Component, useContext } from 'react';
import AuthContext from '../../../AuthContext';
import { Row, Col, Button, Spinner } from 'reactstrap';
import Propiedad from './propiedad/Propiedad';
import FiltrarPropiedades from './FiltrarPropiedades';
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';

function numberWithCommas(x) {
  x = Math.round(x).toString();
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x)) x = x.replace(pattern, '$1,$2');
  return x;
}

class MisPropiedades extends Component {
  constructor(props) {
    super(props);
    this.loadFilter();
    props.history.listen(() => this.loadFilter());
  }
  loadFilter() {
    try {
      const session = sessionStorage.getItem('filter:mis-propiedades');
      if (session) {
        const sessionData = JSON.parse(session);
        const { orderBy, ...filter } = sessionData;
        this.filter = filter;
        this.orderBy = orderBy;
      }
    } catch (err) {
      console.error(err);
    }
  }
  state = {
    open: false,
    leads: [],
    show: 0,
    busqueda: '',
    country: '',
    state: '',
    city: '',
    skip: 0,
  };

  user = {
    id: '23489',
    balance: '0',
    currency: { name: 'MXN', factor: 1 },
    budgets: [
      {
        origin: {
          country: 'México',
          state: 'Puebla',
          city: 'Puebla',
          clientValue: '100',
        },
        amount: '1900',
      },
      {
        origin: {
          country: 'México',
          state: 'Puebla',
          city: 'Cholula',
          clientValue: '100',
        },
        amount: '4000',
      },
    ],
  };

  propertySubscription = gql`
    subscription {
      propertyEvent {
        event
        property {
          id
          accepted
          reviewed
          advertiserId
          active
          propertyType
          operationType
          squareMeters
          constructionMeters
          zone
          bedrooms
          bathrooms
          furnished
          commission
          pictures {
            id
            order
            url
          }
          description
          price
          address
          placeId
          dueDate
          origin {
            country
            state
            city
          }
          datasheet
          location {
            latitude
            longitude
          }
        }
      }
    }
  `;

  propertiesQuery = gql`
    query($filter: propertyWhereInput, $skip: Int, $take: Int) {
      getUser {
        user {
          id
          posts(filter: $filter, skip: $skip, take: $take) {
            id
            active
            accepted
            reviewed
            propertyType
            operationType
            squareMeters
            constructionMeters
            zone
            bedrooms
            bathrooms
            furnished
            commission
            pictures {
              id
              order
              url
            }
            description
            price
            dueDate
            address
            placeId
            origin {
              country
              state
              city
            }
            datasheet
            location {
              latitude
              longitude
            }
          }
          postCount(filter: $filter, skip: $skip, take: $take)
        }
      }
    }
  `;
  take = 24;
  dummy = true;
  refetch = null;
  isSubscribed = false;

  _subscribeToProperty = subscribeToMore => {
    subscribeToMore({
      document: this.propertySubscription,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const isOwn =
          prev.getUser.user.id ==
          subscriptionData.data.propertyEvent.property.advertiserId;
        if (isOwn) {
          switch (subscriptionData.data.propertyEvent.event) {
            case 'DELETED':
              prev.getUser.user.posts = prev.getUser.user.posts.filter(
                prop =>
                  prop.id !== subscriptionData.data.propertyEvent.property.id,
              );
              setTimeout(
                () =>
                  this.setState(state => ({
                    dummy: !state.dummy,
                  })),
                500,
              );
              return Object.assign({}, prev);
            case 'DEACTIVATED':
            case 'UPDATED':
            case 'COMPLETED':
              prev.getUser.user.posts.map(prop => {
                if (
                  prop.id === subscriptionData.data.propertyEvent.property.id
                ) {
                  return subscriptionData.data.propertyEvent.property;
                } else {
                  return prop;
                }
              });
              return { ...prev };
            default:
              console.log(
                'MIS PROPIEDADES: ',
                subscriptionData.data.propertyEvent.event,
              );
              return { ...prev };
          }
        }
      },
    });
  };
  setFilter = filter => {
    if (filter.origin) {
      Object.keys(filter.origin).forEach(
        key => !filter.origin[key] && delete filter.origin[key],
      );
    }
    Object.keys(filter).forEach(key => !filter[key] && delete filter[key]);
    this.setState({ filter });
  };

  render() {
    console.log(this.context);
    return (
      <Query
        query={this.propertiesQuery}
        variables={{
          take: this.take,
          skip: 0,
          filter: this.filter,
          orderBy: this.orderBy,
        }}
        onCompleted={data => {
          this.setState(state => ({
            skip: state.skip + this.take,
          }));
        }}
        fetchPolicy="cache-and-network">
        {({ data, loading, error, fetchMore, subscribeToMore, refetch }) => {
          if (loading && !(data && data.getUser && data.getUser.user))
            return (
              <div className="parmuygrande centro">
                <div className="verde wbold pmuychico centro tmuygrande">
                  Cargando tus propiedades
                </div>
                <Spinner size="lg" color="success" />
              </div>
            );
          if (error) return <p>{error.message}</p>;
          if (!this.isSubscribed) {
            this._subscribeToProperty(subscribeToMore);
            this.isSubscribed = true;
          }
          const properties = data.getUser.user.posts;
          if (!this.refetch) {
            this.refetch = refetch;
          }
          return (
            <React.Fragment>
              {properties.length === 0 && !this.filter && (
                <div className="pargrande">
                  <div className="centro parmuygrande pabmuychico">
                    <div className="sombraTodo tamanoAun centradoLead fondoBlanco">
                      <div className="centro tmuygrande pchico wbold gris">
                        Aún no has publicado propiedades
                      </div>{' '}
                      
                      <div className="pmediano centro add-property" style={{margin: 15, borderRadius: 20}}>
                        
                        <Button
                          tag={Link}
                          to="/subir-propiedad"
                          className="wbold parmediano pabchico pizmediano pdemediano tmediana botonVerde" style={{borderRadius: 18}}>
                            <div className="tgrande wbold centro text-white" style={{fontWeight: 'bold'}}>
                              <b style={{ fontSize: 25 }}>Publica tu primera propiedad</b>
                            </div>
                          <div className="icon-nueva-propiedad" style={{ fontSize: 60 }} />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <React.Fragment>
                {(properties.length !== 0 || this.filter) && (
                  <React.Fragment>
                    {/*Vista corredor muestra*/}
                    <div className="centro parmuygrande">
                      <div className="pabmuychico">
                        <Row className="pabgrande parmuychico">
                          <Col
                            xs={{ size: 12 }}
                            md={{ size: 10, offset: 1 }}
                            lg={{ size: 8, offset: 2 }}>
                            <div className="parmuychico">
                              <Link to="/subir-propiedad">
                                <div className="centrado">
                                  <div className="botonVerde wbold pchico botonVerMasPropiedades">
                                    <Row>
                                      <Col xs={3} className="derecha">
                                        <div className="parmicro2 icon-nueva-propiedad tmuygrande pdemuychico" />
                                      </Col>
                                      <Col
                                        xs={9}
                                        className="sinpymlados izquierda parmicro3">
                                        Publica tus propiedades
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </Col>
                          <Col>
                            <FiltrarPropiedades
                              storageId="filter:mis-propiedades"
                              setFilter={({ filter, orderBy }) => {
                                this.filter = filter;
                                this.orderBy = orderBy;
                                if (this.refetch) {
                                  this.refetch({
                                    skip: 0,
                                    take: this.take,
                                    filter,
                                    orderBy,
                                  });
                                }
                                this.setState({ skip: 0 });
                              }}
                            />
                          </Col>
                        </Row>
                        <div className="paddingPropiedades">
                          <Row className="parmediano">
                            {loading && <Spinner />}
                            {properties !== null && (
                              <React.Fragment>
                                {properties.map(property => (
                                  <React.Fragment>
                                    <Col
                                      xs={12}
                                      md={6}
                                      lg={4}
                                      xl={3}
                                      className="contenedor alturaMiPropiedad">
                                      <Propiedad
                                        auth={useContext(AuthContext)}
                                        miPropiedad
                                        {...this.props}
                                        property={property}
                                        currency={this.user.currency}
                                      />
                                    </Col>
                                  </React.Fragment>
                                ))}
                              </React.Fragment>
                            )}
                          </Row>
                          {loading && (
                            <Row>
                              <Col>
                                <Spinner className="verde" />
                              </Col>
                            </Row>
                          )}
                          <Row>
                            <Col>
                              {(properties !== null && properties.length > 1) && <Button
                                className="botonGris claseBoton2 botonVerMasPropiedades"
                                onClick={() => {
                                  if (
                                    data.getUser.user.postCount >
                                    properties.length
                                  ) {
                                    console.log("0'000000000000", properties);
                                    fetchMore({
                                      variables: {
                                        skip: this.state.skip,
                                        take: this.take,
                                        filter: this.filter,
                                        orderBy: this.orderBy,
                                      },
                                      updateQuery: (
                                        prev,
                                        { fetchMoreResult },
                                      ) => {
                                        if (!fetchMoreResult) return prev;
                                        const {
                                          user: { posts: newPosts },
                                        } = fetchMoreResult.getUser;
                                        prev.getUser.user.posts = prev.getUser.user.posts.concat(
                                          newPosts,
                                        );
                                        const retval = Object.assign({}, prev);
                                        return retval;
                                      },
                                    });
                                  }
                                }}
                                disabled={loading}>
                                Ver más
                              </Button>}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </React.Fragment>
            </React.Fragment>
          );
        }}
      </Query>
    );
  }
}
MisPropiedades.contextType = AuthContext;
export default MisPropiedades;
