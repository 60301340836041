import React, { Component, AsyncStorage } from 'react';
import { Button } from 'reactstrap';
import { Mutation } from '@apollo/react-components';
import Swal from 'sweetalert2';

const authUrl =
  process.env.NODE_ENV === 'development'
    ? `${process.env.HTTPS ? 'https' : 'http'}://localhost:4000`
    : 'https://graph.client.mx';

export default class BtnUpdateUser extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="centro parchico">
        {!this.props.changePassword && (
          <Mutation
            mutation={this.props.mutation}
            onCompleted={() => {
              Swal.fire(
                '¡ÉXITO!',
                'Cambios realizados exitosamente',
                'success',
              );
              this.props.setCollapse(false);
            }}>
            {updateUser => (
              <Button
                className="botonVerde tBotonActualizar"
                onClick={() => {
                  updateUser({ variables: { data: this.props.data } });
                }}>
                ACTUALIZAR
              </Button>
            )}
          </Mutation>
        )}
        {this.props.changePassword && (
          <Button
            className="botonVerde tBotonActualizar"
            onClick={() => {
              fetch(`${authUrl}/auth/local/validationResetPassword`, {
                method: 'post',
                credentials: 'include',
                body: JSON.stringify({
                  newPassword: this.props.newPassword,
                  password: this.props.password,
                }),
                headers: {
                  'Content-Type': 'application/json',
                },
              })
                .then(res => res.json())
                .then(result => {
                  if (result.success) {
                    Swal.fire(
                      '¡ÉXITO!',
                      'La contraseña ha sido cambiada exitosamente',
                      'success',
                    );
                    this.props.setCollapse(false);
                  } else {
                    if (result.reason) {
                      Swal.fire('¡ERROR!', 'Contraseña incorrecta', 'error');
                    }
                  }
                })
                .catch(error => {
                  Swal.fire('¡ERROR!', 'error');
                });
            }}>
            ACTUALIZAR
          </Button>
        )}
      </div>
    );
  }
}
