import React, { PureComponent } from 'react';
import { Row, Col, Card, Button } from 'reactstrap';

import Frame from '../frame/Frame';
import { FaChevronCircleDown } from 'react-icons/fa';

class Seccion1 extends PureComponent {
	render() {
		return (
			<Frame
				id={this.props.id}
				clase="botonBlanco3"
				className="hPage section fondo fondoBlanco">
				{/*TODO ocultar esta vista de desarrollo*/}
				{/*<Desarrollo />*/}
				<Col md={1} className="d-none d-md-block" />
				<section>
					<Row>
						<Col xs={12} id="cardInicio">
							<Card className="pabmuygrande transparente alturaCardInicio2">
								{!this.props.soloPropiedades ? (
									<span className="pabchico textoInicio wbold negro">
										¿Buscas clientes para tu inmobiliaria?
									</span>
								) : (
									<span className="pabchico textoInicio2 wbold negro">
										¡Únete a la red inmobiliaria más grande!
									</span>
								)}
								<div className="parchico">
									<Button
										href="/iniciar-sesion#registrate"
										className="botonRojo botonInicio parchico pizgrande pdegrande">
										¡Registrate aquí!
									</Button>
								</div>
							</Card>
						</Col>
					</Row>
					<div className="centro tenorme naveg4">
						<FaChevronCircleDown
							onClick={() => this.props.fullpageApi.moveSectionDown()}
							className="chevronInicio"
						/>
					</div>
				</section>
				<Col md={1} className="d-none d-md-block" />
			</Frame>
		);
	}
}

export default Seccion1;
