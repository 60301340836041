import React, { PureComponent } from 'react';
import { slide as Burguer } from 'react-burger-menu';
import { NavLink, withRouter } from 'react-router-dom';
import { Navbar, NavbarBrand } from 'reactstrap';
import './Menu.css';
import LogoVerde from '../imgs/logoVerde.png'

class Menu extends PureComponent {
  state = {
    isOpen: false,
  };

  handleStateChange (state) {
    this.setState({isOpen: state.isOpen})  
  }
  closeMenu () {
    this.setState({isOpen: false})
  }
  toggleMenu () {
    this.setState(state => ({isOpen: !state.isOpen}))
  }

  componentDidUpdate(prevProps, prevState) {
    
    if (prevState.isOpen !== this.state.isOpen) {
      if (this.state.isOpen) {
        document.body.setAttribute("style", "overflow: hidden; position: static;");
      } else {
        document.body.setAttribute("style", "overflow: visible; position: static;");
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <Navbar
          light
          expand={this.props.inicio ? 'lg' : 'md'}
          fixed={this.props.fixed || ''}
          className={this.props.color}>
          <NavbarBrand href={this.props.match.path}>
            {!this.props.logotipo && (
              <img
                src={
                  LogoVerde
                }
                className="mercadologo"
                alt="logo de client"
              />
            )}
            {this.props.logotipo && (
              <img
                src={this.props.logotipo}
                className="mercadologo"
                alt="logo de client"
              />
            )}
          </NavbarBrand>
          {this.props.children}
        </Navbar>
        {this.props.styles && (
          <Burguer
            right
            isOpen={this.state.menuOpen}
            onStateChange={(state) => this.handleStateChange(state)}
            styles={this.props.styles|| {}}
            >
            {this.props.rutas.map((ruta, i) => (
              <div key={`ruta-${i}`} className=" tgrande wbold pabchico">
                {ruta.componente && ruta.componente}
                {!ruta.componente && (
                  <NavLink
                    key={ruta.name}       
                    to={ruta.path}>
                    {ruta.name}
                  </NavLink>
                )}
              </div>
            ))}
          </Burguer>
        )}
      </React.Fragment>
    );
  }
}
export default withRouter(Menu);
