import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { NavLink, NavbarToggler, Nav, NavItem, Collapse } from 'reactstrap';
import Menu from '../Menu';
import Seccion1 from './seccion1Inicio/Seccion1';
import Seccion2 from './seccion2MejorOpcion/Seccion2';
import Seccion3 from './seccion3Explicacion/Seccion3';
import Seccion4 from './seccion4Preguntas/Seccion4';
import Seccion5 from './seccion5Contacto/Seccion5';
import ModalPrimeraVisita from './ModalPrimeraVisita';
import ReactFullpage from '@fullpage/react-fullpage';
import anime from 'animejs/lib/anime.es.js';
import styles from './Inicio.module.css';
import LogoVerde from '../imgs/logoVerde.png'

function sweepIn(origin, destination, direction) {
  if (destination.index === 0) {
    anime({
      targets: '.fadeIn2',
      opacity: 1,
      scale: 1.2,
      duration: 800,
    });

    anime({
      targets: '.fadeIn3',
      opacity: 1,
      scale: 1.2,
      duration: 800,
      delay: 800,
    });

    anime({
      targets: '.fadeIn4',
      opacity: 1,
      scale: 1.2,
      delay: 1600,
    });

    anime({
      targets: '.fadeIn5',
      opacity: 1,
      scale: 1.2,
      duration: 800,
      delay: 2400,
    });

    anime({
      targets: '.fadeIn6',
      opacity: 1,
      scale: 1.2,
      duration: 800,
      delay: 3000,
    });
  }
}

export default props => {
  useEffect(() => window.scrollTo(0, 1), []);
  const [toggle, Toggle] = useState(false);
  return (
    <React.Fragment>
      <div style={{ minHeight: '91px' }} />
      <Menu
        color="fondoBlanco"
        src={LogoVerde}
        fixed="top"
        inicio>
        <NavbarToggler onClick={() => Toggle(!toggle)} />
        <Collapse isOpen={toggle} onClick={() => Toggle(!toggle)} navbar>
          <Nav className="ml-auto" navbar id="navnav ">
            <NavItem data-menuanchor="inicio">
              <NavLink className="navHighlight linkInicio mayus" href="#inicio">
                Inicio
              </NavLink>
            </NavItem>
            <NavItem data-menuanchor="tu-mejor-opcion">
              <NavLink
                className="navHighlight linkInicio mayus"
                href="#tu-mejor-opcion">
                Tu mejor opción
              </NavLink>
            </NavItem>
            <NavItem data-menuanchor="quienes-somos">
              <NavLink
                className="navHighlight linkInicio mayus"
                href="#quienes-somos">
                CLIENT
              </NavLink>
            </NavItem>
            <NavItem data-menuanchor="preguntas-frecuentes">
              <NavLink
                className="navHighlight linkInicio mayus d-block d-md-none"
                href="#preguntas-frecuentes">
                Preguntas frecuentes
              </NavLink>
              <NavLink
                className="navHighlight linkInicio mayus d-none d-md-block"
                href="#preguntas-frecuentes">
                FAQ
              </NavLink>
            </NavItem>
            <NavItem data-menuanchor="contacto">
              <NavLink
                className="navHighlight linkInicio mayus"
                href="#contacto">
                Contacto
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink>
                <div className=" bordeLogin pizchico mayus pdechico verde">
                  <a
                    className={`wbold verde ${styles.iniciar_sesion}`}
                    href="/iniciar-sesion">
                    Iniciar sesión
                  </a>
                </div>
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Menu>{' '}
      <ReactFullpage
        licenseKey="23D069B2-6B424173-ADAD6D62-6CB56498"
        controlArrows={true}
        anchors={[
          'inicio',
          'tu-mejor-opcion',
          'quienes-somos',
          'preguntas-frecuentes',
          'contacto',
        ]}
        slideSelector=".fpage-slide"
        afterLoad={sweepIn}
        keyboardScrolling={true}
        menu="#navnav"
        render={({ state, fullpageApi }) => {
          return (
            <ReactFullpage.Wrapper>
              <Seccion1 soloPropiedades fullpageApi={fullpageApi} />
              <Seccion2 soloPropiedades />
              <Seccion3 soloPropiedades />
              <Seccion4 soloPropiedades />
              <Seccion5 soloPropiedades />
            </ReactFullpage.Wrapper>
          );
        }}
      />
    </React.Fragment>
  );
};
