import React from 'react';

export default class Dummy extends React.PureComponent {
  componentDidMount() {
    this.props.exec();
  }
  render() {
    return null;
  }
}
