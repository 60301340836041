import React, { PureComponent } from 'react';
import { Row, Col, Card, Button } from 'reactstrap';
import { FaUser } from 'react-icons/fa';

class CardCliente extends PureComponent {
	state = {
		aceptado: false,
		rechazado: false,
	};

	render() {
		return (
			<React.Fragment>
				<div className="tchica">
					<Card className="pchico negro">
						<React.Fragment>
							{!this.state.rechazado && (
								<React.Fragment>
									<span className="centro">
										<FaUser className="tgrande" />
									</span>

									{this.state.aceptado && (
										<div className="parchico centro">
											<Card className="fondoAzulClaro azul wbold parmicro">
												¡Felicidades! Has aceptado a tu primer cliente su
												teléfono es 2227067092, esperamos cierres la operación.
											</Card>
										</div>
									)}

									{!this.state.aceptado && (
										<React.Fragment>
											<div className="centro parchico pabchico pizladoschico">
												<span className="wbold">Pedro Sánchez</span> busca una{' '}
												<span className="wbold">casa</span> en{' '}
												<span className="wbold">renta</span> en el
												fraccionamiento "La Alcantara" en la colonia
												"Cantabres".
											</div>
											<div className="pizmediano pdemediano">
												<Card className="fondoGrisClaro negro wbold centro tgrande pchico">
													Presupuesto: $25,000
												</Card>
											</div>
										</React.Fragment>
									)}
									<Row className="parchico">
										<Col className="derecha">
											<Button
												onClick={() =>
													this.setState({
														aceptado: true,
													})
												}
												className="botonVerde claseBoton2">
												<div className="parmuychico">Aceptar</div>
											</Button>
										</Col>
										<Col className="izquierda">
											<Button
												onClick={() => {
													alert('+ $50');
													this.setState({
														rechazado: true,
													});
												}}
												className="botonRojo claseBoton2">
												<div className="parmuychico">Rechazar</div>
											</Button>
										</Col>
									</Row>
								</React.Fragment>
							)}
							{this.state.rechazado && (
								<div className="pmediano verde wbold centro tclientemuestra">
									Pronto tendremos más clientes para ti, queremos que generes
									dinero desde la primera semana con nosotros.
								</div>
							)}
						</React.Fragment>
					</Card>
				</div>
			</React.Fragment>
		);
	}
}

export default CardCliente;
