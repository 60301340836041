import React, { PureComponent } from 'react';
import { Row, Col } from 'reactstrap';
import Frame from '../frame/Frame';
import Pregunta from './Pregunta';

class Seccion4 extends PureComponent {
	state = {
		aceptado: false,
	};

	render() {
		return (
			<React.Fragment>
				<Frame
					id={this.props.id}
					className="fondoBlanco hpage section fondo4"
					clase="botonAmarillo"
					padding="sinpymde">
					<section className="parenorme blanco contenedor2">
						<Row className="areaOpacityClient2 centered2">
							<Col className="wbold letraClient">
								<div className="parmediano">
									<div className="letraPreguntasClient izquierda">
										Preguntas frecuentes
									</div>
								</div>
							</Col>
						</Row>
						{!this.props.soloPropiedades ? (
							<React.Fragment>
								<div className="bordeRojoPreguntas pizmediano pabmediano">
									<Pregunta pregunta="¿Cómo le hago para ganar dinero con Client?">
										Tenemos principalmente 3 maneras: <br />
										<div className="pizmediano">
											1.- Comprando clientes y consiguiendo lo que necesitan.{' '}
											<br />
											2.- Publicando tus propiedades y generando ventas en
											equipo con otras inmobiliarias. <br />
											3.- Accediendo a la cartera de propiedades COMISIONABLE
											más grande en el mercado.
										</div>
									</Pregunta>
									<Pregunta pregunta="¿Cobran comisión de ventas?">
										¡No! Nosotros te entregamos los clientes para que tu ganes
										toda la comisión.
									</Pregunta>
									<Pregunta pregunta="¿Cómo me conseguirán clientes?">
										Invertimos en publicidad efectiva y dirigida a este mercado.
										Los clientes nos dejan sus datos e información sobre que
										buscan y nosotros te los entregamos para que tu cierres la
										venta.
									</Pregunta>
									<Pregunta pregunta="¿Cómo compro clientes?">
										Es muy simple, primero registrate, después elige la cantidad
										de clientes que deseas, ingresa un metodo de pago (o ponte
										en contacto con nostros). ¡Y listo!, comenzaras a recibir
										clientes, el plazo máximo que tenemos para encontrartelos
										son 30 días. Ten en cuenta que entre más clientes compres
										mayor será tu utilidad. Es tan barato que con tan sólo
										invertir $10,000 pesos obtendras 100 clientes, con una sola
										renta recuperaras tu inversión.
									</Pregunta>
									<Pregunta pregunta="¿A cuántas personas les venden el mismo cliente?">
										Solo a una, el cliente es únicamente del que lo compra.
									</Pregunta>
									<Pregunta pregunta="¿Tiene costo?">
										Siempre te regalaremos un espacio para que publiques una
										propiedad. Sabemos que nuestra plataforma da resultados que
										te invitamos a que pruebes lo que esta puede hacer por ti.
										Es la plataforma precio rendimientos más barata y efectiva
										en el mercado.
									</Pregunta>
									<Pregunta pregunta="¿Cuánto vale un cliente?">
										El cliente tiene precio dependiendo de la zona donde te
										encuentras (generalmente alrededor de $100).
									</Pregunta>
									<Pregunta pregunta="Vendí la casa que tengo publicada, ¿Puedo publicar otra gratis en su lugar?">
										¡Sí!, dala de baja y publica una nueva totalmente gratis.
									</Pregunta>
									<Pregunta pregunta="¿Tienen gasto mínimo?">
										No, siempre podrás publicar una propiedad gratis. Sin
										embargo, nosotros para conseguirte algunos servicios
										necesitamos invertir dinero, por ello es que algunos de
										nuestros servicios tienen costo. Nuestra plataforma esta
										hecha de tal manera que entre más inviertas, más clientes y
										ventas tendrás.
									</Pregunta>
									<Pregunta pregunta="¿Cuánto tardan en asignarme clientes?">
										Generalmente será hasta el día siguiente que procesemos tu
										pago. Los clientes se asignan conforme vayan llegando así
										que deberán ser pacientes, nuestro plazo máximo son 30 días
										desde el día en que estos fueron requeridos. Recuerda
										buscamos los mejores clientes para que tu generes dinero.
									</Pregunta>
									<Pregunta
										pregunta="Compré clientes pero no me han
																						llegado, ¿Qué hago?">
										Si los solicitaste hoy no deberian de estar llegando sino
										hasta mañana, primero tenemos que procesar tu pago para
										comenzar a asignarte clientes. Si ya llevas más de un día
										esperando porfavor contáctanos!
									</Pregunta>
								</div>
							</React.Fragment>
						) : (
							<React.Fragment>
								<div className="bordeRojoPreguntas pizmediano pabmediano">
									<Pregunta pregunta="Qué es CLIENT?">
										CLIENT es una plataforma que busca unir a todos los
										inmobiliarios. La finalidad es crear una red inmobiliaria
										donde tengamos acceso a herramientas que mejoren nuestra
										manera de trabajar y a su vez que podamos generar más dinero
										creando alianzas en el sector.
									</Pregunta>
									<Pregunta pregunta="¿Cómo descargo la App?">
										Tenemos una APP que esta publicada como CLIENT - Red
										Inmobiliaria en el Play Store y pronto estará en el App
										Store, para descargarla únicamente necesitas buscarla e
										instalarla. La App y la web hacen lo mismo y solo necesitas
										tener una cuenta para operar ambas plataformas.
									</Pregunta>
									<Pregunta pregunta="¿Cómo le hago para ganar dinero con Client?">
										<span className="wbold">
											Tenemos principalmente 3 maneras:
										</span>{' '}
										<br />
										<div className="pizmediano">
											1.- <span className="wbold">Con nuestro cliente</span> -
											Subiendo tus propiedades a la plataforma y nosotros te
											conseguimos clientes intentando generarte ventas y rentas.{' '}
											<br />
											2.- <span className="wbold">Con el cliente de otro </span>
											- Publicando tus propiedades y generando ventas en equipo
											con otras inmobiliarias. <br />
											3.- <span className="wbold">Con tu cliente</span> -
											Accediendo a la cartera de propiedades COMISIONABLE más
											grande en el mercado.
										</div>
									</Pregunta>
									<Pregunta pregunta="¿Cómo me ayudaran a vender o rentar?">
										Cuando tu subes una propiedad con nosotros, invertimos
										dinero en publicidad buscando generarte ventas y rentas.
										Nosotros trabajaremos por ti tu lo único que ahora tienes
										que hacer es subir propiedades.
									</Pregunta>
									<Pregunta pregunta="¿Cobran comisión de ventas?">
										Si la venta la generas con otro corredor{' '}
										<span className="wbold">NO</span> cobramos absolutamente
										nada. Solo cobramos comisión si te generamos ventas o rentas
										con nuestros clientes (como cualquier inmobiliario).
									</Pregunta>
									<Pregunta pregunta="¿Tiene costo?">
										<span className="wbold">No</span>. Esta es una App gratuita.
									</Pregunta>
								</div>
							</React.Fragment>
						)}
					</section>
				</Frame>
			</React.Fragment>
		);
	}
}

export default Seccion4;
