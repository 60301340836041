import React, { PureComponent } from 'react';
import { Row, Col, Card, Button } from 'reactstrap';
import Frame from '../frame/Frame';
import { FaUser } from 'react-icons/fa';
import CardCliente from './CardCliente';
import Propiedad from '../../Logeado/Propiedades/propiedad/Propiedad';

class Seccion2 extends PureComponent {
  state = {
    aceptado: false,
    rechazado: false,
  };

  auth = {
    user: { firstName: 'Podrias', lastName: 'ser tu!' },
  };

  property = {
    id: 'PRUEBA',
    active: true,
    propertyType: 'HOUSE',
    operationType: 'BUY',
    squareMeters: 143,
    constructionMeters: 268,
    zone: 'Lomas de Angelopolis - Fraccionamiento Milan',
    address: 'Lomas de Angelopolis - Fraccionamiento Milan',
    bedrooms: 3,
    bathrooms: 2,
    commission: 2,
    pictures: [
      {
        id: '234',
        url:
          'https://s3.amazonaws.com/e-client/pictures/casa-con-sala-de-tv-con-cuarto-de-lavado-cuarto-de-servicio-y-roof-0.jpg',
      },
    ],
    description:
      'Casa con sala de tv, con cuarto de lavado, cuarto de servicio y recamaras',
    price: 3590000,
    dueDate: '15/15/2020',
    advertiser: {
      firstName: 'Jorge ',
      lastName: 'de la Mora Menéndez',
      cellphone: '2227067092',
    },
    origin: {
      country: 'México',
      state: 'Puebla',
      city: 'Puebla',
    },
  };

  user = {
    currency: { name: 'MXN', factor: 1 },
  };

  render() {
    return (
      <React.Fragment>
        <Frame
          id={this.props.id}
          className="fondoBlanco hPage section"
          clase="botonAmarillo"
          padding>
          <div className="paddingSeccion gris h100">
            <Row className="h100">
              <Col
                sm={this.props.soloPropiedades ? 12 : 6}
                md={6}
                className={`${
                  this.props.soloPropiedades
                    ? 'd-none d-md-block'
                    : 'd-none d-sm-block'
                } h100`}>
                <div className="posicionCard">
                  {!this.props.soloPropiedades ? (
                    <React.Fragment>
                      <div className="wbold opacityprueba">
                        Ejemplo de cliente:
                      </div>
                      <CardCliente />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Row className="posicionCardPropiedad">
                        <Col xs={12}>
                          <Propiedad
                            demo
                            auth={this.auth}
                            property={this.property}
                            currency={this.user.currency}
                          />
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </div>
                <div
                  className={`${
                    !this.props.soloPropiedades ? 'fondo2' : 'fondo2Propiedad'
                  } h100`}
                />
              </Col>
              <Col
                sm={this.props.soloPropiedades ? 12 : 6}
                md={6}
                className="paddingPorque">
                <div
                  className={`${this.props.soloPropiedades &&
                    'parmediano'} letraPorque izquierda pabchico centro wbold rojo`}>
                  ¿Qué hacemos por ti?
                </div>
                <React.Fragment>
                  <div className="centro izquierda">
                    <Row>
                      <Col
                        xs={2}
                        className="derecha parOpcionesPorque verde contenedor">
                        <div className="icon-checkbox opcionesPorque centered parmuychico" />
                      </Col>
                      <Col
                        xs={10}
                        className="parOpcionesPorque sinpymiz opcionesPorque">
                        {this.props.soloPropiedades
                          ? 'Sube tus propiedades'
                          : 'Te conseguimos clientes'}
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={2}
                        className="derecha parOpcionesPorque verde contenedor">
                        <div className="icon-checkbox opcionesPorque centered parmuychico" />
                      </Col>
                      <Col
                        xs={10}
                        className="parOpcionesPorque sinpymiz opcionesPorque">
                        {this.props.soloPropiedades
                          ? 'Te conseguimos clientes'
                          : 'Elige a tus clientes'}
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={2}
                        className="derecha parOpcionesPorque verde contenedor">
                        <div className="icon-checkbox opcionesPorque centered parmuychico" />
                      </Col>
                      <Col
                        xs={10}
                        className="parOpcionesPorque sinpymiz opcionesPorque wbold verde">
                        Haz equipo con otros inmobiliarios de tu ciudad
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={2}
                        className="derecha parOpcionesPorque verde contenedor">
                        <div className="icon-checkbox opcionesPorque centered parmuychico" />
                      </Col>
                      <Col
                        xs={10}
                        className="parOpcionesPorque sinpymiz opcionesPorque">
                        {this.props.soloPropiedades
                          ? 'No pierdas clientes por no tener más que ofrecer'
                          : 'Publica tus propiedades'}
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={2}
                        className="derecha parOpcionesPorque verde contenedor">
                        <div className="icon-checkbox opcionesPorque centered parmuychico" />
                      </Col>
                      <Col
                        xs={10}
                        className="parOpcionesPorque sinpymiz opcionesPorque">
                        Obten acceso a la cartera comisionable más extensa
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={2}
                        className="derecha parOpcionesPorque verde contenedor">
                        <div className="icon-checkbox opcionesPorque centered parmuychico" />
                      </Col>
                      <Col
                        xs={10}
                        className="parOpcionesPorque sinpymiz opcionesPorque">
                        Ahorra dinero en publicidad, en paginas web y en quién
                        te las lleve
                      </Col>
                    </Row>
                  </div>
                </React.Fragment>
              </Col>
              {!this.props.soloPropiedades && (
                <Col xs={12} className="d-block d-sm-none claseCardIntento">
                  <div className="tchica">Ejemplo de cliente:</div>
                  <CardCliente />
                </Col>
              )}
            </Row>
          </div>
        </Frame>
      </React.Fragment>
    );
  }
}

export default Seccion2;
