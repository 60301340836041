import React, { Component } from 'react';
import { TopContainer } from '../contenedor';
import Menu from '../Menu';
import { Switch, Route } from 'react-router-dom';
import Publicaciones from './Publicaciones/Publicaciones';
import CerrarSesion from '../objetos/CerrarSesion';
import LogoBlanco from '../imgs/logoBlanco.png'

var styles = {
  bmMenu: {
    background: '#7E1416',
  },

  bmMorphShape: {
    fill: '#7E1416',
  },
  bmBurgerButton: {
    zIndex: 1040,
  },
};

class Nuevo extends Component {
  rutas = [
    {
      name: 'Autorizar',
      path: `${this.props.match.url}/`,
      component: Publicaciones,
      exact: true,
    },

    {
      componente: <div className="lmuychica fondoBlanco" />,
    },
    {
      name: 'Cerrar Sesión',
      path: `${this.props.match.url}/logout`,
      component: CerrarSesion,
      exact: true,
    },
  ];
  render() {
    return (
      <React.Fragment>
        <Menu
          logotipo={
            LogoBlanco
          }
          rutas={this.rutas}
          color="fondoRojo"
          styles={styles}
        />{' '}
        <TopContainer className="tamanoProductoImg2">
          <Switch>
            {this.rutas
              .filter(ruta => !ruta.componente)
              .map(ruta => (
                <Route
                  key={ruta.name}
                  path={ruta.path}
                  component={ruta.component}
                  exact={ruta.exact}
                />
              ))}
          </Switch>
        </TopContainer>
      </React.Fragment>
    );
  }
}

export default Nuevo;
