import React, { useContext } from 'react';
import { Row, Col, Button } from 'reactstrap';
import DatosUsuario from './DatosUsuario';
import DatosNegocio from './DatosNegocio';
import MetodosPago from './MetodosPago';
import AuthContext from '../../../AuthContext';
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';

const userQuery = gql`
  {
    getUser {
      user {
        id
        email
        firstName
        lastName
        phone
        agency {
          name
        }
      }
    }
  }
`;

const cargando = {
  user: {
    email: 'Cargando',
    firstName: 'Cargando',
    lastName: 'Cargando',
    phone: 'Cargando',
    agency: {
      name: 'Cargando',
    },
  },
};

export default props => {
  return (
    <Query query={userQuery}>
      {({ data, error, loading }) => {
        if (loading)
          return (
            <div className="centro parmediano">
              <section className="parmediano pabmediano">
                <div className="fondoBlanco">
                  <Row>
                    <Col
                      xs={12}
                      md={{ size: 10, offset: 1 }}
                      lg={{ size: 8, offset: 2 }}
                      xl={{ size: 6, offset: 3 }}
                      className="pizenorme">
                      <DatosUsuario user={cargando.user} />
                      <div className="parmuygrande pabmuygrande centro">
                        <Button href="/logout" className="botonCerrarSesion">
                          Cerrar sesión
                        </Button>
                      </div>
                      {/*
                            {data.cliente.compania !== null && (
                              <DatosNegocio
                                imagen={usuario.negocio.imagen}
                                nombre={data.cliente.compania.nombre}
                                negocio={data.cliente.tipo_de_negocio.nombre}
                                usuarios={usuario.negocio.usuarios}
                              />
                            )}
                            {/* falta agregar  <MetodosPago metodos={usuario.negocio.metodo_de_pago} />
                                    <hr />*/}
                    </Col>
                  </Row>
                </div>
              </section>
            </div>
          );
        if (error) return <h2>{error.message}</h2>;
        const user = data.getUser.user;
        return (
          <section className="parmediano pabmediano">
            <div className="fondoBlanco">
              <Row>
                <Col
                  xs={12}
                  md={{ size: 10, offset: 1 }}
                  lg={{ size: 8, offset: 2 }}
                  xl={{ size: 6, offset: 3 }}
                  className="pizenorme">
                  <DatosUsuario user={user} />
                  <div className="parmuygrande pabmuygrande centro">
                    <Button href="/logout" className="botonCerrarSesion">
                      Cerrar sesión
                    </Button>
                  </div>
                  {/*
                            {data.cliente.compania !== null && (
                              <DatosNegocio
                                imagen={usuario.negocio.imagen}
                                nombre={data.cliente.compania.nombre}
                                negocio={data.cliente.tipo_de_negocio.nombre}
                                usuarios={usuario.negocio.usuarios}
                              />
                            )}
                            {/* falta agregar  <MetodosPago metodos={usuario.negocio.metodo_de_pago} />
                                    <hr />*/}
                </Col>
              </Row>
            </div>
          </section>
        );
      }}
    </Query>
  );
};
