import React, { Component } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  Row,
  Col,
  Spinner,
  Button,
} from 'reactstrap';
import ComponenteContactar from './ComponenteContactar';
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import { FaChevronLeft } from 'react-icons/fa';

function numberWithCommas(x) {
  x = Math.round(x).toString();
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x)) x = x.replace(pattern, '$1,$2');
  return x;
}

class PropiedadID extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      items: [],
    };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === this.state.items.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? this.state.items.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  propertiesQuery = gql`
    query($filter: propertyUniqueWhereInput!) {
      getProperty(filter: $filter) {
        property {
          id
          active
          advertiser {
            agency {
              name
            }
            rating
            phone
            lastName
            firstName
            email
          }
          datasheet
          propertyType
          operationType
          squareMeters
          constructionMeters
          zone
          bedrooms
          active
          bathrooms
          commission
          pictures {
            id
            order
            url
          }
          description
          price
          dueDate
          address
          origin {
            country
            state
            city
          }
        }
      }
    }
  `;

  type = {
    APPARTMENT: 'DEPARTAMENTO',
    BUILDING: 'EDIFICIO',
    HOUSE: 'CASA',
    LAND: 'TERRENO',
    MEDICAL: 'CONSULTORIO',
    OFFICE: 'OFICINA',
    RANCH: 'FINCA',
    SHOP: 'LOCAL',
    STORAGE: 'BODEGA',
    FACILITY: 'NAVE INDUSTRIAL',
  };

  render() {
    const { activeIndex } = this.state;

    if (!this.props.match.params.id) {
      this.props.history.push('/propiedades');
    }

    return (
      <Query
        query={this.propertiesQuery}
        variables={{ filter: { id: this.props.match.params.id } }}
        fetchPolicy="cache-and-network"
        onCompleted={data =>
          this.setState({ items: data.getProperty.property.pictures })
        }>
        {({ data, loading, error }) => {
          if (loading && !data)
            return (
              <div className="parmuygrande centro tmuygrande">
                <div className="verde wbold pmuychico centro tmuygrande">
                  Cargando propiedad
                </div>
                <Spinner size="lg" color="success" />
              </div>
            );
          if (error)
            return <p className="centro rojo wbold pchico">{error.message}</p>;
          const { property } = data.getProperty;

          return (
            <React.Fragment>
              {/*FOTO*/}
              {/*falta incluir favoritos en la foto*/}
              <React.Fragment>
                <div className="posicionCarrousel">
                  <div className="pabmediano">
                    {' '}
                    <Row>
                      <Col xs={4} sm={5}>
                        <Button
                          className="botonGris claseBoton2"
                          onClick={() => {
                            const back =
                              this.props.location.search === '?o=true'
                                ? '/mis-propiedades'
                                : '/propiedades';

                            this.props.history.push(back);
                          }}
                          tag={Link}>
                          <Row>
                            <Col xs={2} sm={1} className="pabmicro sinpymde">
                              <div className="pizmicro3 paddingBotonRegresar">
                                <FaChevronLeft />
                              </div>
                            </Col>
                            <Col className="d-none d-sm-block parmicro1">
                              <div className="d-inline parchico">Regresar</div>
                            </Col>
                          </Row>
                        </Button>
                      </Col>
                      {/*numero de propiedad*/}
                      <Col className="tgrande wbold parmicro1">
                        <div className="parmuychico">
                          PROPIEDAD #{property.id}
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="tamanoImagenCarousel fondoGrisClaro">
                    <Carousel
                      className="tamanoImagenCarousel"
                      activeIndex={activeIndex}
                      next={this.next}
                      interval={0}
                      pause
                      ride={false}
                      previous={this.previous}>
                      <CarouselIndicators
                        items={this.state.items}
                        activeIndex={activeIndex}
                        onClickHandler={this.goToIndex}
                      />
                      {this.state.items.map(picture => (
                        <CarouselItem
                          onExiting={this.onExiting}
                          onExited={this.onExited}
                          key={picture.url}>
                          <img
                            className="tamanoImagenCarousel"
                            src={picture.url}
                            alt={`Foto de propiedad`}
                          />
                        </CarouselItem>
                      ))}
                      <CarouselControl
                        direction="prev"
                        directionText="Previous"
                        onClickHandler={this.previous}
                      />
                      <CarouselControl
                        direction="next"
                        directionText="Next"
                        onClickHandler={this.next}
                      />
                    </Carousel>
                  </div>
                  <div className="pabenorme">
                    {/*pantallas chicas y medianas*/}
                    <div className="fondoBlanco pmediano tamanoCarousel">
                      {/*precio*/}
                      <React.Fragment>
                        {/*chica*/}
                        <div className="d-block d-sm-none wbold tPrecioID centro parchico pabchico">
                          <div className="tmuygrande">
                            ${numberWithCommas(property.price)}
                          </div>
                          {/*Tipo de operacion y tipo de propiedad*/}
                          {this.type[property.propertyType]} EN{' '}
                          {property.operationType === 'RENT'
                            ? 'RENTA'
                            : 'VENTA'}
                        </div>
                        {/*mediana*/}
                        <div className="d-none d-sm-block wbold tPrecioID centro parchico pabchico">
                          <div className="d-inline tPrecioID2">
                            ${numberWithCommas(property.price)}
                          </div>{' '}
                          - {/*Tipo de operacion y tipo de propiedad*/}
                          {this.type[property.propertyType]} EN{' '}
                          {property.operationType === 'RENT'
                            ? 'RENTA'
                            : 'VENTA'}
                        </div>
                      </React.Fragment>
                      <div className="lmuychica fondoGris" />
                      {/*ubicacion*/}
                      <React.Fragment>
                        <div className="centro wbold parmediano pabmediano">
                          {/*chica*/}
                          <div className="d-block d-sm-none">
                            <Row>
                              <Col
                                xs={3}
                                className="centro pdemuychico parmuychico">
                                <div className="centrado pizmediano">
                                  <div
                                    className={`d-inline icon-pin ${
                                      property.origin.country.length +
                                        property.origin.state.length +
                                        property.origin.city.length +
                                        property.zone.length >=
                                      45
                                        ? 'tmuygrande'
                                        : 'tgrande'
                                    } pdechico rojo`}
                                  />
                                </div>
                              </Col>
                              <Col className="sinpymiz parmicro3">
                                <div className="pdemuychico">
                                  {property.address.replace(',', '-')}
                                </div>
                              </Col>
                            </Row>
                          </div>
                          {/*mediana*/}
                          <div className="d-none d-sm-block">
                            <div className="d-inline icon-pin tgrande pdechico" />
                            {property.address}
                          </div>
                        </div>
                      </React.Fragment>
                      <div className="lmuychica fondoGris" />
                      <Row className="pargrande pabchico">
                        {/*Datos generales (terreno, m3, habitaciones, baños)*/}
                        <Col xs={12} md={4}>
                          <div>
                            {/*chica*/}
                            <div className="d-block d-md-none">
                              <div className="wbold parmicro centro subrayado">
                                DATOS GENERALES
                              </div>
                              <div
                                className={
                                  property.operationType !== 'RENT' &&
                                  'paddingIconosLados'
                                }>
                                <Row className="parchico pabchico">
                                  <Col
                                    xs={
                                      property.operationType !== 'RENT' ? 6 : 4
                                    }
                                    className="centro">
                                    <div className="d-inline icon-terreno verde" />{' '}
                                    {property.squareMeters}
                                    <span className="minus">m</span>
                                    <sup className="tmuychica">2</sup>
                                  </Col>
                                  <Col
                                    xs={
                                      property.operationType !== 'RENT' ? 6 : 4
                                    }
                                    className="centro">
                                    <div className="d-inline icon-cama verde" />{' '}
                                    {property.bedrooms !== 0 ? (
                                      <div className="d-inline">
                                        {property.bedrooms}
                                      </div>
                                    ) : (
                                      'n/a'
                                    )}
                                  </Col>
                                  {property.operationType !== 'RENT' && (
                                    <Col xs={6} className="centro">
                                      <div className="d-inline icon-construccion verde" />{' '}
                                      {property.constructionMeters !== 0 ? (
                                        <div className="d-inline">
                                          {property.constructionMeters}
                                          <span className="minus">m</span>
                                          <sup className="tmuychica">3</sup>
                                        </div>
                                      ) : (
                                        'n/a'
                                      )}
                                    </Col>
                                  )}
                                  <Col
                                    xs={
                                      property.operationType !== 'RENT' ? 6 : 4
                                    }
                                    className="centro">
                                    <div className="d-inline icon-escusado verde" />{' '}
                                    {property.bathrooms !== 0 ? (
                                      <div className="d-inline">
                                        {property.bathrooms}
                                      </div>
                                    ) : (
                                      'n/a'
                                    )}
                                  </Col>
                                </Row>
                              </div>
                            </div>
                            {/*mediana*/}
                            <div className="d-none d-md-block">
                              <div className="wbold parmicro">
                                DATOS GENERALES:
                              </div>
                              <div>
                                <span className="verde">Terreno:</span>{' '}
                                {property.squareMeters}
                                <span className="minus">m</span>
                                <sup className="tmuychica">2</sup>
                              </div>
                              <div>
                                <span className="verde">Construcción:</span>{' '}
                                {property.constructionMeters}
                                <span className="minus">m</span>
                                <sup className="tmuychica">3</sup>
                              </div>
                              <div>
                                <span className="verde">Recámaras:</span>{' '}
                                {property.bedrooms}
                              </div>
                              <div>
                                <span className="verde">Baños:</span>{' '}
                                {property.bathrooms}
                              </div>
                            </div>
                          </div>
                        </Col>
                        {/*Descripcion*/}
                        <Col>
                          <div>
                            <div className="wbold verde paddingDescripcion">
                              Descripción:
                              <div className="gris justificado tmediana wnormal">
                                {property.description}
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      {/*Contactar*/}
                      <React.Fragment>
                        <div className="centro pargrande pabchico">
                          <ComponenteContactar
                            property={property}
                            auth={{ user: property.advertiser }}
                            ID
                          />
                          {/*PDF*/}
                          <React.Fragment>
                            <div className="parchico">
                              <Button
                                className="botonRojo claseBoton3"
                                tag="a"
                                href={property.datasheet}
                                target="_blank">
                                <div className="parmicro pizchico pdemuychico botonContactarID">
                                  <div className="pizchico">
                                    <Row>
                                      <Col
                                        xs={3}
                                        className="pizmicro sinpymde derecha">
                                        <div className="d-inline icon-descargar tgrande" />{' '}
                                        <div className="d-inline icon-pdf tgrande" />
                                      </Col>
                                      <Col
                                        xs={9}
                                        className="pizchico parmicro1 izquierda sinpymde">
                                        Descargar PDF
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </Button>
                            </div>
                          </React.Fragment>
                        </div>
                      </React.Fragment>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            </React.Fragment>
          );
        }}
      </Query>
    );
  }
}

export default PropiedadID;
