import React from 'react';
import Cards from 'react-credit-cards';
import { Row, Col, Input, Spinner } from 'reactstrap';
import { Mutation } from '@apollo/react-components';
import gql from 'graphql-tag';
import 'react-credit-cards/es/styles-compiled.css';

class Credit_card extends React.Component {
  state = {
    cvc: '',
    expiration_month: '',
    expiration_year: '',
    focus: '',
    name: '',
    number: '',
  };

  handleInputFocus = e => {
    this.setState({ focus: e.target.name });
  };

  handleInputChange = e => {
    const { name, value } = e.target;

    this.setState({ [name]: value });
  };

  cardnumber = document.getElementsByName('cardnumber');
  exp_date = document.getElementsByName('exp-date');
  cvc = document.getElementsByName('cvc');

  addCardMutation = gql`
    mutation($data: addCardInput!) {
      addCard(data: $data) {
        card_number
        brand
      }
    }
  `;

  render() {
    return (
      <Row>
        <Col xs={{ size: 12 }}>
          <Cards
            cvc={this.state.cvc}
            expiry={`${this.state.expiration_month}${
              this.state.expiration_year
            }`}
            focused={this.state.focus}
            name={this.state.name}
            number={this.state.number}
          />
          <Mutation
            mutation={this.addCardMutation}
            onCompleted={this.props.afterSave}>
            {(addCard, { loading, called, data }) => (
              <form
                onSubmit={e => {
                  e.preventDefault();
                  const { OpenPay } = window;
                  const device_session_id = OpenPay.deviceData.setup();
                  const {
                    number: card_number,
                    expiration_month,
                    expiration_year,
                    name: holder_name,
                    cvc: cvv2,
                  } = this.state;
                  OpenPay.token.create(
                    {
                      card_number,
                      expiration_year,
                      expiration_month,
                      holder_name,
                      cvv2,
                      device_session_id,
                    },
                    ({ data }) => {
                      addCard({
                        variables: {
                          data: {
                            token: data.id,
                            sessionId: device_session_id,
                          },
                        },
                      });
                    },
                    console.error,
                  );
                }}>
                <div className="form-group mt-4">
                  <Input
                    className="form-control"
                    placeholder="Numero de tarjeta (16 digitos)"
                    name="number"
                    maxLength="16"
                    required
                    onChange={this.handleInputChange}
                    onFocus={this.handleInputFocus}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Nombre"
                    required
                    onChange={this.handleInputChange}
                    onFocus={this.handleInputFocus}
                  />
                </div>
                <div className="row">
                  <div className="col-3">
                    <Input
                      className="form-control"
                      placeholder="Mes"
                      name="expiration_month"
                      maxLength="2"
                      required
                      onChange={this.handleInputChange}
                      onFocus={this.handleInputFocus}
                    />
                  </div>
                  /
                  <div className="col-3">
                    <Input
                      className="form-control"
                      placeholder="Año"
                      name="expiration_year"
                      maxLength="2"
                      required
                      onChange={this.handleInputChange}
                      onFocus={this.handleInputFocus}
                    />
                  </div>
                  <div className="col-5">
                    <Input
                      className="form-control"
                      name="cvc"
                      placeholder="CVC"
                      maxLength="4"
                      required
                      onChange={this.handleInputChange}
                      onFocus={this.handleInputFocus}
                    />
                  </div>
                </div>
                <div className="form-actions mt-4">
                  <button
                    className="btn btn-primary btn-block"
                    disabled={loading}>
                    Guardar {loading && <Spinner />}
                  </button>
                </div>
              </form>
            )}
          </Mutation>
        </Col>
      </Row>
    );
  }
}

export default Credit_card;
